import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { HomeOutlined } from '@material-ui/icons';
import { useTranslation } from "../../langs/useTranslation";

const BacktoHomeBtn = (props) => {
    const { t } = useTranslation('common'); 
  
    return(
        <NavLink to='/' >
            <List>
                <ListItem button>
                    <ListItemIcon><HomeOutlined /></ListItemIcon>
                    <ListItemText>{t('btn.backToHome')}</ListItemText>
                </ListItem>
            </List>
        </NavLink>
    )
}

export default BacktoHomeBtn;