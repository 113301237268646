import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import * as localforage from 'localforage';

export const valueAddedServicesAPI = async() => {

    const token = await localforage.getItem('token');

    try {
        let response = await axios({
            url: `${url}/api/v1/value-added-services`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token
            },
        });

        await localforage.setItem('valueAddedServices', response.data.value_added_services);
        return response.data.value_added_services;

    } catch (error) {
        errorHandling(error, true);
    }

}