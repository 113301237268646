import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const uploadNewDevicesAPI = async (token, companyId, excelFile) => {
    let formData = new FormData();
    formData.append('company_id', companyId);
    formData.append('spreadsheet', excelFile);
    try {
        await axios({
            url: `${url}/api/v1/devices/upload-new-devices`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token
            },
            data: formData
        });
        return true;
    } catch (error) {
        errorHandling(error, true);
    }
}