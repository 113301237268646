import React, { useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { compareAsc, addDays } from "date-fns";

const MuiDatePicker = (props) => {
  const {
    dateState,
    comparedDate,
    setDateState,
    storedDatePickerValueToLocalforageName,
    noLockPast,
  } = props;

  const [selectedDate, handleDateChange] = useState(null);

  const checkDateValidation = async (value) => {
    let ifDateValid;

    if (comparedDate) {
      if (storedDatePickerValueToLocalforageName === "subscriptionEndDate") {
        ifDateValid = compareAsc(new Date(value), new Date(comparedDate)) === 1;
      }
      if (storedDatePickerValueToLocalforageName === "subscriptionStartDate") {
        ifDateValid = compareAsc(new Date(comparedDate), new Date(value)) === 1;
      }

      return ifDateValid;
    }

    return true;
  };

  return (
    <div className="date-time-picker">
      <DatePicker
        autoOk
        ampm={false}
        variant="inline"
        label={!(selectedDate && dateState) && "yyyy/mm/dd"}
        value={selectedDate && dateState ? selectedDate : null}
        minDate={
          storedDatePickerValueToLocalforageName === "subscriptionEndDate" &&
          addDays(comparedDate, 1)
        }
        disablePast={!noLockPast}
        onChange={async (value) => {
          const ifDateValid = await checkDateValidation(value);
          if (ifDateValid) {
            handleDateChange(value);
            setDateState(value);
          } else {
            handleDateChange();
            setDateState();
          }
        }}
        format="yyyy/MM/dd"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <AlarmIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default MuiDatePicker;
