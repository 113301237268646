import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  InputBase,
  Button,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HelpOutline from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import logo from "../../assets/images/logo.png";
import LangSelect from "./LangSelect";
import useStyles from "../../styles/commonStyle";
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext } from "../../App";
import ResetPasswordForm from "../../containers/ResetPasswordForm";
import UserMenu from "./AppBarUserMenu";
import NotificationIconBtn from "./AppBarNotificationIconBtn";
import * as localforage from "localforage";
import { qaUrl } from "../../config/optionsOnAppBar";
import { releaseVersion } from "../../config/releaseVersion";

export const ModalContext = React.createContext();
const Appbar = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null); // User menu pop out position
  const [modalOpen, setModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const { dispatch } = useContext(SubmitBtnContext);
  const isUserMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleResetPwdModalOpen = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const handleResetPwdModalClose = () => {
    setModalOpen(false);
    dispatch({ type: "stopLoading" });
  };

  const getUserInfo = async () => {
    const user = await localforage.getItem("user");
    setUsername(user.name);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <ModalContext.Provider
      value={{ setAnchorEl, modalOpen, handleResetPwdModalClose }}
    >
      <div className={classes.grow}>
        <AppBar position="fixed" color="inherit">
          <Toolbar>
            <div className={classes.title}>
              <NavLink to="/">
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography variant="caption" color="secondary">
                  Platform
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.releaseVersionText}
                >
                  {releaseVersion}
                </Typography>
              </NavLink>
            </div>
            <div className={classes.rightSection}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder={t("appbar.searchFieldText")}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
              <LangSelect />
              <a
                href={qaUrl}
                target="_blank"
                rel="noreferrer"
                className={classes.qaLink}
              >
                <IconButton aria-label="Q&A" color="inherit">
                  <Badge color="error">
                    <HelpOutline />
                  </Badge>
                </IconButton>
              </a>
              <NotificationIconBtn />
              <Button
                edge="end"
                aria-label="User"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
                <Typography variant="caption" className={classes.usernameText}>
                  {username}
                </Typography>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <UserMenu
          open={isUserMenuOpen}
          onClose={handleUserMenuClose}
          anchorEl={anchorEl}
          handleResetPwdModelOpen={handleResetPwdModalOpen}
        />
        <ResetPasswordForm open={modalOpen} close={handleResetPwdModalClose} />
      </div>
    </ModalContext.Provider>
  );
};

export default Appbar;
