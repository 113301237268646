import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Typography, Paper } from '@material-ui/core';
import StyledSubmitBtn from '../components/commons/StyledSubmitBtn';
import logo from '../assets/images/logo.png';
import { useTranslation } from "../langs/useTranslation";
import useStyles from '../styles/loginStyle';
import { SubmitBtnContext } from '../App';
import { newPwdSendAction } from '../apis/forgotPasswordAPI';
import { Formik, Form } from 'formik';
import { EmailTextField } from '../components/commons/StyledTextFields';
import { forgotPwdValuesSchema, forgotPwdInitialValues } from '../config/formValueSchema';

const ForgotPassword = () => {
    const classes = useStyles();
    const { t } = useTranslation('login');
    const history = useHistory();
    const { dispatch } = useContext(SubmitBtnContext);

    return(
        <div className={classes.root}>
            <Paper variant="outlined" className={classes.paper}>
                <img src={logo} alt='logo' className={classes.logoSmall} />
                <Typography className={classes.forgotPwdTitle} variant="h4" align="center">
                    {t('forgotPwd.title')}
                </Typography>
                <Typography className={classes.forgotPwdText} align="center">
                    {t('forgotPwd.enterEmail')}<br/>
                    {t('forgotPwd.sendPwdToEmail')}
                </Typography>
                <Formik
                    initialValues={forgotPwdInitialValues}
                    validationSchema={forgotPwdValuesSchema(t)}
                    validateOnChange={false}
                    onSubmit={async(values) => {
                        dispatch({type: 'showLoading'});
                        let responseStatus = await newPwdSendAction(values);
                        if( responseStatus === true ) history.replace('/loginAgain')
                        dispatch({type: 'stopLoading'});
                    }}
                >
                    {({
                        values, errors, handleChange, handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit}>  
                            <EmailTextField 
                                width="300px"
                                className={errors.email ? 'mb_16 error-text-field' : 'mb_16' }
                                label={t('login.emailInputLabel')} 
                                name='email'
                                onChange={handleChange} 
                                value={values.email} 
                                helperText={errors.email}
                            />
                            <StyledSubmitBtn 
                                btnColor="primary" 
                                variant="contained" 
                                btnText={t('forgotPwd.confirmBtn')}
                            />
                        </Form>
                    )}
                </Formik>
                <a onClick={(e) => {e.preventDefault(); history.goBack()}} className={classes.linkText}>{t('forgotPwd.backToLogin')}</a>
                {/* <NavLink to='/login' className={classes.linkText}>{t('forgotPwd.backToLogin')}</NavLink>*/}            </Paper>
        </div>
    )
}

export default ForgotPassword;