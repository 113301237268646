import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Grid, FormControl, RadioGroup, Divider, Typography, Paper } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/subscribeServicesStyle';
import { StepTitle, MissingSelectedErrorText } from '../commons/StyledTexts';
import SelectService from './SelectService';
import SelectCompanyAndUsers from './SelectCompanyAndUsers';
import SetDates from './SetDates';
import DeviceTable from './DeviceTable';
import ExcelFileUpload from '../commons/ExcelFileUpload';
import SubmitAndCancelBtn from '../../components/commons/SubmitAndCancelBtn';
import { SubmitBtnContext } from '../../App';
import * as localforage from 'localforage';
import * as $ from 'jquery';
import { handleDatatableSelectWithRadio, handleStoreDataToLocal } from '../../helpers/handleDatatableSelectWithRadio';
import { getCompanyAccountsAPI } from '../../apis/getCompanyAccountsAPI' ;
import { setUpArrayWithCheckStatus } from '../../helpers/setUpArrayWithCheckStatus';
import { getObjArrFromAPIOrLocal } from '../../helpers/objArrHandlers';
import { getCompanyTreeAPI } from '../../apis/getCompanyTreeAPI';
import { valueAddedServicesAPI } from '../../apis/valueAddedServicesAPI';
import { createSubscribeServiceAPI } from '../../apis/createSubscribeServiceAPI';
import { renewSubscribeServiceAPI } from '../../apis/renewSubscribeServiceAPI';
import { arraySorterBySN } from '../../helpers/arraySorter';
import SubscriptionDetailDialog from './SubscriptionDetail';
import { transformedFormatBytes } from '../../helpers/transformedFormatBytes';

const SubscriForm = props => {
    const { startDateLocalforageName, endDateLocalforageName } = props;
    const classes = useStyles(); 
    let { id } = useParams();
    const { t } = useTranslation('subscribeServices');
    const { dispatch } = useContext(SubmitBtnContext);
    const [ resetSearchField, setResetSearchField ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const [ selectedUsers, setSelectedUsers ] = useState([]); // stored name and id for chips on mutiple selector
    const [ selectedUserIds, setSelectedUserIds ] = useState(false);  // stored id only for api
    const [ companies, setCompanies ] = useState([]);
    const [ selectedCompany, setSelectedCompany ] = useState('');
    const [ services, setServices ] = useState([]);
    const [ serviceValue, setServiceValue ] = useState(false);
    const [ serviceId, setServiceId ] = useState(false);
    const [ nubisDriveCapacityValue, setNubisDriveCapacityValue ] = useState(false);
    const [ planContentValue, setPlanContentValue ] = useState(false);
    const [ isValueValid, setIsValueValid ] = useState(true);
    const [ dateRange, setDateRange ] = useState();
    const [ selectWay, setSelectWay ] = useState('upload');
    const [ excelFile, setExcelFile ] = useState(null);
    const [ deviceIds, setDeviceIds ] = useState();
    const [ deviceSNs, setDeviceSNs ] = useState();
    const [ isFormSubmitting, setIsFormSubmitting ] = useState(false);
    const [ isRenewal, setIsRenewal ] = useState(false);
    const [ startedAt, setStartedAt ] = useState();
    const [ endedAt, setEndedAt ] = useState();
    const [ subscriptionId, setSubscriptionId ] = useState(false);
    const [ orderId, setOrderId ] = useState();
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const deviceTable = $('#subscribe-device-table').DataTable();

    const handleSelectWayChange = e => {
        setSelectWay(e.target.value);
        setExcelFile(null); // reset the 'required' validation
        setDeviceIds([]); // reset the 'required' validation
    }

    const handleSubscriptionSubmit = async(e) => {
        
        if(!e.detail || e.detail === 1) { 

            dispatch({type: 'showLoading'});
            setIsFormSubmitting(true);

           // Check if any device is selected
            let deviceIdsArr = await localforage.getItem('dtDeviceIds');
            let deviceSNsArr = await localforage.getItem('dtDeviceSNs');
            if(selectWay === 'upload') setExcelFile(!!excelFile);
            if(selectWay !== 'upload') {
                setDeviceIds((deviceIdsArr && deviceIdsArr.length > 0) ? deviceIdsArr : false);
                setDeviceSNs((deviceSNsArr && deviceSNsArr.length > 0) ? deviceSNsArr : false);
            }

            // create subscription
            if ( serviceValue && serviceId && serviceValue && isValueValid && selectedUsers && selectedUsers.length > 0 && dateRange ) {
                if ( !isRenewal && serviceValue === 'nubis_drive' && planContentValue ) {
                    const subscription = await createSubscribeServiceAPI(t, setDialogOpen, selectedCompany.value, selectedCompany.name, serviceId, serviceValue, selectedUsers, planContentValue, startedAt, endedAt);
                    setOrderId(subscription.order_id);
                }
                
                if ( !isRenewal && serviceValue !== 'nubis_drive' ) {
                    if ( selectWay === 'upload' && excelFile ) {
                        const subscription = await createSubscribeServiceAPI(t, setDialogOpen, selectedCompany.value, selectedCompany.name, serviceId, serviceValue, selectedUsers, planContentValue, startedAt, endedAt, undefined, excelFile);
                        if(subscription && subscription.devices) {
                            setOrderId(subscription.order_id);
                            setDeviceSNs(subscription.devices.map(o=> o.serial_number));
                        }
                    }
                    if ( selectWay === 'select' && deviceIdsArr ) {
                        const subscription = await createSubscribeServiceAPI(t, setDialogOpen, selectedCompany.value, selectedCompany.name, serviceId, serviceValue, selectedUsers, planContentValue, startedAt, endedAt, deviceIdsArr);
                        setOrderId(subscription.order_id);
                    }
                } 
            }

            // renew Subscription
            if ( isRenewal && subscriptionId && selectedUsers && selectedUsers.length > 0 && dateRange ) {
                const subscription = await renewSubscribeServiceAPI(t, setDialogOpen, subscriptionId, selectedUsers, startedAt, endedAt);
                setOrderId(subscription.order_id);
            }

            dispatch({type: 'stopLoading'});
        }

    }

    const setUpServices = async() => {
        let servicesObj = await getObjArrFromAPIOrLocal('valueAddedServices', valueAddedServicesAPI, 'id', false);
        setServices(servicesObj);
    }
    
    const setUpCompany = async() => {
        
        if(serviceValue){
            // when subscribing 'Nubis Drive', remove FEC HQ from the selector options.
            let companyTree = serviceValue === 'nubis_drive' ? 'flattenCompanyTreeWithoutHQ' : 'flattenCompanyTree';
            let companiesObj = await getObjArrFromAPIOrLocal(companyTree, getCompanyTreeAPI, 'value', true);
            setCompanies(companiesObj);
        }
    }

    const setUpUsers = async() => {

        // Rule: selected company changed, reset user selector
        setUsers([]);
        setSelectedUsers([]);
        setSelectedUserIds(false);

        if(selectedCompany){
            let companyId = selectedCompany.value;
            let usersObj = await getCompanyAccountsAPI(companyId);
            setUpArrayWithCheckStatus(usersObj, setUsers);
        } 
    }

    const onChangeSingleSelector = e => setSelectedCompany(e.target.value);

    const setUpRenewalForm = async() => {
        const subscriptionInfo = await localforage.getItem(`subscription${id}`);
        const sortedDevices = arraySorterBySN(subscriptionInfo.devices);
        setSubscriptionId(subscriptionInfo.id);
        setNubisDriveCapacityValue(subscriptionInfo.plan_content);
        setPlanContentValue(subscriptionInfo.plan_content && transformedFormatBytes(subscriptionInfo.plan_content.capacity));
        setServiceId(subscriptionInfo.value_added_service_id);
        setServiceValue(subscriptionInfo.value_added_service_name);
        setSelectedCompany({ value: subscriptionInfo.company_id, name: subscriptionInfo.company_name });
        setDeviceSNs(sortedDevices.map(o => o.serial_number));
        setOrderId(subscriptionInfo.order_id);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const handleCancelBtnOnClick = e => {
        if(!e.detail || e.detail === 1) { 
            window.history.back();
        }
    }

    $('#searchNameField').on('click', async function() {
        setSelectWay('select');
    })
    
    deviceTable.on('click', $('#subscribe-device-table input'), async function() {
        handleDatatableSelectWithRadio(setSelectWay);
        handleStoreDataToLocal('dtDeviceSNs', 'id');
    })

    useEffect(() => {
        setUpServices();
        setIsRenewal(!!id);
        if(id) setUpRenewalForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    
    useEffect(() => {
        setUpCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceValue])

    useEffect(() => {
        setUpUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies, selectedCompany])


    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <SelectService 
                    isRenewal={isRenewal}
                    services={services}
                    serviceValue={serviceValue} 
                    setServiceValue={setServiceValue}
                    setServiceId={setServiceId}
                    isValueValid={isValueValid}
                    setIsValueValid={setIsValueValid}
                    nubisDriveCapacityValue={nubisDriveCapacityValue}
                    setNubisDriveCapacityValue={setNubisDriveCapacityValue}
                    planContentValue={planContentValue}
                    setPlanContentValue={setPlanContentValue}
                    setCompanies={setCompanies} // reset if service changed
                    setSelectedCompany={setSelectedCompany}  // reset if service changed
                    setUsers={setUsers}  // reset if service changed
                    setSelectedUsers={setSelectedUsers}  // reset if service changed
                    setSelectedUserIds={setSelectedUserIds}  // reset if service changed
                />
                { isFormSubmitting && !serviceValue ? <MissingSelectedErrorText text="selectedTargetRequired" /> : ''}
                { isFormSubmitting && serviceValue === 'nubis_drive' && !planContentValue ? <MissingSelectedErrorText text="capacityRequired" /> : ''}
                <SelectCompanyAndUsers 
                    serviceValue={serviceValue} // to check if render company options
                    serviceId={serviceId}
                    isRenewal={isRenewal}
                    companies={companies}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    users={users} 
                    setUsers={setUsers}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    selectedUserIds={selectedUserIds}
                    setSelectedUserIds={setSelectedUserIds}
                    onChangeSingleSelector={onChangeSingleSelector}
                    isFormSubmitting={isFormSubmitting}
                />
                <Grid item xs={12}>
                    <StepTitle title='setDateRange' /> 
                </Grid>
                <Grid item xs={12}>
                    <SetDates
                        isRenewal={isRenewal}
                        startedAt={startedAt}
                        setStartedAt={setStartedAt}
                        endedAt={endedAt}
                        setEndedAt={setEndedAt}
                        startDateLocalforageName={startDateLocalforageName}
                        endDateLocalforageName={endDateLocalforageName}
                        setDateRange={setDateRange}
                    />
                    { isFormSubmitting && !dateRange ? <MissingSelectedErrorText text="dateRangeRequired" /> : ''}
                </Grid>
                {  !isRenewal && serviceValue !== 'nubis_drive' &&
                    <>
                        <Grid item xs={12}>
                            <StepTitle title='chooseDevices' /> 
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="selectWay" name="selectWay" value={selectWay} onChange={handleSelectWayChange}>
                                    <ExcelFileUpload 
                                        selectWay={selectWay}
                                        excelFile={excelFile}
                                        setExcelFile={setExcelFile}
                                        setSelectWay={setSelectWay}
                                        isFormSubmitting={isFormSubmitting}
                                        label={t('subscribeNewService.byExcel')}
                                        />
                                    <Divider className={classes.divider} />
                                    <DeviceTable
                                        deviceTable={deviceTable}
                                        selectedCompany={selectedCompany}
                                        originalServiceObjArr={services}
                                        resetSearchField={resetSearchField}
                                        setResetSearchField={setResetSearchField}
                                        selectWay={selectWay}
                                        isFormSubmitting={isFormSubmitting}
                                        deviceIds={deviceIds}
                                        startedAt={startedAt}
                                        endedAt={endedAt}
                                        isRenewal={isRenewal}
                                        subscriptionId={subscriptionId}
                                        serviceId={serviceId}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </>
                }
                { isRenewal && serviceValue !== 'nubis_drive' && deviceSNs &&
                    <>
                        <Grid item xs={12}>
                            <StepTitle title='chooseDevices' /> 
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Typography variant="caption" color="textSecondary">
                                    {deviceSNs.join(', ')}
                                </Typography>
                            </Paper>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <SubmitAndCancelBtn
                        styledSubmitBtnOnClick={(e)=>handleSubscriptionSubmit(e)}
                        cancelBtnDisabled={isFormSubmitting}
                        cancelBtnOnClick={(e)=>handleCancelBtnOnClick(e)}
                    />                  
                </Grid>
            </Grid>
            <SubscriptionDetailDialog 
                open={dialogOpen} 
                onClose={handleDialogClose}
                serviceValue={serviceValue}
                deviceSNs={deviceSNs}
                planContentValue={planContentValue}
                companyName={selectedCompany?.name}
                usernames={selectedUsers && selectedUsers.map(o=>o.name).join(', ')}
                startedAt={startedAt}
                endedAt={endedAt}
                orderId={orderId}
                directToList={true}
            />
        </>
    )
}

export default SubscriForm;