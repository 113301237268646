import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";

const DelegationActionFromOrToDeterminator = props => {
    const { action, actionsHideGroupOrTagSelection } = props;
    const { t } = useTranslation('devicesGroups');

    return(
        <Typography 
            variant="body1" 
            color={actionsHideGroupOrTagSelection.includes(action) ? 'textSecondary' : 'primary'}>
            { action === 'remove' ? 
                `${t('setting.from')}`
                :   
                actionsHideGroupOrTagSelection.includes(action) ? `${t('setting.from')}` : `${t('setting.to')}`
            }
        </Typography>
    )
}

export default DelegationActionFromOrToDeterminator;