import React, { useState, useEffect, useCallback } from 'react';
import * as localforage from 'localforage';
import { authLogin } from '../apis/loginAPI';
import { authLogout } from '../apis/logoutAPI';
import moment from 'moment';

export const AuthContext = React.createContext({})
export default function Auth({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoginFailed, setIsLoginFailed] = useState(false);

    useEffect(() => {
        console.log("Checking Ticket...")
        const [ticket, loginStatus] = checkTicket();

        //console.log(loginStatus)
        //console.log(ticket)

        if (loginStatus === "true") {
            //from login pipeline, get ticket
            //?ticket=XXX&login_status=true
            console.log("Got ticket, now get the token...")
            login(ticket)
        } else if (loginStatus === "false"){
            //from login pipeline, something wrong at CAS <=> API server
            //?login_status=false
            setIsLoginFailed(true)
        }
    }, [])

     /* useEffect(() => {
        console.log("/// state ///", document.location.href)
        console.log("Is Authenticated: ", isAuthenticated)
        console.log("Is Loading: ", isLoading)
        console.log("Ticket: ", ticket)
        console.log("Is Login Failed: ", isLoginFailed)
    }) */

    const checkTicket = () => {
        /* When redirect back from API server, 
            check ticket and login_status{true, false}
        */
        const params = (new URL(document.location)).searchParams
        const ticket = params.get('ticket')
        const loginStatus = params.get('login_status')
        
        return [ticket, loginStatus]
    }

    const checkAuth = async (here) => {
        //check expired_at with current time
        // transfer current date from local time zone to utc
        console.log("Check auth..." + here)
        const currentDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        const expiredDate = await localforage.getItem('expiredAt');
        const token = await localforage.getItem('token');

        const [, loginStatus] = checkTicket()

        if(loginStatus){
            return;
        }

        if (!token || !expiredDate || currentDate > expiredDate) {
            //Token expired or no token'
            setIsAuthenticated(false);
            setIsLoading(false);
            localforage.clear();
            return;
        } else {
            //Is Authenticated
            setIsAuthenticated(true);
            setIsLoading(false);
            return;
        }

    }

    const login = async (ticket) => {
        // call api, store token, expired_at
        //console.log('Login...')
        setIsLoading(true);
        /*Use ticket to call API server for getting token and permissions.*/
        const hasLoginData = await authLogin(ticket)
        console.log("login...")
        
        if (!hasLoginData) {
            console.log("[Auth Error]:Can't get login Data")
            setIsLoginFailed(true);
            setIsLoading(false);
        } else {
            console.log("login success")
            setIsAuthenticated(true)
            setIsLoading(false);
            window.location.href = window.location.href.split('?')[0];
        }
    }

    const logout = async () => {
        // call Logout API
        await authLogout();
    }

    return (
        // AuthContext.Provider will wrap the components
        // The wrapped components can use the value sent from the AuthContext.Provider
        // To use the value, the wrapped components should delcare 'useContext(AuthContext)' 
        <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout, checkAuth, setIsLoading, isLoginFailed, setIsLoginFailed }}>
            {children}
        </AuthContext.Provider>
    )

}
