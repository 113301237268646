import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Button, Paper, IconButton } from "@material-ui/core";
import useStyles from "../../styles/accessManagementStyle";
import { url } from "../../apis/apiSetup";
import "../../css/dataTable.css";
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from "localforage";
import * as $ from "jquery";
import "datatables.net";
import "datetime-moment";
import { DeleteOutlined, ReplayOutlined, AddBox } from "@material-ui/icons";
import RenameField from "../commons/RenameField";
import CreatePermissionDialog from "./CreatePermissionDialog";
import RemovePermissionDialog from "./RemovePermissionDialog";
import { searchStyleRender } from "../../helpers/datatableSearchIconRender";
import { language } from "../../helpers/datatableSetting";
import { renamePermissionAPI } from "../../apis/renamePermissionAPI";

const PermissionManagementTable = () => {
  const classes = useStyles();
  const { t } = useTranslation("accessManagement");
  const [open, setOpen] = useState(false);
  const [permissionId, setPermissionId] = useState();
  const [permissionName, setPermissionName] = useState();

  const handleRemovePermissionDialogClickOpen = (
    permissionId,
    permissionName
  ) => {
    setOpen(true);
    setPermissionId(permissionId);
    setPermissionName(permissionName);
  };

  const handleRemovePermissionDialogClose = () => {
    setOpen(false);
    table();
  };

  const table = async () => {
    const token = await localforage.getItem("token");
    $("#permission-management-table").DataTable({
      processing: true,
      language: language(t),
      searching: true,
      filter: false,
      deferRender: true,
      info: false,
      autoWidth: false,
      stateSave: false,
      destroy: true,
      pageLength: 15,
      ordering: true,
      order: [[0, "asc"]],
      ajax: {
        url: `${url}/api/v1/permissions`,
        type: "GET",
        dataSrc: "permissions.data",
        data: {
          page: 1
        },
        beforeSend: request => {
          request.setRequestHeader("Authorization", "Bearer " + token);
          request.setRequestHeader("Accept", "application/json");
        },
        error: () => {}
      },
      columns: [
        {
          data: "name",
          name: "data.name",
          title: `${t("table.permissionTitle")}`,
          searchable: true,
          className: "name-rename-first-column",
          createdCell: (td, data, rowData) => {
            ReactDOM.render(
              <RenameField
                id={rowData.id}
                inputValue={data}
                renameAPI={renamePermissionAPI}
              />,
              td
            );
          }
        },
        {
          data: "name",
          name: "data.name",
          title: `${t("table.actionTitle")}`,
          className: "permission-remove-column",
          searchable: false,
          createdCell: (td, data, rowData) => {
            ReactDOM.render(
              <Button
                onClick={() =>
                  handleRemovePermissionDialogClickOpen(
                    rowData.id,
                    rowData.name
                  )
                }
                startIcon={<DeleteOutlined />}
              >
                {t("table.removePermission")}
              </Button>,
              td
            );
          }
        },
        { data: "created_at", visible: false, searchable: false }
      ]
    });
    // render Material UI Search Input
    searchStyleRender();
  };

  const handleReloadDataTable = () => {
    table();
  };

  //Create Permission Props and state
  const [createPermissionDialogOpen, setCreatePermissionDialogOpen] = useState(
    false
  );
  const handleCreatePermissionDialogClickOpen = () => {
    setCreatePermissionDialogOpen(true);
  };
  const handleCreatePermissionDialogClose = () => {
    setCreatePermissionDialogOpen(false);
    table();
  };

  useEffect(() => {
    table();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className="fullwidth-table-wrapper">
      <div className="table-button-wrap">
        <IconButton
          aria-label="reload table"
          className={classes.margin}
          size="small"
          onClick={handleReloadDataTable}
        >
          <ReplayOutlined />
        </IconButton>
        <IconButton
          aria-label="reload table"
          className={classes.margin}
          size="small"
          onClick={handleCreatePermissionDialogClickOpen}
        >
          <AddBox />
        </IconButton>
      </div>
      <CreatePermissionDialog
        open={createPermissionDialogOpen}
        onClose={handleCreatePermissionDialogClose}
      />
      <table
        id="permission-management-table"
        className="mdl-data-table"
      ></table>
      <RemovePermissionDialog
        open={open}
        onClose={handleRemovePermissionDialogClose}
        permissionId={permissionId}
        permissionName={permissionName}
      />
    </Paper>
  );
};

export default PermissionManagementTable;
