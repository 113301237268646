import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

const SearchFieldAndButton = props => {
    const { resetSearchField, setResetSearchField, table, searchDatatableForTable } = props;
    const [ searchValue, setSearchValue ] = useState('');
    
    const updateSearchValue = e => setSearchValue(e.target.value);
    
    const searchDatatable = () => {
        // as 'table.search().draw()' will not run in some cases, such as dialog & multiple searching demands
        // so for datatable that won't run function above, we run 'searchDatatableForTable()' instead
        if(table) { 
            table.search(searchValue).draw();
        } else {
            searchDatatableForTable(searchValue);
        }
    }
    
    const searchInputByKeypress = e => {
        if(e.which === 13){
            e.preventDefault(); // Prevent form submit
            searchDatatable()
        }
    }

    useEffect(() => {
        if(resetSearchField) {
            setSearchValue('');
            setResetSearchField(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSearchField])

    return (
        <Box display="flex" alignItems="center" className="custom-search">
            <TextField id="searchNameField" value={searchValue} onChange={updateSearchValue} onKeyPress={searchInputByKeypress} />      
            <Button id="searchButton" onClick={searchDatatable}><SearchOutlined /></Button>              
        </Box>
    )
}

export default SearchFieldAndButton;