import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const updateCompanyAPI = async(token, company_id, values, initCompanyImg, defaultImage) => {

    let formData = new FormData();
    formData.append('id', company_id);
    formData.append('name', values.companyName);
    formData.append('parent_company_id', values.parentCompany === "-" ? "" : values.parentCompany);
    formData.append('country_id', values.companyCountry);
    formData.append('region_id', values.companyRegion);
    formData.append('address', values.companyAddress);
    formData.append('contact_person', values.contactPerson);
    if (values.file) {
        formData.append('image', values.file[0]);
        if ( initCompanyImg !== defaultImage ) { // if there is already an old image 
            formData.append('old_image', initCompanyImg);
        } else { 
            formData.append('old_image', defaultImage);
        }
    }
    formData.append('phone', values.contactPhone);
    formData.append('email', values.contactMail);
    formData.append('fec_customer_id', values.customID === "-" ? "" : values.customID);
    
    try {
        let response = await axios({
            url: `${url}/api/v1/company/update`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            },
            data: formData,
        })

        //update Profile Card 
        const userCompanyID = await localforage.getItem('userCompanyID')
        if(userCompanyID === company_id) {
            if(values.file){
                localforage.setItem('userCompanyImgLink', response.data.company.company_image_link)
            }
            if(values.contactPerson){
                localforage.setItem('userCompanyContactPerson', values.contactPerson)
            }
            if(values.contactMail){
                localforage.setItem('userCompanyMail', values.contactMail)
            }
            if(values.companyName){
                localforage.setItem('userCompanyName', values.companyName)
            }
        }
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}