import { createTheme } from "@material-ui/core/styles";

// Custom Mui Theme for our project
const theme = createTheme({
  palette: {
    primary: {
      main: "#2c3ff3"
    },
    secondary: {
      main: "#999"
    },
    error: {
      main: "#f44336"
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxSizing: "border-box"
      }
    },
    MuiTypography: {
      colorSecondary: {
        color: "#666"
      }
    },
    MuiMenu: {
      paper: {
        maxHeight: "100%",
        padding: "0 !important"
      }
    },
    MuiButton: {
      root: {
        zIndex: 9,
        padding: "0 10px !important"
      },
      contained: {
        boxShadow: "none",
        color: "white"
      },
      containedSizeSmall: {
        padding: "0 10px !important",
        height: "32px !important",
        "& .MuiButton-label": {
          padding: "0 !important"
        }
      },
      textSecondary: {
        color: "#666"
      },
      containedSecondary: {
        backgroundColor: "#bbb !important",
        color: "#fff !important",
        "&&:hover": {
          backgroundColor: "#aaa !important"
        }
      },
      label: {
        padding: "0 10px",
        minHeight: "40px"
      }
    },
    MuiPaper: {
      rounded: {
        padding: 32,
        marginBottom: 24
      }
    },
    MuiSvgIcon: {
      colorSecondary: {
        color: "#666 !important"
      }
    }
  }
});

export default theme;
