import React, { useEffect, useState, useContext } from 'react';
import { Dialog, TextField, Grid, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../../styles/delegationStyle';
import { useTranslation } from "../../langs/useTranslation";
import 'datetime-moment';
import StyledSubmitBtn from '../../components/commons/StyledSubmitBtn';
import { createRoleAPI } from '../../apis/createRoleAPI';
import { SubmitBtnContext } from '../../App';

const CreateRoleDialog = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;
    const { t } = useTranslation('accessManagement');
    const [ roleName, setRoleName ] = useState('');
    const [ roleNameErrorStatus, setRoleNameErrorStatus ] = useState(false);
    const { dispatch } = useContext(SubmitBtnContext);
  
    const handleClose = () => {
      onClose();
    };

    const handleNewGroupName = (event) => {
        setRoleName(event.target.value)
    }

    const handleCreateNewGroup = async() => {
        dispatch({type: 'showLoading'});
        if(roleName){
            const responseStatus = await createRoleAPI(roleName)
            if(responseStatus){
                setRoleName('')
                handleClose()
            }
            setRoleNameErrorStatus(false)
        } else {
            setRoleNameErrorStatus(true)
        }
        dispatch({type: 'stopLoading'});
    }

    useEffect(()=>{
    }, [open])
      
  
    return (
      <Dialog className={classes.editGroupDialog} onClose={props.onClose} aria-labelledby="edit-groups" open={props.open}>
        <DialogTitle align="center">{t('setting.createRole')}</DialogTitle>
        <DialogContent>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <TextField variant="outlined" label={t('setting.roleName')} value={roleName} onChange={handleNewGroupName}></TextField>
                    {roleNameErrorStatus ? 
                        <Typography color="error" variant="caption">{t('setting.nameRequired')}</Typography>
                        :
                        ''
                    }
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={classes.editGroupConfirmBtnWrap}>
            <StyledSubmitBtn btnColor="primary" variant="contained" btnText={t('setting.createRole')}  onClick={handleCreateNewGroup} startIcon={<AddIcon />}/>
        </DialogActions>
      </Dialog>
    );
}

export default CreateRoleDialog;