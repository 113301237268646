export const dateItemArray = [
  { name: "Within 1 month", value: "1", checked: false },
  { name: "Within 3 months", value: "3", checked: false },
  { name: "Within 6 months", value: "6", checked: false },
  { name: "Within 1 year", value: "12", checked: false }
];

export const typeArray = t => [
  { name: `${t("serviceManagement.device")}`, id: "1" },
  { name: `${t("serviceManagement.company")}`, id: "2" }
];

export const osArray = t => [
  { name: "Windows", id: "1" },
  { name: "Android", id: "2" }
];
