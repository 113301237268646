import React from 'react';
import ReactDOM from 'react-dom';
import { url } from '../apis/apiSetup';
import '../css/dataTable.css';
import { searchStyleRender } from './datatableSearchIconRender';
import * as localforage from 'localforage';
import * as $ from 'jquery';
import 'datatables.net';
import { language } from './datatableSetting';
import { parseJSON } from 'jquery';
import { getUTCDate } from './getUTCDateTime';

export const table = async (t, serviceId, companyId, companyGroupIds, valueAddedServiceIds, startedAt, endedAt, isRenewal, subscriptionId) => {

    const token = await localforage.getItem('token');
    $.fn.dataTable.ext.errMode = 'throw'; 

    $('#subscribe-device-table').DataTable({
        serverSide: false,
        processing: true,
        language: language(t),
        searching: true,
        filter: true,
        deferRender: true,
        info: true,
        paging: true,
        pageLength:15,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        select: {
            style: 'multi',
        },
        order: [[ 1, 'asc' ]],
        ajax: {
            url: `${url}/api/v1/subscribe/device-datatable`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                company_id: companyId,
                company_group_ids: companyGroupIds,
                subscribe_service_id: serviceId,
                value_added_service_ids: valueAddedServiceIds,
                started_at: startedAt ? getUTCDate(startedAt) : undefined,
                ended_at: endedAt ? getUTCDate(endedAt) : undefined,
                is_renewal: isRenewal ? isRenewal : undefined,
                subscribe_value_added_service_id: isRenewal ? subscriptionId : undefined,
            },
            beforeSend: request => {
                request.setRequestHeader('Authorization', 'Bearer ' + token );
                request.setRequestHeader('Accept', 'application/json');
            },
            complete: async() => {
                await localforage.removeItem('dtDeviceIds');
                await localforage.removeItem('dtDeviceSNs');
                $('.mdl-data-table input[type=checkbox]').each(function(){
                    $(this).prop('checked', false);
                })
            }
        },
        columnDefs: [{
            targets: 0,
            searchable: false,
            checkboxes: {
                selectRow: true
            },
        }],
        columns: [
            {
                data: 'device_id', 
                name: 'device_id',
                searchable: false, 
                createdCell: (td, data, rowData)=> {
                    ReactDOM.render (<input type="checkbox" className="dt-checkboxes" value={data} id={rowData.serial_number} />, td);
                }
            },
            {   data: 'serial_number', 
                name: 'serial_number',
                searchable: true,
                className: 'serial_number',      
                title: `${t('table.deviceS/N')}`,
                render: data => {
                    return data;
                }, 
            },
            {   data: 'company_groups', 
                name: 'company_groups',
                searchable: true,
                orderable: false,
                title: `${t('table.groups')}`, 
                render: data => {
                    if (parseJSON(data).length < 1) {
                        return '-';
                    } else {
                        let companyGroupName = parseJSON(data).map( item => item.name);
                        return companyGroupName.join(', ');
                    }
                },
            },
            {   data: 'subscriptions',
                name: 'subscriptions',
                searchable: true,
                className: 'subscriptions-td',
                title: `${t('table.subscriptions')}`, 
                createdCell: (td, data)=> {

                    const subscriptionData = parseJSON(data);
                    const validSubscriptions = subscriptionData.filter( o => o.status === 'subscribed');

                    if (validSubscriptions.length > 0) {
                        ReactDOM.render (
                            validSubscriptions.map((o, i) => (
                                <div className="service-icon-name" key={i}>
                                    <img src={o.logo} alt={o.name} />
                                    {t(`service.${o.name}`)}
                                </div>
                            ))
                        , td);
                    }

                    if (validSubscriptions.length === 0) {
                        ReactDOM.render ( '-' , td);
                    }
                }    
            },
        ]
    })
    // render Material UI Search Input
    searchStyleRender();
}
