import React, { useContext, useState } from 'react';
import { ModalContext } from '../components/commons/AppBar';
import { SubmitBtnContext } from '../App';
import { Modal, Paper, Typography, Button } from '@material-ui/core';
import { useTranslation } from "../langs/useTranslation";
import { resetPasswordtoAPI } from '../apis/resetPasswordAPI';
import StyledSubmitBtn from '../components/commons/StyledSubmitBtn';
import useStyles from '../styles/loginStyle';
import { Formik, Form } from 'formik';
import { resetPasswordValuesSchema, resetPasswordInitialValues } from '../config/formValueSchema';
import { PasswordTextField } from '../components/commons/StyledTextFields';
import { successfulHandling } from '../helpers/successfulHandling';

const ResetPasswordForm = () => {
    const classes = useStyles();
    const { t } = useTranslation('login');
    const { handleResetPwdModalClose, modalOpen } = useContext(ModalContext);
    const { dispatch } = useContext(SubmitBtnContext);
    const [ showPassword, setShowPassword ] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
   
    return(
        <Modal open={modalOpen} onClose={handleResetPwdModalClose} >
            <Paper className={classes.paperInModal}>
                <Typography variant="h5">
                    {t('resetPwd.title')}   
                </Typography>
                <Formik
                    initialValues={resetPasswordInitialValues}
                    validationSchema={resetPasswordValuesSchema(t)}
                    validateOnChange={false}
                    onSubmit={async(values) => {
                        dispatch({type: 'showLoading'});
                        let responseStatus = await resetPasswordtoAPI(values);
                        dispatch({type: 'stopLoading'});
                        if (responseStatus === true) {
                            successfulHandling(`${t('resetPwd.successfullyReset')}`, handleResetPwdModalClose);
                        } 
                    }}
                >
                    {({
                        values, errors, handleChange, handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit}>  
                            <PasswordTextField 
                                width="300px" 
                                className={ errors.oldPassword ? 'mb_16 error-text-field' : 'mb_16' }
                                label={t('resetPwd.oldPwdInputLabel')} 
                                name='oldPassword'
                                onChange={handleChange} 
                                value={values.oldPassword} 
                                helperText={errors.oldPassword}
                                show={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                            />
                            <PasswordTextField 
                                width="300px" 
                                className={ errors.newPassword ? 'mb_16 error-text-field' : 'mb_16' }
                                label={t('resetPwd.newPwdInputLabel')} 
                                name='newPassword'
                                onChange={handleChange} 
                                value={values.newPassword} 
                                helperText={errors.newPassword}
                                show={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                            />
                            <PasswordTextField 
                                width="300px" 
                                className={ errors.confirmNewPassword ? 'mb_16 error-text-field' : 'mb_16' }
                                label={t('resetPwd.newPwdConfirmInputLabel')} 
                                name='confirmNewPassword'
                                onChange={handleChange} 
                                value={values.confirmNewPassword} 
                                helperText={errors.confirmNewPassword}
                                show={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                            />
                            <StyledSubmitBtn 
                                btnColor="primary" 
                                variant="contained" 
                                btnText={t('resetPwd.resetBtn')}  
                            />
                        </Form>
                    )}
                </Formik>
                <Button 
                    color="secondary" 
                    onClick={handleResetPwdModalClose} 
                    className={classes.backBtn} 
                >
                    {t('resetPwd.backBtn')} 
                </Button>
            </Paper>
        </Modal>
    )
}

export default ResetPasswordForm;