import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Paper } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import { useTranslation } from "../langs/useTranslation";
import useStyles from '../styles/loginStyle';
import LoginForm from '../components/logins/LoginForm';

const LoginWithNewPwd = () => {

    const classes = useStyles();
    const { t } = useTranslation('login'); 

    return(
        <div className={classes.root}>
            <Paper variant="outlined" className={classes.paper}>
                <SendOutlined color="primary" />
                <Typography className={classes.loginAgainText} variant="h6" align="center">{t('loginAgain.title')}</Typography>
                <Typography className={classes.loginAgainTitle} align="center">{t('loginAgain.newPwdSent')}<br />{t('loginAgain.loginWithNewPwd')}</Typography>
                <LoginForm />    
                <NavLink to='/forgotPassword' className={classes.linkText}>{t('loginAgain.pwdSendAgainMsg')}</NavLink>                  
            </Paper>
        </div>
    )
}

export default LoginWithNewPwd;