import React, { useState, useEffect } from 'react';
import * as localforage from 'localforage';
import AntSwitch from '../commons/AntSwitch';

const EditGroupNameInput = (props) => {
    const [ checkedStatus, setCheckedStatus ] = useState(props.initialChecked);
    
    const handleSwitchChanged = async(event) => {
        const switchedPermissionId = event.target.id
        const switchedPermissionCheckedStatus = event.target.checked
        setCheckedStatus(switchedPermissionCheckedStatus);
        const permissions = await localforage.getItem(props.roleId)
        //use the id to find the changed permission
        if(permissions){
            let switchedPermission = permissions.find((o, i) => {
                if(o.id === switchedPermissionId){
                    permissions[i] = {id: o.id, name: o.name, permissionAssignedToRole: switchedPermissionCheckedStatus === false ? '' : 'checked' }
                    return true
                }else{
                    return false
                }
            })
            //update the stored permissions array
            await localforage.setItem(props.roleId, permissions)
        }
    }

    useEffect(()=>{
        setCheckedStatus(checkedStatus)
    },[props.initialChecked])


    return (
        <AntSwitch 
            id={props.permissionId}
            checked={checkedStatus}
            onChange={handleSwitchChanged} 
        />
    )
}

export default EditGroupNameInput;