import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";  
import "../../node_modules/noty/lib/themes/relax.css"; 

export const successfulHandling = (text, afterClose) => {
    new Noty({
        type: 'success',
        theme: 'relax',
        layout: 'topRight',
        closeWith: ['click', 'button'],
        timeout: process.env.REACT_APP_NOTY_TIMEOUT,
        text: text,
        callbacks: {
            afterClose: function() {
                afterClose();
            }
        }
    }).show();
}
