import React, { useState, useEffect } from "react";
import { useTranslation } from "../../langs/useTranslation";
import { TextField, IconButton, Grid, Button } from "@material-ui/core";
import EditOutlined from "@material-ui/icons/EditOutlined";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import useStyles from "../../styles/accessManagementStyle";

const RenameField = props => {
  const classes = useStyles();
  const { t } = useTranslation("accessManagement");
  const { inputValue, id, renameAPI, onlyIconButton } = props;
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [value, setValue] = useState(inputValue);
  const [idForApi, setIdForApi] = useState();
  const [storedValue, setStoredValue] = useState(inputValue);

  const handleTextEditabled = () => setIsFieldDisabled(false);

  const handleTextSaved = () => {
    setIsFieldDisabled(true);
    if (value !== storedValue)
      renameAPI(idForApi, value, storedValue, setStoredValue, setValue);
  };

  const handleTextEditabledCancel = () => {
    setValue(storedValue);
    setIsFieldDisabled(true);
  };

  const handleValueChanged = event => {
    setValue(event.target.value);
    setIdForApi(event.target.id);
  };

  useEffect(() => {
    setValue(inputValue);
    setStoredValue(inputValue);
  }, [inputValue]);

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={8}>
        <TextField
          className={classes.renameInput}
          id={id}
          value={value}
          disabled={isFieldDisabled}
          onChange={handleValueChanged}
        />
      </Grid>
      {isFieldDisabled ? (
        <Grid item xs={4}>
          {onlyIconButton ? (
            <IconButton onClick={handleTextEditabled}>
              <EditOutlined fontSize="small" />
            </IconButton>
          ) : (
            <Button
              aria-label="edit"
              onClick={handleTextEditabled}
              startIcon={<EditOutlined fontSize="small" />}
            >
              {t("table.rename")}
            </Button>
          )}
        </Grid>
      ) : (
        <Grid item xs={4}>
          <IconButton aria-label="edit" size="small" onClick={handleTextSaved}>
            <CheckRoundedIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="cancel"
            size="small"
            onClick={handleTextEditabledCancel}
          >
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default RenameField;
