import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import { AuthContext } from '../../auths/Auth';
import useStyles from '../../styles/commonStyle';
import { useTranslation } from "../../langs/useTranslation";

const UserMenu = (props) => {
    const classes = useStyles();
    const { anchorEl, open, onClose, handleResetPwdModelOpen } = props;
    const { t } = useTranslation('common'); 
    const { logout } = useContext(AuthContext);
    const history = useHistory();

    const handleLogout = () => {
        logout();
        //history.replace('/login');
    }

    return (
        <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        className={classes.menuList}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={onClose}
        >
            <MenuItem onClick={handleResetPwdModelOpen}>{t('appbar.resetPwdBtn')}</MenuItem>
            <MenuItem onClick={handleLogout}>{t('appbar.logoutBtn')}</MenuItem>
        </Menu>
    )
}

export default UserMenu;