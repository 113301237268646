import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { generateRecursiveList } from '../helpers/generateRecursiveList';
import { flatMultiDimensional } from '../helpers/flatMultiDimensional';
import _ from 'lodash';

export const getCompanyTreeAPI = async() => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('userCompanyID');

    try {
        let response = await axios({
            url: `${url}/api/v1/company-tree`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            params: {
                company_id: companyId
            }
        })
        if (response.data && response.data.companies) {
            const companyTreeArr = generateRecursiveList(response.data.companies);
            const flattenSubCompaniesArr = flatMultiDimensional(response.data.companies);
            const flattenCompanyTreeWithoutHQ = _.reject(flattenSubCompaniesArr, function(o){return !o.parent_company_id});
            await localforage.setItem('companyTree', companyTreeArr);
            await localforage.setItem('flattenCompanyTree', flattenSubCompaniesArr);
            await localforage.setItem('flattenCompanyTreeWithoutHQ', flattenCompanyTreeWithoutHQ);
            return true;
        }
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}