import React, { useContext, useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { SubmitBtnContext } from "../../App";
import { TextField, Paper, Button, CircularProgress } from "@material-ui/core";
import { EditOutlined, SendOutlined } from "@material-ui/icons";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/companyProfileFormstyle";
import { Formik } from "formik";
import * as localforage from "localforage";
import { useParams } from "react-router-dom";
import { updateCompanyAPI } from "../../apis/updateCompanyAPI";
import StyledSubmitBtn from "../../components/commons/StyledSubmitBtn";
import { getSubCompanyAPI } from "../../apis/getSubCompanyAPI";
import { createCompanyAPI } from "../../apis/createCompanyAPI";
import { customersAPI } from "../../apis/customersAPI";
import { flatMultiDimensional } from "../../helpers/flatMultiDimensional";
import defaultImage from "../../assets/images/default_image.svg";
import "../../css/avatarEditor.css";
import HasPermission from "../../components/commons/HasPermission";
import { companyProfileFormValueSchema } from "../../config/formSchema";
import { generateRecursiveList } from "../../helpers/generateRecursiveList";
import { successfulHandling } from "../../helpers/successfulHandling";
import { CaptionText, SimpleErrorText } from "../commons/StyledTexts";

const CompanyProfileForm = (props) => {
  const classes = useStyles();
  const { company_id } = useParams();
  const { companyData } = props;
  const { t } = useTranslation("accountCenter");
  const { dispatch } = useContext(SubmitBtnContext);
  const [assignSelfCompanyID, setAssignSelfCompanyID] = useState("");
  const [initCompanyName, setInitCompanyName] = useState(companyData?.name);
  const [initParentCompany, setInitParentCompany] = useState(
    companyData?.parent_company_id
  );
  const [initCompanyImg, setInitCompanyImg] = useState(defaultImage);
  const [initCompanyAddress, setInitCompanyAddress] = useState(
    companyData.address
  );
  const [companyParentName, setCompanyParentName] = useState("");
  const [initContactPerson, setInitContactPerson] = useState("");
  const [initContactMail, setInitContactMail] = useState("");
  const [initContactPhone, setInitContactPhone] = useState("");
  const [initCountryID, setInitCountryID] = useState("");
  const [initRegionID, setInitRegionID] = useState("");
  const [initCustomID, setInitCustomID] = useState("");
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [subCompaniesArr, setSubCompaniesArr] = useState([]);
  const [countriesArr, setCountriesArr] = useState([]);
  const [customersArr, setCustomersArr] = useState([]);
  const [regionsArr, setRegionsArr] = useState([]);
  // const [isFECHQ, setIsFECHQ] = useState(false);
  const [belongPermission, setBelongPermission] = useState(false);
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const [isFileExtensionError, setIsFileExtensionError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userCompanyID, setUserCompanyID] = useState(null);

  const handleFormEdit = () => setIsFormEditable(true);
  const handleCancelFormEdit = () => {
    setIsFormEditable(false);
    setIsFileExtensionError(false);
    setIsFileSizeError(false);
  };

  const getCompanyData = useCallback(async () => {
    const userPermissions = await localforage.getItem("userPermissions");
    const userCompanyName = await localforage.getItem("userCompanyName");
    if (userCompanyName) {
      setCompanyParentName(userCompanyName);
    }
    if (userPermissions) {
      const isPermission = userPermissions.includes(
        "account_belong_to_dropdown"
      );
      setBelongPermission(!!isPermission);
    }
    const userCompanyID = await localforage.getItem("userCompanyID");
    setAssignSelfCompanyID(userCompanyID);
    setUserCompanyID(userCompanyID);

    if (company_id === "new") {
      setIsLoading(false);
      setInitCompanyName("");
      setInitCompanyImg(defaultImage);
      setInitCompanyAddress("");
      setInitContactPhone("");
      setInitContactPerson("");
      setInitContactMail("");
      setInitRegionID("");
      setInitCountryID("");
      setInitCustomID("");
      setInitParentCompany(userCompanyID);
      setIsFormEditable(true);

      const subcompaniesArr = await getSubCompanyAPI();
      const flattenSubCompaniesArr = flatMultiDimensional(
        generateRecursiveList(
          subcompaniesArr.filter((o) => o.id !== company_id)
        )
      );
      setSubCompaniesArr(
        flattenSubCompaniesArr.filter((o) => o.key !== company_id)
      );
      return;
    }
    if (companyData) {
      setIsLoading(true);
      setInitCompanyName(companyData.name);
      setInitCompanyImg(companyData.company_image_link || defaultImage);
      setInitCompanyAddress(companyData.address);
      setInitContactPhone(companyData.phone);
      setInitContactPerson(companyData.contact_person);
      setInitContactMail(companyData.email);

      if (companyData.parent_company_id) {
        setInitParentCompany(companyData.parent_company_id);
      } else {
        setInitParentCompany("-");
        // setIsFECHQ(true);
        setIsFormEditable(false);
      }

      setInitRegionID(companyData.region_id || "-");
      setInitCountryID(companyData.country_id || "-");
      setInitCustomID(companyData.fec_customer_id || "-");

      const subcompaniesArr = await getSubCompanyAPI();
      const flattenSubCompaniesArr = flatMultiDimensional(
        generateRecursiveList(
          subcompaniesArr.filter((o) => o.id !== company_id)
        )
      );
      setSubCompaniesArr(
        flattenSubCompaniesArr.filter((o) => o.key !== company_id)
      );

      setIsLoading(false);
    } else {
      window.location.replace("/account-center");
    }
  }, [company_id, companyData]);

  const getAllRegions = async () => {
    const regions = await localforage.getItem("regions");
    setRegionsArr(regions);
  };

  const getAllCountries = async () => {
    const countries = await localforage.getItem("countries");
    setCountriesArr(countries);
  };

  const getAllCustomers = async () => {
    const customers = await customersAPI();
    if (customers) {
      const customersIdAndNameArr = customers.map((customer) => ({
        customer_id: customer.customer_id,
        customer_name: `(${customer.customer_id}) ${customer.customer_name}`,
      }));
      setCustomersArr(
        customersIdAndNameArr.sort(
          (a, b) => 2 * (a.customer_id > b.customer_id) - 1
        )
      );
    }
  };

  const inputCursorStyle = !isFormEditable ? {} : { cursor: "pointer" };

  useEffect(() => {
    getCompanyData();
    getAllRegions();
    getAllCountries();
    getAllCustomers();
  }, [getCompanyData]);

  const initialValues = {
    companyName: initCompanyName,
    parentCompany: initParentCompany,
    contactPhone: initContactPhone,
    contactMail: initContactMail,
    companyAddress: initCompanyAddress,
    companyCountry: initCountryID,
    companyRegion: initRegionID,
    contactPerson: initContactPerson,
    customID: initCustomID,
  };

  return (
    <Paper elevation={3} className={`height-100 ${classes.companyProfileForm}`}>
      {isLoading ? (
        <div className={classes.loadingWrap}>
          <CircularProgress />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={companyProfileFormValueSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            if (!isFileExtensionError && !isFileSizeError) {
              dispatch({ type: "showLoading" });
              const token = await localforage.getItem("token");
              let responseStatus = await (company_id === "new"
                ? createCompanyAPI(token, values, initCompanyImg, defaultImage)
                : updateCompanyAPI(
                    token,
                    company_id,
                    values,
                    initCompanyImg,
                    defaultImage
                  ));
              if (responseStatus) {
                successfulHandling(
                  `${t("companyProfileForm.successfullyUpdateCompany")}`
                );
                handleCancelFormEdit();
              }
              dispatch({ type: "stopLoading" });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className={classes.mainContent}>
              <div className="avatar-editor">
                <input
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={(event) => {
                    setIsFileSizeError(false);
                    let files = event.target.files;
                    if (files && files[0]) {
                      const src = URL.createObjectURL(files[0]);
                      const fileSize = files[0].size / 1024 / 1024;
                      if (fileSize > 0.5) {
                        setIsFileSizeError(true);
                        return;
                      }
                      setIsFileSizeError(false);
                      setFieldValue("file", files);
                      setInitCompanyImg(src);
                    }
                  }}
                  disabled={!isFormEditable}
                  style={inputCursorStyle}
                />
                <div className="avatar-image">
                  <img src={initCompanyImg} alt="avatar" />
                </div>
                {isFormEditable && (
                  <div>
                    <CaptionText text={t("companyProfileForm.imageSize")} />
                    <CaptionText text={t("companyProfileForm.imageFormat")} />
                    {isFileSizeError && (
                      <SimpleErrorText
                        text={t(
                          "companyProfileForm.pleaseUploadFileWithin500KB"
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
              <TextField
                variant="standard"
                name="companyName"
                placeholder={initCompanyName}
                value={values.companyName}
                className={`${classes.input} ${classes.inputLong}`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isFormEditable || isSubmitting}
                label={t("companyProfileForm.companyName")}
                helperText={
                  errors.companyName &&
                  touched.companyName &&
                  errors.companyName
                }
                error={
                  !!(
                    errors.companyName &&
                    touched.companyName &&
                    errors.companyName
                  )
                }
              />
              {(company_id === "new" && !belongPermission) ||
              company_id === userCompanyID ? (
                ""
              ) : (
                <TextField
                  select
                  SelectProps={{
                    native: true, disableScrollLock: true
                  }}
                  name="parentCompany"
                  disabled={
                    !isFormEditable ||
                    !belongPermission ||
                    company_id === userCompanyID
                  }
                  value={values.parentCompany}
                  onChange={handleChange}
                  className={clsx({
                    [classes.input]: true,
                    [classes.roleSelected]: values.parentCompany,
                  })}
                  onBlur={handleBlur}
                  label={t("companyProfileForm.companyBelongTo")}
                  helperText={
                    errors.parentCompany &&
                    touched.parentCompany &&
                    errors.parentCompany
                  }
                  error={
                    !!(
                      errors.parentCompany &&
                      touched.parentCompany &&
                      errors.parentCompany
                    )
                  }
                >
                  <option value={assignSelfCompanyID}>
                    {companyParentName}
                  </option>
                  {subCompaniesArr.map((subCompany, index) => (
                    <option value={subCompany.key} key={index}>
                      {subCompany.title}
                    </option>
                  ))}
                </TextField>
              )}
              <TextField
                select
                SelectProps={{
                  native: true, disableScrollLock: true
                }}
                name="companyCountry"
                disabled={!isFormEditable || isSubmitting}
                value={values.companyCountry}
                onChange={handleChange}
                className={clsx({
                  [classes.input]: true,
                  [classes.roleSelected]: values.companyCountry,
                })}
                onBlur={handleBlur}
                label={t("companyProfileForm.companyCountry")}
                helperText={
                  errors.companyCountry &&
                  touched.companyCountry &&
                  errors.companyCountry
                }
                error={
                  !!(
                    errors.companyCountry &&
                    touched.companyCountry &&
                    errors.companyCountry
                  )
                }
              >
                <option value=""></option>
                {countriesArr.map((country, index) => (
                  <option value={country.id} key={index}>
                    {country.name}
                  </option>
                ))}
              </TextField>
              <TextField
                variant="standard"
                name="companyAddress"
                value={values.companyAddress}
                className={`${classes.input} ${classes.inputLong}`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isFormEditable || isSubmitting}
                label={t("companyProfileForm.companyAddress")}
                helperText={
                  errors.companyAddress &&
                  touched.companyAddress &&
                  errors.companyAddress
                }
                error={
                  !!(
                    errors.companyAddress &&
                    touched.companyAddress &&
                    errors.companyAddress
                  )
                }
              />
              <TextField
                select
                SelectProps={{
                  native: true, disableScrollLock: true
                }}
                name="companyRegion"
                disabled={!isFormEditable || isSubmitting}
                value={values.companyRegion}
                onChange={handleChange}
                className={clsx({
                  [classes.input]: true,
                  [classes.roleSelected]: values.companyRegion,
                })}
                onBlur={handleBlur}
                label={t("companyProfileForm.companyRegion")}
                helperText={
                  errors.companyRegion &&
                  touched.companyRegion &&
                  errors.companyRegion
                }
                error={
                  !!(
                    errors.companyRegion &&
                    touched.companyRegion &&
                    errors.companyRegion
                  )
                }
              >
                <option value=""></option>
                {regionsArr.map((region, index) => (
                  <option value={region.id} key={index}>
                    {region.name}
                  </option>
                ))}
              </TextField>
              <TextField
                variant="standard"
                placeholder="Contact Person *"
                name="contactPerson"
                value={values.contactPerson}
                className={classes.input}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isFormEditable}
                label={t("companyProfileForm.contactPerson")}
                helperText={
                  errors.contactPerson &&
                  touched.contactPerson &&
                  errors.contactPerson
                }
                error={
                  !!(
                    errors.contactPerson &&
                    touched.contactPerson &&
                    errors.contactPerson
                  )
                }
              />
              <TextField
                variant="standard"
                placeholder="Contact Phone *"
                name="contactPhone"
                value={values.contactPhone}
                className={classes.input}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isFormEditable || isSubmitting}
                label={t("companyProfileForm.contactPhone")}
                helperText={
                  errors.contactPhone &&
                  touched.contactPhone &&
                  errors.contactPhone
                }
                error={
                  !!(
                    errors.contactPhone &&
                    touched.contactPhone &&
                    errors.contactPhone
                  )
                }
              />
              <TextField
                variant="standard"
                placeholder="Email *"
                name="contactMail"
                value={values.contactMail}
                className={`${classes.input} ${classes.inputLong}`}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isFormEditable || isSubmitting}
                label={t("companyProfileForm.contactMail")}
                helperText={
                  errors.contactMail &&
                  touched.contactMail &&
                  errors.contactMail
                }
                error={
                  !!(
                    errors.contactMail &&
                    touched.contactMail &&
                    errors.contactMail
                  )
                }
              />

              <HasPermission permission="customer_id">
                <TextField
                  select
                  SelectProps={{
                    native: true, disableScrollLock: true
                  }}
                  name="customID"
                  disabled={!isFormEditable || isSubmitting}
                  value={values.customID}
                  onChange={handleChange}
                  className={clsx({
                    [classes.input]: true,
                    [classes.roleSelected]: values.customID,
                  })}
                  onBlur={handleBlur}
                  label={t("companyProfileForm.customID")}
                  helperText={
                    errors.customID && touched.customID && errors.customID
                  }
                  error={
                    !!(errors.customID && touched.customID && errors.customID)
                  }
                >
                  <option value=""></option>
                  {customersArr.map((customer, index) => (
                    <option value={customer.customer_id} key={index}>
                      {customer.customer_name}
                    </option>
                  ))}
                </TextField>
              </HasPermission>

              {!isFormEditable ? (
                <HasPermission permission="edit_company_info">
                  <Button
                    color="primary"
                    className={classes.formControlBtn}
                    startIcon={<EditOutlined />}
                    onClick={handleFormEdit}
                  >
                    {t("companyProfileForm.editBtn")}
                  </Button>
                </HasPermission>
              ) : (
                <div className={classes.formControlBtn}>
                  <StyledSubmitBtn
                    btnColor="primary"
                    startIcon={<SendOutlined />}
                    buttonClassname={classes.submitFormBtn}
                    btnText={t("companyProfileForm.confirmBtn")}
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm(initialValues);
                      handleCancelFormEdit();
                    }}
                  >
                    {t("companyProfileForm.cancelBtn")}
                  </Button>
                </div>
              )}
            </form>
          )}
        </Formik>
      )}
    </Paper>
  );
};

export default CompanyProfileForm;
