import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const updateDeviceNubisVersionAPI = async (token, deviceIds, nubisVersion) => {
        try {
            await axios({
                url: `${url}/api/v1/devices/nubis-version/update`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                },
                data: {
                    device_ids: deviceIds,
                    nubis_version: nubisVersion,
                }
            });
            return true;
        } catch (error) {
            errorHandling(error, true);
        }
}