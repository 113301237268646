import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import "../css/dataTable.css";
import moment from "moment";
import { searchStyleRender } from "./datatableSearchIconRender";
import * as localforage from "localforage";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-select";
import "jquery-datatables-checkboxes";
import "datetime-moment";
import { language, buttons } from "./datatableSetting";
import { parseJSON } from "jquery";
import '../helpers/datatableInputPagination.js'

export const table = async (
  t,
  action,
  selectedCompanyIds,
  selectedGroupIds,
  nubisVersion
) => {
  const token = await localforage.getItem("token");
  const company_id = await localforage.getItem("userCompanyID");
  const userPermissions = await localforage.getItem("userPermissions");
  let actionsDontNeedSelectedIds = ["assign", "register"];

  $("#device-list-table").DataTable({
    dom: "Bfrtip",
    serverSide: true,
    processing: true,
    language: language(t),
    buttons: [
      {
        extend: "excel",
        text: null,
        className: "export-button",
        init: (api, node) => {
          buttons(userPermissions, node);
        },
      },
    ],
    searching: true,
    filter: true,
    deferRender: true,
    info: false,
    paging: true,
    pageLength: 15,
    pagingType:'input',
    autoWidth: false,
    stateSave: false,
    destroy: true,
    ordering: true,
    select: {
      style: "multi",
    },
    order: [[1, "asc"]],
    ajax: {
      url: `${url}/api/v1/devices`,
      type: "GET",
      dataSrc: "data",
      data: {
        company_id: company_id,
        nubis_version: nubisVersion ? nubisVersion : "",
        status: action === "register" ? "inactive" : "",
        group_ids: actionsDontNeedSelectedIds.includes(action)
          ? ""
          : selectedGroupIds,
        sub_company_ids: actionsDontNeedSelectedIds.includes(action)
          ? ""
          : selectedCompanyIds,
      },
      beforeSend: (request) => {
        request.setRequestHeader("Authorization", "Bearer " + token);
        request.setRequestHeader("Accept", "application/json");
      },
      complete: async () => {
        $(".mdl-data-table input[type=checkbox]").each(function() {
          $(this).prop("checked", false);
        });
      },
    },
    columnDefs: [
      {
        targets: 0,
        searchable: false,
        checkboxes: {
          selectRow: true,
        },
      },
    ],
    columns: [
      {
        data: "device_id",
        name: "device_id",
        searchable: false,
        createdCell: (td, data) => {
          ReactDOM.render(
            <input type="checkbox" className="dt-checkboxes" value={data} />,
            td
          );
        },
      },
      {
        data: "serial_number",
        name: "serial_number",
        searchable: true,
        className: "serial_number",
        title: `${t("table.deviceSN")}`,
        render: (data) => {
          return data;
        },
      },
      {
        data: "company_groups",
        name: "company_groups",
        searchable: true,
        orderable: false,
        title: `${t("table.groups")}`,
        render: (data) => {
          if (parseJSON(data).length < 1) {
            return "-";
          } else {
            let companyGroupName = parseJSON(data).map((item) => item.name);
            return companyGroupName.join(", ");
          }
        },
      },
      {
        data: "companies",
        name: "companies",
        searchable: true,
        orderable: false,
        title: `${t("table.owners")}`,
        render: (data) => {
          if (parseJSON(data).length < 1) {
            return "-";
          } else {
            let companyName = parseJSON(data).map((item) => item.name);
            return companyName.join(", ");
          }
        },
      },
      {
        data: "status",
        name: "status",
        searchable: true,
        title: `${t("table.status")}`,
        createdCell: (td, data) => {
          ReactDOM.render(
            <span className={`${data} status`}>{t(`table.${data}`)}</span>,
            td
          );
        },
      },
      {
        data: "nubis_version",
        name: "nubis_version",
        searchable: true,
        title: `${t("table.nubisVersion")}`,
        createdCell: (td, data) => {
          if (data) {
            ReactDOM.render(
              <span className={`${data} nubis-version`}>
                {t(`setting.${data}`)}
              </span>,
              td
            );
          } else {
            ReactDOM.render("-", td);
          }
        },
      },
      {
        data: "registered_at",
        name: "registered_at",
        searchable: true,
        title: `${t("table.registeredAt")}`,
        className: "date-td",
        render: (data) => {
          if (!data) {
            return "-";
          } else {
            return moment(data).format("lll");
          }
        },
      },
      {
        data: "activated_at",
        name: "activated_at",
        searchable: true,
        title: `${t("table.activatedAt")}`,
        className: "date-td",
        render: (data) => {
          if (!data) {
            return "-";
          } else {
            return moment(data).format("lll");
          }
        },
      },
    ],
  });
  // render Material UI Search Input
  searchStyleRender();
};
