import React, { useEffect, useState, useCallback } from "react";
import { NavLink } from 'react-router-dom';
import { Tree } from "antd";
import "antd/dist/antd.min.css";
import "../../css/companyTree.css";
import { TextField, Paper, Divider, Button, Typography } from '@material-ui/core';
import { AddOutlined, Search } from '@material-ui/icons';
import * as localforage from "localforage";
import useStyles from '../../styles/accountCenterStyle.js';
import { useTranslation } from "../../langs/useTranslation";
import { getCompanyTreeAPI } from '../../apis/getCompanyTreeAPI';
import HasPermission from '../commons/HasPermission';
import { useHistory } from "react-router-dom";
import { useBoundingRect } from "../../helpers/useBoundingRect";

const CompanyTree = () => {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation('accountCenter');
  const [treeData, setTreeData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [timer, setTimer] = useState(null);

  const [ bbox, treeOuterRef ] = useBoundingRect();

  const expKeys = new Set();
  const filterTreeBySearch = useCallback((data) => {
    let tree = [];
    if (data) {
      tree = data.map((item) => {
        const titleLowerCase = item.title.toLowerCase();
        const itemTitle = item.title;
        const num = item.num;
        const index = titleLowerCase.indexOf(searchValue.toLowerCase());
        const beforeStr = itemTitle.substr(0, index);
        const afterStr = itemTitle.substr(index + searchValue.length);
        const valueStr = itemTitle.substr(index, searchValue.length);

        let title = "";
        let childrenResult = filterTreeBySearch(item.children)

        if (index > -1) {
          if(searchValue !== '' && item.parent_id){
            expKeys.add(item.parent_id);
          }
          title = (
            <span className="tree-outer">
              {beforeStr}
              <span className="site-tree-search-value">{valueStr}</span>
              {afterStr}
            </span>
          );
          return {
            title,
            key: item.key,
            children: childrenResult,
            name: itemTitle,
          };
        } else if(childrenResult.length > 0){
          if(searchValue !== '' && item.parent_id){
            expKeys.add(item.parent_id);
          }
          title = (
            <span className="tree-outer">
              {item.title}
            </span>
          );
          return {
            title,
            key: item.key,
            children: childrenResult,
            name: itemTitle,
          };
        } else {
          return null;
        }
      }).filter( item => item ?? false);

      return tree;

    } else {
      return [];
    }
  }, [searchValue]);

  const getTreeData = async () => {
    await getCompanyTreeAPI();
    const companyTree =  await localforage.getItem('companyTree')
    setTreeData(companyTree);
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const onSelect = (selectedKeys) => {
    history.push(`/company-profile/${selectedKeys}`);
  };

  useEffect(() => {
    getTreeData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    setTimer(setTimeout( () => {
        setFilteredTreeData(filterTreeBySearch(treeData))
        setExpandedKeys(Array.from(expKeys));
    }, 200));
  }, [searchValue]);

  //First-show company tree
  useEffect(() => {
      setFilteredTreeData(filterTreeBySearch(treeData));
      setExpandedKeys(treeData.map( d => d.key ));
  }, [treeData])

  return (
    <div>
       <div className={classes.searchInput}>
        <TextField
          label={t('accountCenter.searchBarFieldDefaultText')}
          variant="outlined"
          onChange={onChange}
        />
        <Search className={classes.searchIcon} color="action" />
      </div>
      <Divider />
      <HasPermission permission="create_company">
        <NavLink to="/company-profile/new">
          <Button
              variant="contained"
              color="primary"
              className={classes.createCompanyBtn}
              startIcon={<AddOutlined />}
          >
              {t('accountCenter.createCompanyBtnText')}
          </Button>   
        </NavLink>
      </HasPermission>
      <Typography variant="h5" className={classes.companyTreeTitle}>{t('accountCenter.companyTreeTitle')}</Typography>
      <Paper variant="outlined" className={classes.companyTreeContent}>
        {

          (filteredTreeData.length > 0) ?
              (
                <div className={classes.treeOuter} id="treeOuter" ref={treeOuterRef}>
                  <Tree
                    defaultExpandAll={true}
                    showIcon={false}
                    onExpand={onExpand}
                    defaultSelectedKeys={[]}
                    treeData={filteredTreeData}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onSelect={onSelect}
                    showLine={{showLeafIcon: false}}
                    height={bbox.height ? bbox.height : 390}
                  />
                </div>
              )
            :
              <div style={{textAlign: 'center'}}>
                <p>No matched result.</p>
              </div>
        }
      </Paper>
    </div>
  );
};
export default CompanyTree;
