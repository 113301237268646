import React from 'react';
import { Typography, Box, TextField } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { MissingSelectedErrorText } from '../commons/StyledTexts';

const OtherCapacityTextfield = props => {
    const { t } = useTranslation('subscribeServices');
    const { planContentValue, onClick, onChange, isValueValid, capacityValue, isRenewal } = props;

    return (
        <Box display="flex" flexDirection="column">
            <Box display="inline-flex" alignItems="flex-end">
                <Box width="100px" mr={1}>
                    <TextField 
                        disabled={isRenewal}
                        value={ capacityValue === 'other' && planContentValue ? planContentValue : '' } 
                        onClick={onClick} 
                        onChange={onChange} />
                </Box>
                <Typography variant="caption">
                    ({t('subscribeNewService.capacityExample')})
                </Typography>
            </Box>
            { planContentValue && !isValueValid && capacityValue === 'other' && 
                <MissingSelectedErrorText text="pleaseEnterValidValueWithUnit" />
            }
        </Box>
    )
}

export default OtherCapacityTextfield;