import React from 'react';
import { useParams } from "react-router-dom";
import { Breadcrumbs, Link, Drawer, Typography } from '@material-ui/core';
import { HomeOutlined} from '@material-ui/icons';
import useStyles from '../styles/subscribeServicesStyle';
import { useTranslation } from "../langs/useTranslation";
import SubscribeDrawerBtns  from '../components/subscribeServices/DrawerBtns';
import SubscribeForm from '../components/subscribeServices/SubscribeForm';

const SubscribeNewService = () => {
    const classes = useStyles();
    const { t } = useTranslation('subscribeServices');
    let { id } = useParams();
    const startDateLocalforageName = 'subscriptionStartDate';
    const endDateLocalforageName = 'subscriptionEndDate';
    const title = id ? `${t('subscribeNewService.renewalTitle')}` : `${t('subscribeNewService.title')}`;

    return(
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <SubscribeDrawerBtns />
                </Drawer>
            </nav>
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href='/'>
                        <HomeOutlined />
                    </Link>
                    <Typography color="textPrimary">
                        {title}
                    </Typography>
                </Breadcrumbs>
                <div className={classes.titleSection}>
                    <Typography variant="h4">
                        {title}
                    </Typography>
                </div>
                <SubscribeForm
                    startDateLocalforageName={startDateLocalforageName}
                    endDateLocalforageName={endDateLocalforageName}
                />
            </main>
        </div>
    )
}

export default SubscribeNewService;