import localforage from 'localforage';
import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";  
import "../../node_modules/noty/lib/themes/relax.css"; 

export const errorHandling = (error, shouldRedirect = true, needTranslation = false, t) => {
    if(error.response && error.response.data) {
        let errorObject = error.response.data;
        errorObject.message = "Already logout on other page";
        let status = error.response.status;
        let httpCodes = [400, 401, 403, 422];
        if(status === httpCodes[3]) {
            for (let objs in errorObject.errors) {
                errorObject.errors[objs].forEach((errMsg) => {
                    new Noty({
                        theme: 'relax',
                        type: 'error',
                        closeWith: ['click', 'button'],
                        layout: 'topRight',
                        text: needTranslation ? `${t(`error.${errMsg}`)}` : errMsg,
                    }).show();
                })
            }
        } else if(status === httpCodes[1]) {
           if (shouldRedirect === true) {
            localforage.clear()    
            window.location.replace('/login')
           }
           if (shouldRedirect === false) {
                new Noty({
                    theme: 'relax',
                    type: 'error',
                    closeWith: ['click', 'button'],
                    layout: 'topRight',
                    text: errorObject.message,
                }).show();
           }
        }
    } else {
        return error
    }
}