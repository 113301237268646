import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const uploadExistingDevicesAPI = async (token, action, selectedCompanyIds, selectedGroupIds, excelFile, deviceStatus, nubisVersion) => {

    let formData = new FormData();
    formData.append('actions', action);
    for (let i = 0; i < selectedCompanyIds.length; i++) {
        formData.append('sub_company_ids[]', selectedCompanyIds[i]);
    }
    for (let i = 0; i < selectedGroupIds.length; i++) {
        formData.append('company_group_ids[]', selectedGroupIds[i]);
    }
    formData.append('spreadsheet', excelFile);
    if(action === 'register'){
        formData.append('status', 'registered');
    }
    if(action === 'nubis_free' || action === 'nubis_pro'){
        formData.append('nubis_version', action );
    }
    try {
        await axios({
            url: `${url}/api/v1/devices/upload-exist-devices`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token
            },
            data: formData
        });
        return true;
    } catch (error) {
        errorHandling(error, true);
    }
}