import React, { useEffect, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Typography, Paper, IconButton } from '@material-ui/core';
import useStyles from '../../styles/accessManagementStyle';
import { url } from '../../apis/apiSetup';
import { SubmitBtnContext } from '../../App';
import '../../css/dataTable.css';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import * as $ from 'jquery';
import 'datatables.net';
import 'datetime-moment';
import { ReplayOutlined, SendOutlined } from '@material-ui/icons';
import StyledSubmitBtn from '../commons/StyledSubmitBtn';
import { useParams } from 'react-router-dom';
import EditRolePermissionSwitch from './EditRolePermissionSwitch';
import { updateRoleWithPermissionsAPI } from '../../apis/updateRoleWithPermissionsAPI';
import { successHandling } from '../../helpers/successHandling';
import { searchStyleRender } from '../../helpers/datatableSearchIconRender';

const RoleManagementTable = () => {
    const classes = useStyles();
    const { role_id, role_name } = useParams();
    const { t } = useTranslation('accessManagement');
    const { dispatch } = useContext(SubmitBtnContext);
    const [ permissions, setPermissions ] = useState([])

    const handleUpdateRolePermission = async() => {
        dispatch({type: 'showLoading'});
        const responseStatus = await updateRoleWithPermissionsAPI(role_id, role_name);
        if(responseStatus){
            successHandling(t, 'setting.successfullyUpdatePermission', 'success');
            table();
        }
        dispatch({type: 'stopLoading'});
    }

    const table = async() => {
        const token = await localforage.getItem('token')
        $('#role-permission-table').DataTable({
            processing: true,
            language:{
                zeroRecords: `${t('table.zeroRecords')}`,
                loadingRecords: `${t('table.loadingRecords')}`,
                infoEmpty: `${t('table.noRecordsAvailable')}`,
                processing: '<div class="loading-wrapper table-loading-wrapper"><div class="loadingio-spinner-ellipsis-0e9htm6lpb7"><div class="ldio-tk8dyrglbc"><div></div><div></div><div></div><div></div><div></div></div></div></div>'
            },
            searching: true,
            filter: false,
            deferRender: true,
            info: false,
            autoWidth: false,
            stateSave: false,
            destroy: true,
            pageLength:15,
            ordering: true,
            order: [[ 0, 'asc' ]],
            ajax: {
                url: `${url}/api/v1/roles/edit`,
                type: 'GET',
                dataSrc: function(json){
                    setPermissions(json.permissions)
                    return json.permissions
                },
                data: {
                    role_id: role_id,
                },
                beforeSend: (request) => {
                    request.setRequestHeader('Authorization', 'Bearer ' + token );
                    request.setRequestHeader('Accept', 'application/json');
                },
                error: () => {
                    // window.location.replace(process.env.REACT_APP_UNAUTH_REDIRECT_URL);
                },
            },
            columns: [
                {   data: 'name', 
                    title: `${t('table.permissionTitle')}`,
                    searchable: true,
                    createdCell:  (td, data) => {
                        ReactDOM.render(<Typography variant="body1">{data}</Typography>,td)
                    } 
                },
                {   data: 'permissionAssignedToRole', 
                    title: `${t('table.statusTitle')}`,
                    searchable: true,
                    className: 'switch-column',
                    createdCell:  async(td, data, rowData) => {
                        ReactDOM.render(<div><EditRolePermissionSwitch permissionId={rowData.id} initialChecked={data === 'checked' ? true : false} roleId={role_id} permissionData={rowData} /></div>, td)
                    }, 
                }
            ]
        })
         // render Material UI Search Input
         searchStyleRender()
    }

    const handleReloadDataTable = () => {
        table()
    }

    const storedPermissionsToLocalforage = async() => {
        await localforage.setItem(role_id, permissions)
    }

    useEffect(()=>{
        table()
    },[])

    useEffect(()=>{
        storedPermissionsToLocalforage()
    },[permissions])

    return (
        <>
            <Paper className="fullwidth-table-wrapper reset-margin-top-table">
                <div className="table-button-wrap">
                    <IconButton aria-label="reload table" className={classes.margin} size="small" onClick={handleReloadDataTable}>
                        <ReplayOutlined />
                    </IconButton>
                </div>
                <table id="role-permission-table" className="mdl-data-table"></table> 
            </Paper>
            <div className={classes.styledSubmitBtnWrap}>
                <StyledSubmitBtn btnColor="primary" variant="contained" btnText={t('table.confirmBtn')} startIcon={<SendOutlined />} onClick={handleUpdateRolePermission} />
            </div>
        </>
    )
}

export default RoleManagementTable;