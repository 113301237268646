import React, { useEffect, useState } from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import SettingOption from "../components/homes/SettingOption";
import ServiceOption from "../components/homes/ServiceOption";
import { useTranslation } from "../langs/useTranslation";
import useStyles from "../styles/homeStyle";
import accountCenterIcon from "../assets/images/account-center.svg";
import devicesGroupsIcon from "../assets/images/devices-groups.svg";
import subscribeServicesIcon from "../assets/images/subscribe-service.svg";
import accessManagementIcon from "../assets/images/access-management.svg";
import goodviewIcon from "../assets/images/goodview.svg";
import nubisIcon from "../assets/images/nubis.svg";
import vergoIcon from "../assets/images/vergo.svg";
import visioIcon from "../assets/images/visio.svg";
import updateCenterIcon from "../assets/images/package-center.png"
import viscoveryIcon from "../assets/images/viscovery.svg";
import * as localforage from "localforage";
import { getCountriesAPI } from "../apis/getCountriesAPI";
import { getRegionsAPI } from "../apis/getRegionsAPI";
import {
  getNubisLink,
  getVisioLink,
  getVergoLink,
} from "../helpers/getServicesLink";
import { getGoodviewLink } from "../helpers/getGoodviewLink";
import LoadingIndicator from "../components/commons/LoadingIndicator";
import HasPermission from "../components/commons/HasPermission";

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation("home");
  const [userName, setUserName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // to test service icons have correctly shown up, just change the variable value to the desired DNS name.
  const dnsName = window.location.origin;
  const getUsername = async () => {
    const storedUser = await localforage.getItem("user");
    if (storedUser) {
      setUserName(storedUser.name);
    }
  };

  const handleGetNubisLink = async () => {
    setIsLoading(true);
    const result = await getNubisLink();
    if (result) {
      setIsLoading(!!result);
    }
  };

  const handleViscovery = () => {
    window.open("https://vcms.viscovery.com/web/login");
  };

  const getUpdateCenterLink = () => {
    window.open(process.env.REACT_APP_UPDATE_CENTER_URL);
  }

  const handleGetGoodviewLink = () => {
    getGoodviewLink();
  };

  const handleGetVisioLink = async () => {
    setIsLoading(true);
    const result = await getVisioLink();
    if (result) {
      setIsLoading(!!result);
    }
  };

  const handleGetVergoLink = async () => {
    setIsLoading(true);
    const result = await getVergoLink();
    if (result) {
      setIsLoading(!!result);
    }
  };

  const storedRegionsAndCountriesToLocal = async () => {
    const token = await localforage.getItem("token");
    getRegionsAPI(token);
    getCountriesAPI(token);
  };

  useEffect(() => {
    storedRegionsAndCountriesToLocal();
    getUsername();
  }, []);

  return isLoading ? (
    <div className={classes.wholePageLoading}>
      <LoadingIndicator />
    </div>
  ) : (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.welcomeText}>
          <Typography variant="h3" align="center">
            {t("welcome.text")}
            {userName}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t("welcome.description")}
          </Typography>
        </div>
        <Grid container spacing={3} className={classes.optionsWrap}>
          <HasPermission permission="Account Center">
            <SettingOption
              linkPath="/account-center"
              btnTitle={t("settingOptionText.ac_title")}
              btnText={t("settingOptionText.ac_description")}
              imgSrc={accountCenterIcon}
              imgAlt={t("settingOptionText.ac_imgAlt")}
            />
          </HasPermission>
          <HasPermission permission="Devices & Groups">
            <SettingOption
              linkPath="/devices-groups"
              btnTitle={t("settingOptionText.dg_title")}
              btnText={t("settingOptionText.dg_description")}
              imgSrc={devicesGroupsIcon}
              imgAlt={t("settingOptionText.dg_imgAlt")}
            />
          </HasPermission>
          <HasPermission permission="Subscribe Services">
            <SettingOption
              linkPath="/subscriptions"
              btnTitle={t("settingOptionText.ss_title")}
              btnText={t("settingOptionText.ss_description")}
              imgSrc={subscribeServicesIcon}
              imgAlt={t("settingOptionText.ss_imgAlt")}
            />
          </HasPermission>
          <HasPermission permission="Access Management">
            <SettingOption
              linkPath="/access-management"
              btnTitle={t("settingOptionText.am_title")}
              btnText={t("settingOptionText.am_description")}
              imgSrc={accessManagementIcon}
              imgAlt={t("settingOptionText.am_imgAlt")}
            />
          </HasPermission>
        </Grid>
        <Typography variant="h5" align="center">
          {t("serviceOptionText.section_title")}
        </Typography>
        <Grid container spacing={3} className={classes.optionsWrap}>
          {/* Nubis */}
          <ServiceOption
            linkPath="/"
            btnTitle="Nubis"
            imgSrc={nubisIcon}
            clickBtn={
              () => { 
                window.location.replace(`${process.env.REACT_APP_NUBIS_URL}`)
              }
            }
            imgAlt={t("serviceOptionText.nb_imgAlt")}
          />

          {/* Visio */}
          {dnsName === "https://fec-platform.com.cn" ? (
            <ServiceOption
              linkPath="/"
              btnTitle="Visio"
              className="disabledBtn"
              imgSrc={visioIcon}
              imgAlt={t("serviceOptionText.vg_imgAlt")}
            />
          ) : (
            <ServiceOption
              linkPath="/"
              btnTitle="Visio"
              imgSrc={visioIcon}
              clickBtn={handleGetVisioLink}
              imgAlt={t("serviceOptionText.vg_imgAlt")}
            />
          )}

          {/* Vergo */}
          {/* <ServiceOption
            linkPath="/"
            btnTitle="Vergo"
            className="disabledBtn"
            imgSrc={vergoIcon}
            imgAlt={t("serviceOptionText.vs_imgAlt")}
          />  */}
          {
            dnsName !== "https://fec-platform.fecpos.com" &&
            (dnsName === "https://fec-platform.com.cn" ?
              (
                <ServiceOption
                  linkPath="/"
                  btnTitle="Vergo"
                  className="disabledBtn"
                  imgSrc={vergoIcon}
                  imgAlt={t("serviceOptionText.vs_imgAlt")}
                />
              ) : (
                <ServiceOption
                  linkPath="/"
                  btnTitle="Vergo"
                  imgSrc={vergoIcon}
                  clickBtn={handleGetVergoLink}
                  imgAlt={t("serviceOptionText.vg_imgAlt")}
                />
              ))}

          {/* Viscovery */}
          {dnsName !== "https://fec-platform.com.cn" && (
            <ServiceOption
              linkPath="/"
              btnTitle="Viscovery"
              className=""
              imgSrc={viscoveryIcon}
              clickBtn={handleViscovery}
              imgAlt={t("serviceOptionText.vi_imgAlt")}
            />
          )}

          {/* Goodview */}
          {dnsName !== "https://fec-platform.fecpos.com" && (
            <ServiceOption
              linkPath="/"
              btnTitle="Goodview"
              className=""
              clickBtn={handleGetGoodviewLink}
              imgSrc={goodviewIcon}
              imgAlt={t("serviceOptionText.gv_imgAlt")}
            />
          )}

          {/* FEC Update Center */}
          <ServiceOption
            linkPath="/"
            btnTitle="FEC Update Center"
            imgSrc={updateCenterIcon}
            clickBtn={getUpdateCenterLink}
            imgAlt={t("serviceOptionText.pc_imgAlt")}
          />

        </Grid>
      </Container>
    </div>
  );
};

export default Home;
