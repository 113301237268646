import * as localforage from 'localforage';
import * as $ from 'jquery';
import { url } from '../apis/apiSetup';
import { searchStyleRender } from './datatableSearchIconRender';
import { language } from './datatableSetting';
import 'datatables.net';
import '../css/dataTable.css';

export const table = async (t, subscriptionId) => {

    const token = await localforage.getItem('token');
    $.fn.dataTable.ext.errMode = 'throw'; 

    $('#device-table').DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        filter: true,
        deferRender: true,
        info: true,
        paging: true,
        pageLength:10,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        order: [[ 0, 'desc' ]],
        ajax: {
            url: `${url}/api/v1/subscribe/device-list-datatable`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                subscribe_value_added_service_id: subscriptionId
            },
            beforeSend: request => {
                request.setRequestHeader('Authorization', 'Bearer ' + token );
                request.setRequestHeader('Accept', 'application/json');
            },
        },
        columns: [
            {   data: 'device_sn', 
                name: 'device_sn',
                searchable: true,
                orderable: true,
                className: 'serial_number',      
                title: `${t('table.deviceS/N')}`,
                render: data => {
                    return data;
                }, 
            },
        ]
    })
    // render Material UI Search Input
    searchStyleRender();
}
