import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Link, Drawer, Divider, Typography } from '@material-ui/core';
import { HomeOutlined} from '@material-ui/icons';
import BacktoHomeBtn from '../components/commons/BacktoHomeBtn'
import useStyles from '../styles/accessManagementStyle.js';
import { useTranslation } from "../langs/useTranslation";
import RolePermissionTable from '../components/accessManagement/RolePermissionTable'
import BacktoPrevPage from '../components/commons/BacktoPrevPage';
import { useParams } from 'react-router-dom';
import { editRoleWithPermissionsAPI } from '../apis/editRoleWithPermissionsAPI';

const PermissionManagement = () => {
    const classes = useStyles();
    const { role_id } = useParams();
    const { t } = useTranslation('accessManagement');
    const [ role, setRole ] = useState('');

    const drawer = (
        <div>
          <div className={classes.toolbar} />
            <BacktoHomeBtn />
            <Divider />
        </div>
    );

    const editRoleWithPermission = async() => {
        const responseStatus = await editRoleWithPermissionsAPI(role_id);
        if(responseStatus){
            setRole(responseStatus.role.name)
        }
    }

    useEffect(()=>{
        editRoleWithPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </nav>
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href='/'>
                        <HomeOutlined />
                    </Link>
                    <Typography color="textPrimary">{t('accessManagement.title')}</Typography>
                </Breadcrumbs>
                <BacktoPrevPage linkTo="/access-management" btnText={t('accessManagement.title')} />
                <div className={classes.tableSection}>
                    <Typography variant="h6">{role}</Typography>
                    <RolePermissionTable />
                </div>
            </main>
        </div>
    )
}

export default PermissionManagement;