import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& a": {
      textDecoration: "none",
      marginTop: 30
    },
    "& .MuiDivider-root": {
      width: "100% !important",
      marginBottom: "0"
    },
    "& .MuiDivider-root.dates-connector": {
      width: "20px !important"
    },
    "& .MuiFormControlLabel-root": {
      overflow: "hidden !important"
    },
    "& .MuiFormControl-root": {
      width: "100% !important"
    },
    "& .fullwidth-table-wrapper": {
      padding: "16px 0 !important"
    },
    "& .MuiButton-textSecondary": {
      color: "rgba(0, 0, 0, 0.65) !important"
    },
    "& .MuiSvgIcon-colorPrimary": {
      color: "#2c3ff3 !important"
    },
    "& .btn-no-permission": {
      display: "none !important"
    },
    "& .MuiIconButton-sizeSmall": {
      padding: "2px !important"
    },
    "& .MuiFormControl-root.mulitple-form-control": {
      width: "180px !important"
    },
    "& .MuiTableCell-head": {
      color: "#ccc"
    },
    "& .primary-shadow": {
      boxShadow: "0 0px 10px 3px #e5e7f9"
    }
  },
  flex: {
    display: "flex !important"
  },
  drawerPaper: {
    width: drawerWidth,
    position: "fixed",
    top: theme.spacing(8),
    zIndex: "1"
  },
  content: {
    flexGrow: 1,
    boxSizing: "border-box",
    padding: theme.spacing(8),
    marginTop: theme.spacing(5),
    width: `calc( 100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  titleSection: {
    marginBottom: "24px !important",
    marginTop: "32px !important",
    "& h4": {
      marginBottom: "16px !important"
    }
  },
  iconButtonGroup: {
    position: "absolute",
    top: "20px",
    left: "20px",
    width: "calc( 100% - 20px )",
    zIndex: "9",
    "& button": {
      marginRight: "5px !important",
      zIndex: 20
    },
    "&>div, &>p": {
      marginRight: 8
    },
    "& .filter-button": {
      border: "1px solid #ddd",
      background: "#ddd",
      borderRadius: 4,
      width: 34,
      height: 34,
      transition: ".5s all"
    },
    "& .filter-button:hover": {
      background: "#ccc"
    }
  },
  iconButtonGroupWthTitle: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(3),
    "& .iconButtonGroup": {
      "&>div, &>p": {
        marginRight: 8
      },
      "& .filter-button": {
        border: "1px solid #ddd",
        background: "#ddd",
        borderRadius: 4,
        width: 34,
        height: 34,
        transition: ".5s all"
      },
      "& .filter-button:hover": {
        background: "#ccc"
      },
      "& .custom-search": {
        width: 200,
        marginRight: "0 !important",
        marginLeft: 20,
        top: 0,
        right: 0,
        position: "relative !important"
      }
    }
  },
  serviceSeletion: {
    "& label.MuiFormControlLabel-root": {
      display: "flex",
      alignItems: "baseline",
      position: "relative",
      "& .MuiPaper-rounded": {
        padding: "0 !important",
        marginTop: "10px",
        borderRadius: "20px",
        position: "relative",
        height: "90px",
        width: "90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      "& img": {
        width: "60%"
      },
      "& .MuiFormControlLabel-label": {
        position: "relative",
        top: -7
      }
    }
  },
  capacityFieldTitle: {
    position: "relative",
    top: -7,
    marginRight: 10
  },
  capacityField: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& label.MuiFormControlLabel-root": {
      display: "flex",
      alignItems: "baseline",
      "& .MuiFormControlLabel-label": {
        position: "relative",
        top: -7
      },
      "& .MuiTypography-caption": {
        color: "#ccc"
      },
      "& .MuiTypography-colorError": {
        marginTop: 8,
        color: "#f44336 !important"
      }
    }
  },
  divider: {
    marginBottom: "20px !important"
  },
  subscriptionDetailDialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "50%",
      maxWidth: "450px !important",
      minWidth: "300px !important"
    },
    "& .MuiDivider-root": {
      margin: "16px 0 !important"
    },
    "& .MuiTypography-caption": {
      color: "#999"
    },
    "& .bold": {
      fontWeight: "bold !important",
      padding: "0 6px"
    }
  },
  avatar: {
    fontSize: "0.8rem",
    width: "30px !important",
    height: "30px !important"
  },
  serviceListItem: {
    "& img.service-logo": {
      padding: "0 5% !important",
      width: "90%",
      fontSize: "0.8em",
      wordBreak: "break-all"
    },
    "& .MuiDivider-flexItem": {
      width: "1px !important",
      height: "20px !important",
      alignSelf: "auto"
    }
  },
  createServiceDialog: {
    "& .MuiDialog-paperFullWidth": {
      padding: "16px !important"
    },
    "& .avatar-editor": {
      width: "200px",
      "& input": {
        width: "130px",
        height: "130px",
        "&::before": {
          width: "100%",
          height: "100%"
        }
      },
      "& .avatar-image": {
        width: "130px",
        height: "130px"
      }
    }
  },
  serviceNameField: {
    width: "300px"
  },
  osSelect: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      fontSize: "1rem !important",
      color: "#666 !important"
    }
  },
  hiddenInput: {
    position: "absolute",
    width: "100%",
    height: "100%",
    cursor: "pointer"
  }
}));

export default useStyles;
