export const flatMultiDimensional = (array) => {
  let result = [];
  if (array) {
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flatMultiDimensional(a.children));
      }
      
      delete a.children;
    });
  }
  
  return result;  
}