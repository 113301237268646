import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { getCompanyAPI } from './getCompanyAPI';

export const createAccountAPI = async(token, company_id, values, activeStatus) => {

    let formData = new FormData();
    formData.append('company_id', company_id);
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('password', values.password);
    formData.append('active_status', activeStatus === true ?  1 : 0);
    formData.append('roles[]', values.role);
    
    if (values.file) {
        formData.append('avatar', values.file[0]);
    }
    
    try {
        await axios({
            url: `${url}/api/v1/account/create`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: formData,
        })
        
        //update company and account in the localforage
        await getCompanyAPI(company_id);
        return true;
        
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}