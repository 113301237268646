import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { useHistory } from 'react-router-dom';

export const authLogin = async ticket => {
  // const email = user.email;
  // const password = user.password;
  //const history = useHistory();

  try {
    let response = await axios({
      withCredentials: true,
      url: `${url}/api/v1/member/login`,
      method: "GET",
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        'Accept': "application/json",
      },
      params: {
        ticket
      }
    });

    let loginData = response.data;

    if (loginData) {
      await localforage.setItem("token", loginData.token);
      await localforage.setItem("expiredAt", loginData.expires_at); // expired date time: UTC
      await localforage.setItem("loginData", loginData);
      await localforage.setItem("user", loginData.user);
      await localforage.setItem("userCompanyID", loginData.user.company_id);
      await localforage.setItem(
        "userRole",
        loginData.user.roles.length > 0 ? loginData.user.roles[0].name : ""
      );
      await localforage.setItem(
        `${loginData.user.company_id}`,
        loginData.user.company
      );
      await localforage.setItem(
        "userCompanyImgLink",
        loginData.user.company.company_image_link
          ? loginData.user.company.company_image_link
          : ""
      );
      await localforage.setItem("userCompanyName", loginData.user.company.name);
      await localforage.setItem(
        "userCompanyContactPerson",
        loginData.user.company.contact_person
      );
      await localforage.setItem(
        "userCompanyEmail",
        loginData.user.company.email
      );
      await localforage.setItem(
        "visioPayload",
        loginData.visio_login_url.split("payload=")[1]
      );
      await localforage.setItem(
        "vergoPayload",
        loginData.vergo_login_url.split("payload=")[1]
      );

      //store user permissions name array
      const userPermissionsFromResponse = loginData.user.roles[0].permissions;
      if (userPermissionsFromResponse) {
        const userPermissionsNameArray = userPermissionsFromResponse.map(
          userPermissionFromResponse => {
            return userPermissionFromResponse.name;
          }
        );
        await localforage.setItem("userPermissions", userPermissionsNameArray);
      }

      return true;
    } else {
      //Request is Completed, but don't get loginData.
      console.log("Failed to get loginData")
      return false;
    }
  } catch (error) {
    errorHandling(error, false);
    return false;
  }
};
