import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root:{
        position: 'relative',
    },
    formControlBtn: {
        display: 'flex !important',
        flexDirection: 'row',
        '& .MuiButton-textPrimary': {
          height: '40px !important',
          width: 'auto !important',
          marginRight: '10px !important',
          background: '#2c3ff3 !important',
          color: '#fff !important',
          '&:hover': {
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important'
          }
        },
        '& .MuiButton-containedSecondary': {
          marginTop: '0 !important',
          '&:hover': {
            color: '#fff !important'
          }
        }
    },  
    btn: {
        width: 300,
        lineHeight: '3em',
        '&:hover': {
            background: '#4153fb',
            color: '#fff',
        },
        background: '#2c3ff3',
        '&.MuiButton-root.Mui-disabled': {
            background: '#ddd !important',
        }
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        zIndex: '99 !important'
    }
})

export default useStyles;