import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  IconButton,
} from "@material-ui/core";
import useStyles from "../../styles/subscribeServicesStyle";
import { AttachFileOutlined, AddBoxRounded } from "@material-ui/icons";
import { CaptionText, SimpleErrorText } from "../commons/StyledTexts";

const OsSelect = (props) => {
  const { value, onChange, disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation("subscribeServices");

  return (
    <FormControl fullWidth className={classes.osSelect}>
      <InputLabel id="os-select-label">{t("serviceManagement.os")}</InputLabel>
      <Select
        labelId="os-select-label"
        id="os-select"
        value={value}
        label={t("serviceManagement.os")}
        onChange={onChange}
        variant="outlined"
        disabled={disabled}
        MenuProps={{ disableScrollLock: true }}
      >
        <MenuItem value="android">Android</MenuItem>
        <MenuItem value="windows">Windows</MenuItem>
      </Select>
    </FormControl>
  );
};

const FileForSupportOs = (props) => {
  const { file, onChange, uploadProgress, disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation("subscribeServices");

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Box position="relative" mr={2}>
          <Button
            size="small"
            variant="contained"
            disabled={disabled}
            startIcon={<AttachFileOutlined color="primary" />}
          >
            <input
              type="file"
              className={classes.hiddenInput}
              onChange={onChange}
            />
            <Typography color="primary">
              {t("serviceManagement.fileUpload")}
            </Typography>
          </Button>
        </Box>
        <Typography variant="caption" color="secondary" className="mr-1">
          {t("serviceManagement.uploadedFile")}:
        </Typography>
        <Typography variant="caption" color="textPrimary">
          {file ? file.name : "-"}
        </Typography>
      </Box>
      <LinearProgress value={uploadProgress} variant="determinate" />
    </>
  );
};

export const SupportOsAddButtonBar = (props) => {
  const { t } = useTranslation("subscribeServices");
  const { onClick } = props;
  return (
    <>
      <Box width="140px">
        <IconButton onClick={onClick} color="secondary">
          <AddBoxRounded color="secondary" />
        </IconButton>
        <CaptionText text={t("serviceManagement.supportOS")} />
      </Box>
      <Box width="100%">
        <Divider className="full-width" />
      </Box>
    </>
  );
};

const SupportOsAndFile = (props) => {
  const { t } = useTranslation("subscribeServices");
  const { o, i, onChange, ifLastOne, uploadProgress, disabled, error } = props;

  return (
    <Box mb={!ifLastOne ? 2 : 0}>
      <Box key={i} display="flex" flexDirection="row">
        <Box width="300px" mr={2}>
          <OsSelect
            value={o.os}
            onChange={(e) => onChange(e, i, "os")}
            disabled={disabled}
          />
        </Box>
        <Box className="flex flex-direction-column space-between full-width">
          <FileForSupportOs
            onChange={(e) => onChange(e, i, "file")}
            file={o.file}
            uploadProgress={uploadProgress}
            disabled={disabled}
          />
        </Box>
      </Box>
      {error && (
        <SimpleErrorText
          text={t("serviceManagement.osAndFileAreAllRequired")}
        />
      )}
    </Box>
  );
};

export default SupportOsAndFile;
