import React from 'react';
import { Breadcrumbs, Link, Drawer, Divider, Typography } from '@material-ui/core';
import { HomeOutlined} from '@material-ui/icons';
import CompanyTree from '../components/accountCenters/CompanyTreeSearch';
import BacktoHomeBtn from '../components/commons/BacktoHomeBtn'
import useStyles from '../styles/accountCenterStyle.js';
import { useTranslation } from "../langs/useTranslation";
import ProfileCard from '../components/accountCenters/ProfileCard';

const AccountCenter = () => {
    const classes = useStyles();
    const { t } = useTranslation('accountCenter');

    const drawer = (
        <div>
          <div className={classes.toolbar} />
            <BacktoHomeBtn />
            <Divider />
            <ProfileCard />
            <Divider />
        </div>
    );

    return(
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </nav>
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href='/'>
                        <HomeOutlined />
                    </Link>
                    <Typography color="textPrimary">{t('accountCenter.title')}</Typography>
                </Breadcrumbs>
                <div className={classes.titleSection}>
                    <Typography variant="h4">{t('accountCenter.title')}</Typography>
                    <Typography>{t('accountCenter.description')}</Typography>
                </div>
                <Typography variant="h5" className={classes.searchTitle}>{t('accountCenter.searchBarTitle')}</Typography>
                <CompanyTree />
            </main>
        </div>
    )
}

export default AccountCenter;