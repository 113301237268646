export const handleItemsSelected = (e, menuObjArray, chipObjArray, setGroups, setSelectedGroups, setSelectedGroupIds) => {
    
    let itemId = e.target.id;
    let itemCheckedStatus = e.target.checked;
    
    // update menu list with checkbox status
    let changedItemIndex = menuObjArray.map( o => o.id).indexOf(itemId);
    let changedItem = { ...menuObjArray[changedItemIndex], checked: itemCheckedStatus };

    const copiedItemsArr = menuObjArray;
    menuObjArray.splice(changedItemIndex, 1, changedItem);
    setGroups([...copiedItemsArr]);

    // update chip list
    // eslint-disable-next-line no-new-object
    let chipItem = new Object(changedItem);
    
    let chipItemsIndex = chipObjArray.map(o => o.id).indexOf(itemId);
    chipItemsIndex > -1 ? chipObjArray.splice(chipItemsIndex, 1) : chipObjArray.push(chipItem);
    
    let selectedItemIds = chipObjArray.map(o => o.id);
    setSelectedGroups(chipObjArray);
    setSelectedGroupIds(selectedItemIds);

}
