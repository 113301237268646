import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const assignDevicesAPI = async (token, deviceIds, companyGroupIds, subCompanyIds) => {
    try {
        await axios({
            url: `${url}/api/v1/devices/assign`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token
            },
            data: {
                device_ids: deviceIds,
                company_group_ids: companyGroupIds,
                sub_company_ids: subCompanyIds
            }
        });
        return true;
    } catch (error) {
        errorHandling(error, true);
    }
}