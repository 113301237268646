import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    root: {
        background: "#fdfdfd",
        position: 'relative',
        minHeight: '100vh'
    },
    footer: {
        position: 'absolute',
        textAlign: 'right',
        // borderTop: '1px solid #ddd',
        paddingRight: theme.spacing(3),
        lineHeight: 4,
        fontSize: 12,
        color: '#999',
        width: '100%',
        bottom: 0
      }
}))

export default useStyles;