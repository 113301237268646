import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";

const TypeRadioButtons = props => {
  const { value, onChange, className, disabled } = props;
  const { t } = useTranslation("subscribeServices");

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={className}
    >
      <FormLabel className="mr-2" required>
        {t("serviceManagement.type")}
      </FormLabel>
      <RadioGroup
        className="flex align-items-center flex-direction-row"
        aria-label={t("serviceManagement.type")}
        name={t("serviceManagement.type")}
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          value="company"
          control={<Radio color="primary" disabled={disabled} />}
          label={t("serviceManagement.company")}
        />
        <FormControlLabel
          value="device"
          control={<Radio color="primary" disabled={disabled} />}
          label={t("serviceManagement.device")}
        />
      </RadioGroup>
    </Box>
  );
};

export default TypeRadioButtons;
