import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';

export const getRolesForServicesAPI = async() => {
    const token = await localforage.getItem('token');
    try {
        let response = await axios({
            url: `${url}/api/v1/roles-for-services-redirecting`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        return response.data.roles;
    } catch (error) {
        return false;
    }
}