import React, { useState, useEffect } from 'react';
import { useTranslation } from "../../langs/useTranslation";
import { Dialog, Typography, Avatar, Box } from '@material-ui/core';
import useStyles from '../../styles/subscribeServicesStyle';

const UserDialog = props => {
    const classes = useStyles();
    const { onClose, open, data } = props;
    const { t } = useTranslation('subscribeServices');
    const [ array, setArray ] = useState([]);

    useEffect(()=>{
      if(open) setArray(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])
  
    return (
      <Dialog onClose={onClose} open={open}>
        <Box mb={3}>
            <Typography variant="h6">
                {t('dialog.users')}
            </Typography>
        </Box>
        {array.map((o, i) => (
            <Box display="flex" alignItems="center" key={i} mb={1}>  
                <Box mr={1}>
                    <Avatar alt={o} className={classes.avatar}>{o.charAt(0)}</Avatar>
                </Box>
                <Typography variant="body2" color="textSecondary">
                    {o}
                </Typography>
            </Box>
        ))}
      </Dialog>
    );
  }
  
export default UserDialog;