import localforage from "localforage";

export const getNubisLink = async () => {
  const userData = await localforage.getItem("user");
  const userCompanyID = await localforage.getItem("userCompanyID");

  if (userData) {
    const url = new URL(`${process.env.REACT_APP_NUBIS_URL}/?`);
    const params = url.searchParams;
    params.append("id", userData.id);
    params.append("company_id", userCompanyID);
    params.append("email", userData.email);
    const encodedNubisLinkRequiredData = window.btoa(url.search.slice(1));
    window.location.replace(
      `${process.env.REACT_APP_NUBIS_URL}/?${encodedNubisLinkRequiredData}`
    );
  } else {
    return false;
  }
};

export const getVisioLink = async () => {
  const payload = await localforage.getItem("visioPayload");
  window.location.replace(
    `${process.env.REACT_APP_VISIO_URL}?payload=${payload}`
  );
};

export const getVergoLink = async () => {
  const payload = await localforage.getItem("vergoPayload");
  window.location.replace(
    `${process.env.REACT_APP_VERGO_URL}?payload=${payload}`
  );
};
