import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  FormControlLabel,
  Radio,
  FormControl,
  Typography
} from "@material-ui/core";
import useStyles from "../../styles/subscribeServicesStyle";
import MultipleSelector from "./MultipleSelector";
import SearchFieldAndButton from "../commons/SearchFieldAndButton";
import { useTranslation } from "../../langs/useTranslation";
import { table } from "../../helpers/addNewSubscriptionDeviceDatatable";
import {
  ReloadTableIconButton,
  FilterTableIconButton
} from "../commons/IconButtons";
import { getCompanyGroupsAPI } from "../../apis/getCompanyGroupsAPI";
import { setUpArrayWithCheckStatus } from "../../helpers/setUpArrayWithCheckStatus";
import { MissingSelectedErrorText } from "../commons/StyledTexts";

const DeviceTable = props => {
  const classes = useStyles();
  const { t } = useTranslation("subscribeServices");
  const {
    originalServiceObjArr,
    selectedCompany,
    resetSearchField,
    setResetSearchField,
    selectWay,
    deviceIds,
    isFormSubmitting,
    serviceId,
    startedAt,
    endedAt,
    isRenewal,
    subscriptionId,
    deviceTable
  } = props;
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);
  const [ifShowTable, setIfShowTable] = useState(false);

  const setUpServices = async () => {
    setSelectedServices([]); // reset after selected company changed
    setSelectedServiceIds([]); // reset after selected company changed

    if (selectedCompany) {
      const serviceNamesAndIds = originalServiceObjArr
        .filter(o => o.id !== serviceId && o.is_for_device && o.is_enable)
        .map(o => {
          const name = `${t(`service.${o.name}`)}`;
          return { name: name, id: o.id };
        });
      setUpArrayWithCheckStatus(serviceNamesAndIds, setServices);
    }
  };

  const setUpGroups = async () => {
    setSelectedGroups([]); // reset after selected company changed
    setSelectedGroupIds([]); // reset after selected company changed

    if (selectedCompany) {
      const companyGroupsArr = await getCompanyGroupsAPI(selectedCompany.value);
      if (companyGroupsArr)
        setUpArrayWithCheckStatus(companyGroupsArr, setGroups, "group");
    }
  };

  const reloadTable = (e, hasFilter = false) => {
    let companyId = selectedCompany.value;

    if ((!e.detail || e.detail === 1) && companyId) {
      if (!hasFilter) {
        table(t, serviceId, companyId);
        setGroups(groups.map((o, i) => ({ ...groups[i], checked: false })));
        setServices(
          services.map((o, i) => ({ ...services[i], checked: false }))
        );
        setSelectedGroups([]);
        setSelectedGroupIds([]);
        setSelectedServices([]);
        setSelectedServiceIds([]);
      } else {
        table(
          t,
          serviceId,
          companyId,
          selectedGroupIds,
          selectedServiceIds,
          startedAt,
          endedAt,
          isRenewal,
          subscriptionId
        );
      }
    }
    setResetSearchField(true);
  };

  useEffect(() => {
    setUpServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalServiceObjArr]);

  useEffect(() => {
    setIfShowTable(!!selectedCompany);
    setUpGroups();
    setUpServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      table(
        t,
        serviceId,
        selectedCompany.value,
        selectedGroupIds,
        selectedServiceIds,
        startedAt,
        endedAt,
        isRenewal,
        subscriptionId
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, startedAt, endedAt, isRenewal]);

  return (
    <>
      <FormControlLabel
        value="select"
        control={<Radio color="primary" />}
        label={t("subscribeNewService.byDeviceList")}
      />
      {isFormSubmitting && selectWay === "select" && !deviceIds && (
        <Box mb={1}>
          <MissingSelectedErrorText text="selectedTargetRequired" />
        </Box>
      )}
      <Paper className="fullwidth-table-wrapper">
        <Box m={2} display={ifShowTable ? "none" : "block"}>
          <Typography variant="caption" color="textSecondary">
            {t("subscribeNewService.selectedCompanyRequired")}
          </Typography>
        </Box>
        <Box display={ifShowTable ? "block" : "none"}>
          <Box
            className={classes.iconButtonGroup}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <ReloadTableIconButton onClick={e => reloadTable(e, false)} />
            <FormControl className="mulitple-form-control">
              <MultipleSelector
                height={19}
                defaultText={t("subscriptions.groups")}
                noDataText={t("subscribeNewService.noGroups")}
                hasNoData={selectedCompany && groups.length < 1}
                groups={groups}
                setGroups={setGroups}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                setSelectedGroupIds={setSelectedGroupIds}
              />
            </FormControl>
            <FormControl className="mulitple-form-control">
              <MultipleSelector
                height={19}
                defaultText={t("subscriptions.services")}
                noDataText={t("subscribeNewService.noServices")}
                hasNoData={selectedCompany && services.length < 1}
                groups={services}
                setGroups={setServices}
                selectedGroups={selectedServices}
                setSelectedGroups={setSelectedServices}
                setSelectedGroupIds={setSelectedServiceIds}
              />
            </FormControl>
            <FilterTableIconButton onClick={e => reloadTable(e, true)} />
            <SearchFieldAndButton
              resetSearchField={resetSearchField}
              setResetSearchField={setResetSearchField}
              table={deviceTable}
            />
          </Box>
          <table id="subscribe-device-table" className="mdl-data-table">
            <thead>
              <tr>
                <th></th>
                <th>{t("table.deviceS/N")}</th>
                <th>{t("table.groups")}</th>
                <th>{t("table.subscriptions")}</th>
              </tr>
            </thead>
          </table>
        </Box>
      </Paper>
    </>
  );
};

export default DeviceTable;
