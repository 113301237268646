export let styledSubmitBtnState = { loading: false, success: false };

export const styledSubmitBtnReducer = (state, action) => {
  switch (action.type) {
    case "showLoading":
      styledSubmitBtnState.loading = true;
      styledSubmitBtnState.success = false;
      return { ...state };
    case "stopLoading":
      styledSubmitBtnState.loading = false;
      styledSubmitBtnState.success = false;
      return { ...state };
    default:
      break;
  }
};
