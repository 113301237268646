import React, { useContext } from 'react';
import clsx from 'clsx';
import { SubmitBtnContext } from '../../App';
import { Button } from '@material-ui/core';
import useStyles from '../../styles/submitBtnStyle';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledSubmitBtn = props => {
    const classes = useStyles();
    const { loading, success } = useContext(SubmitBtnContext);
    const { variant, btnColor, linkpath, startIcon, onClick, btnText } = props;

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    return(
        <div className={classes.root}>
            <Button 
                variant={variant}
                type="submit" 
                color={btnColor} 
                className={`${classes.btn} ${buttonClassname}`} 
                href={linkpath} 
                disabled={loading}
                startIcon={startIcon}
                onClick={onClick}
            >
                {btnText}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}


export default StyledSubmitBtn;