import React, { useState } from 'react';
import { Paper, IconButton, FormControlLabel, Radio } from '@material-ui/core';
import { GetAppOutlined, ReplayOutlined, PlaylistAddOutlined } from '@material-ui/icons';
import ImportDevicesDialog from './ImportDevicesDialog';
import HasPermission from '../commons/HasPermission';
import useStyles from '../../styles/delegationStyle';
import { useTranslation } from "../../langs/useTranslation";
import { table } from '../../helpers/delegationDatatable';
import SearchFieldAndButton from '../commons/SearchFieldAndButton';
import * as $ from 'jquery';

const DelegationTable = props => {
    const classes = useStyles();
    const { t } = useTranslation('devicesGroups');
    const { reloadTableBtnOnclick, resetSearchField, setResetSearchField } = props;
    const [ importDialogOpen, setImportDialogOpen ] = useState(false);

    const handleImportDialogClickOpen = () => setImportDialogOpen(true);

    const searchDatatable = searchValue => $('#device-list-table').DataTable().search(searchValue).draw();

    const handleImportDialogClose = value => {
        setImportDialogOpen(false);
        if(value === true) table(t);
    };

    return (
        <>
        <FormControlLabel value="select" control={<Radio color="primary" />} label={t('setting.selectDevices')} />
            <Paper className="fullwidth-table-wrapper">
                <div className={classes.iconButtonGroup}>
                    <IconButton aria-label="reload table" className={classes.margin} size="small" onClick={reloadTableBtnOnclick}>
                        <ReplayOutlined />
                    </IconButton>
                    <HasPermission permission="device_and_groups_export">
                        <IconButton aria-label="export device S/N" className={classes.margin} size="small">
                            <GetAppOutlined />
                        </IconButton>
                    </HasPermission>
                    <HasPermission permission="device_and_groups_import">
                        <IconButton aria-label="import device S/N" className={classes.margin} size="small" onClick={handleImportDialogClickOpen}>
                            <PlaylistAddOutlined />
                        </IconButton>
                    </HasPermission>
                    <SearchFieldAndButton 
                        resetSearchField={resetSearchField} 
                        setResetSearchField={setResetSearchField} 
                        searchDatatableForTable={searchDatatable} // sending this rather than 'table' since it won't search after clicking the groups/companies selection
                    />
                </div>
                <table id="device-list-table" className="mdl-data-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('table.deviceSN')}</th>
                            <th>{t('table.groups')}</th>
                            <th>{t('table.owners')}</th>
                            <th>{t('table.status')}</th>
                            <th>{t('table.nubisVersion')}</th>
                            <th>{t('table.registeredAt')}</th>
                            <th>{t('table.activatedAt')}</th>
                        </tr>
                    </thead>
                </table>     
            </Paper>
            <ImportDevicesDialog open={importDialogOpen} onClose={handleImportDialogClose} />
        </>
    )
}

export default DelegationTable;