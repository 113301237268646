import { getNotificationListAPI } from "../apis/getNotificationListAPI";

export let getNotificationState = { get: false };

export const getNotificationReducer = (state, action) => {
  switch (action.type) {
    case "get":
      getNotificationState.get = true;
      getNotificationList();
      return { ...state };
    case "cancel":
      getNotificationState.get = false;
      return { ...state };
    default:
      break;
  }
};

export const getNotificationList = async () => {
  const notificationListObjArr = await getNotificationListAPI(15);
  if (notificationListObjArr) {
    const checkHasNewNotification = notificationListObjArr.filter(
      (notification) => notification.read_at === null
    );
    return {
      notificationListObjArr: notificationListObjArr,
      checkHasNewNotification: checkHasNewNotification,
    };
  }
};
