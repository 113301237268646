import React from 'react';
import { Breadcrumbs, Link, Drawer, Divider, Typography } from '@material-ui/core';
import { HomeOutlined} from '@material-ui/icons';
import BacktoHomeBtn from '../components/commons/BacktoHomeBtn'
import useStyles from '../styles/accessManagementStyle.js';
import { useTranslation } from "../langs/useTranslation";
import RoleManagementTable from '../components/accessManagement/RoleManagementTable'
import PermissionManagementTable from '../components/accessManagement/PermissionManagementTable'

const AccessManagement = () => {
    const classes = useStyles();
    const { t } = useTranslation('accessManagement');

    const drawer = (
        <div>
          <div className={classes.toolbar} />
            <BacktoHomeBtn />
            <Divider />
        </div>
    );

    return(
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </nav>
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href='/'>
                        <HomeOutlined />
                    </Link>
                    <Typography color="textPrimary">{t('accessManagement.title')}</Typography>
                </Breadcrumbs>
                <div className={classes.titleSection}>
                    <Typography variant="h4">{t('accessManagement.title')}</Typography>
                    <Typography variant="body1">{t('accessManagement.description')}</Typography>
                </div>
                <div className={classes.tableSection}>
                    <Typography variant="h6">{t('accessManagement.roleManagement')}</Typography>
                    <RoleManagementTable />
                </div>
                <div className={classes.tableSection}>
                    <Typography variant="h6">{t('accessManagement.permissionManagement')}</Typography>
                    <PermissionManagementTable />
                </div>
            </main>
        </div>
    )
}

export default AccessManagement;