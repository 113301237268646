import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const createRoleAPI = async(role_name, permissions) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v1/roles/create`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: {
                role_name: role_name,
                permissions: permissions,
            }
        })
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
    
}