import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const resetPasswordtoAPI = async (passwords) => {
        const oldPassword = passwords.oldPassword;
        const newPassword = passwords.newPassword;
        const loginData = await localforage.getItem('loginData');
        const token = loginData.token;
        try {
            await axios({
                url: `${url}/api/v1/member/reset-password`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                },
                data: {
                    old_password: oldPassword,
                    new_password: newPassword
                }
            });
            return true;
        } catch (error) {
            errorHandling(error, false);
            return false;
        }
}