import React from 'react'; 
import { Typography } from '@material-ui/core';
import useStyles from '../../styles/serviceOptionStyle';
import { NavLink } from 'react-router-dom';

const ServiceOption = props => {

    const classes = useStyles();
    const { linkPath, className, clickBtn, imgSrc, imgAlt, btnTitle } = props;

    return(
        <div className={classes.root}>
            <NavLink to={linkPath} className={`${classes.btn} ${className}`} onClick={clickBtn} >
                <img src={imgSrc} alt={imgAlt} />
                <Typography variant="h6">{btnTitle}</Typography>    
            </NavLink>
        </div>
    )
}


export default ServiceOption;