import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    root: {
        '& a.disabledBtn':{
            cursor: 'initial !important',
            '& img': {
                filter: 'grayscale(1)',
                opacity: 0.4,
            },
            '& .MuiTypography-h6': {
                color: '#ccc !important'
            },
            '&:hover': {
                boxShadow: '0 0 12px #ddd'
            }
        },
    },  
    btn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        boxShadow: '0 0 12px #ddd',
        padding: theme.spacing(2),
        height: '200px !important',
        transition: '0.2s',
        borderRadius: 44,
        width: '200px',
        maxWidth: '200px !important',
        margin: '10px !important',
        minHeight: 'initial !important',
        '& img': {
            height: 60,
            marginBottom: theme.spacing(2),
        },
        '&:hover': {
            boxShadow: '0 0 50px #dedede',
        },
        '& p': {
            fontSize: 12,
            color: '#aaa',
        }
    }
}))

export default useStyles;