import React from 'react';
import { Breadcrumbs, Link, Drawer, Divider, Typography } from '@material-ui/core';
import { HomeOutlined} from '@material-ui/icons';
import BacktoHomeBtn from '../components/commons/BacktoHomeBtn'
import useStyles from '../styles/accountCenterStyle.js';
import { useTranslation } from "../langs/useTranslation";
import DelegationForm from '../components/devicesGroups/DelegationForm';

const DevicesGroups = () => {
    const classes = useStyles();
    const { t } = useTranslation('devicesGroups');

    const drawer = (
        <div>
          <div className={classes.toolbar} />
            <BacktoHomeBtn />
            <Divider />
        </div>
    );

    return(
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </nav>
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href='/'>
                        <HomeOutlined />
                    </Link>
                    <Typography color="textPrimary">{t('devicesGroups.title')}</Typography>
                </Breadcrumbs>
                <div className={classes.titleSection}>
                    <Typography variant="h4">{t('devicesGroups.title')}</Typography>
                    <Typography>{t('devicesGroups.description')}</Typography>
                </div>
                <DelegationForm />
            </main>
        </div>
    )
}

export default DevicesGroups;