import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/commonStyle';

const NotificationDetailDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, currentNotiItem } = props;
    const { t } = useTranslation('common'); 
    const [ notification, setNotification ] = useState('')

    useEffect(() => {
        if(currentNotiItem && currentNotiItem.json){
            setNotification(currentNotiItem.json)
        }
    },[currentNotiItem, open])

    return(
        <Dialog onClose={onClose} aria-labelledby="notification-dialog-title" open={open}>
            <DialogTitle id="notification-dialog-title">
                {t('notification.title')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="h5" className={classes.notiDialogMainMsg} >
                    <span dangerouslySetInnerHTML = {{ __html: notification.message }} />
                </Typography>
                <Typography variant="body2" color="textPrimary" className={classes.allNotiMemos} >
                    {notification.devices ? notification.devices.join(', ') : ''}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.notiDialogActionWrap}>
                <Button color="primary" variant="contained" onClick={onClose}>{t('notification.confirmBtn')}</Button>
            </DialogActions>
        </Dialog>
       
    )
}

export default NotificationDetailDialog;