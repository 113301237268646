import accessManagement from "./accessManagement.json";
import accountCenter from "./accountCenter.json";
import common from "./common.json";
import devicesGroups from "./devicesGroups.json";
import home from "./home.json";
import login from "./login.json";
import subscribeServices from "./subscribeServices.json";

export const en = {
  accessManagement,
  accountCenter,
  common,
  devicesGroups,
  home,
  login,
  subscribeServices,
};
