import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { getCompanyAPI } from './getCompanyAPI';

export const updateAccountAPI = async(token, accountID, company_id, values, activeStatus, initAvatar, defaultImage) => {
    let formData = new FormData();
    formData.append('id', accountID);
    formData.append('company_id', company_id)
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('password', values.password);
    formData.append('active_status', activeStatus === true ? 1 : 0);
    formData.append('roles[]', values.role);
    if (values.file) {
        formData.append('avatar', values.file[0]);
        if ( initAvatar !== defaultImage ) { // if there is already an old image 
            formData.append('old_avatar', initAvatar);
        } else { 
            formData.append('old_avatar', defaultImage);
        }
    }
    try {
        await axios({
            url: `${url}/api/v1/account/update`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: formData,
        })
        //update company and account in the localforage
        getCompanyAPI(company_id);
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}