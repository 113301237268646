import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& a': {
            textDecoration: 'none',
            marginTop: 30,
        }
    },
    container: {
        padding: theme.spacing(8),
        marginTop: theme.spacing(8),
    },
    welcomeText: {
        marginBottom: theme.spacing(4),
        '& h3': {
            marginBottom: theme.spacing(1),
        }
    }, 
    optionsWrap: {
        maxWidth: '1100px !important',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(8),
        flexWrap: 'wrap',
        '&>a': {
            margin: '1%',
            height: '100%'
        },
    },
    serviceOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wholePageLoading: {
        position: 'relative !important',
        zIndex: "10000 !important",
        background: "#fff !important"
    },
}))

export default useStyles;