import React, { useState } from 'react';
import { Button, Typography, FormControlLabel, Radio } from '@material-ui/core';
import { FileUploadErrorText } from './StyledTexts';
import TemplateFile from '../../assets/excel/template.xlsx';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/delegationStyle';
import { GetAppOutlined } from '@material-ui/icons';
import { MissingSelectedErrorText } from './StyledTexts';

const ExcelFileUpload = props => {
    const classes = useStyles();
    const { t } = useTranslation('devicesGroups');
    const { selectWay, setSelectWay, excelFile, setExcelFile, label } = props;
    const [ fileUploadError, setFileUploadError ] = useState(false);
    const excelExtension = ['xlsx', 'xls', 'xlsb', 'csv', 'xlsm'];

    const handleFileUpload = e => {
        setSelectWay('upload');
        const fileName =  e.target.files[0].name; 
        if (e.target.files && e.target.files[0]) {
            const fileExtension = fileName.split('.').pop();
            if(excelExtension.includes(fileExtension)){
                setFileUploadError(false);
                setExcelFile(e.target.files[0]);
            } else {
                setFileUploadError(true);
                setExcelFile(null);
            }
        }
    }

    return (
        <>
            <FormControlLabel value="upload" control={<Radio color="primary" />} label={label} />
            <div className={classes.uploadFileWrap}>
            <div className={classes.deviceExcelFileUploadMainInput}>
                <Button variant="outlined" component="label" onClick={()=>{setSelectWay('upload')}}>
                    <input type="file" id="fileInput" onChange={handleFileUpload} className={classes.hiddenInput} />    
                </Button>
                <Typography variant="button" className={classes.uploadBtn} color="primary" >
                    {t('setting.uploadBtn')}
                </Typography>
                {fileUploadError && <FileUploadErrorText text='uploadExcelFileErrorMsg'/>}
                {(selectWay === 'upload' && excelFile === false) && <MissingSelectedErrorText text='selectedTargetRequired' />}
            </div>
            <Button 
                href={TemplateFile} 
                variant="text" 
                color="secondary" 
                className={classes.downloadTemplate} 
                startIcon={<GetAppOutlined color="primary" />}>
                    {t('setting.downloadTemplateBtn')}
            </Button>
        </div>
        </>
    )
}

export default ExcelFileUpload;