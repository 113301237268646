import * as Yup from 'yup';

// common form validation related
export const thisIsRequired = t => `${t('common.thisIsRequired')}`;

export const resetPasswordValuesSchema = t => Yup.object().shape({
    oldPassword: Yup.string().required(thisIsRequired(t)),
    newPassword: Yup.string().required(thisIsRequired(t)),
    confirmNewPassword: Yup.string().required(thisIsRequired(t)).oneOf([Yup.ref('newPassword'), null], `${t('resetPwd.pwdNotMatched')}`),
});
    
export const resetPasswordInitialValues={
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
};

export const loginValuesSchema = t => Yup.object().shape({
    email: Yup.string().required(thisIsRequired(t)).email(`${t('login.emailInvalidMsg')}`),
    password: Yup.string().required(thisIsRequired(t)),
});
    
export const loginInitialValues={
    email: '',
    password: '',
};

export const forgotPwdValuesSchema = t => Yup.object().shape({
    email: Yup.string().required(thisIsRequired(t)).email(`${t('login.emailInvalidMsg')}`),
});
    
export const forgotPwdInitialValues={
    email: '',
};