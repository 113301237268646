import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from '../../styles/loadingIndicatorStyle';
import { Typography } from '@material-ui/core';

const LoadingIndicator = (props) => {
    const classes = useStyles();
    const { typographyVariant, typographyText } = props;
    return(
        <div className={classes.root}>
            <CircularProgress />
            <Typography variant={typographyVariant}>{typographyText}</Typography>
        </div>
    )
}

export default LoadingIndicator;