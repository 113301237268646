import React from 'react';
import AppBar from '../components/commons/AppBar';
import Footer from '../components/commons/Footer';
import useStyles from '../styles/privateLayoutStyle';

const PrivateLayout = (props) => {

    const classes = useStyles();
    return(
        <div className={classes.root}>
            <AppBar />
            { props.children }
            <Footer />
        </div>
    )
}

export default PrivateLayout;