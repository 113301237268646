import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { SubmitBtnContext } from '../../App';
import { CompanyAccountContext } from '../../containers/CompanyProfile';
import { Grid, Paper, Typography, ButtonGroup, Button, IconButton, TextField, FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { Formik } from 'formik';
import useStyles from '../../styles/companyProfileFormstyle';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { useParams } from 'react-router-dom';
import StyledSubmitBtn from '../../components/commons/StyledSubmitBtn';
import Noty from 'noty';
import "../../../node_modules/noty/lib/noty.css";  
import "../../../node_modules/noty/lib/themes/relax.css";  
import defaultImage from '../../assets/images/default_image.svg';
import '../../css/avatarEditor.css';
import { createAccountAPI } from '../../apis/createAccountAPI';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import HasPermission from '../../components/commons/HasPermission';
import { roleSelectorDeterminator } from '../../helpers/roleSelectorDeterminator';
import { newAccountValueSchema, newAccountInitialValues } from '../../config/formSchema';

const AccountCardForm = (props) => {
    const classes = useStyles();
    const { company_id } = useParams();
    const { dispatch } = useContext(SubmitBtnContext);
    const { handleCancelCreateAccount, setAccountUpdate } = useContext(CompanyAccountContext);
    const { t } = useTranslation('accountCenter');
    const [ activeStatus, setActiveStatus ] = useState(false);
    const [ initAvatar, setInitAvatar ] = useState(defaultImage);
    // eslint-disable-next-line no-unused-vars
    const [ isFormEditable, setIsFormEditable ] = useState(true);
    const [ isFileSizeError, setIsFileSizeError ] = useState(false);
    const [ roleArr, setRoleArr ] = useState([])
    const [ passwordValues, setPasswordValues] = useState({
        password: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setPasswordValues({ ...passwordValues, showPassword: !passwordValues.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    }

    const getRoleOptions = async() => {
        const roles = await roleSelectorDeterminator()
        setRoleArr(roles)
    }

    const inputCursorStyle = !isFormEditable ?  {} : {cursor: 'pointer'}

    useEffect(()=>{
        getRoleOptions()
    },[])

    return (
        <HasPermission permission="create_account">
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className={classes.accountCardWrap}>
            <Paper elevation={3} className={classes.paper}>
                <Formik
                    initialValues={newAccountInitialValues}
                    validationSchema={newAccountValueSchema}
                    onSubmit={async(values, {setSubmitting}) => {
                        setSubmitting(true)
                        dispatch({type: 'showLoading'});
                        const token = await localforage.getItem('token');
                        let responseStatus = await createAccountAPI(token, company_id, values, activeStatus)
                        if (responseStatus === true) {
                            handleCancelCreateAccount()
                            new Noty({
                                type: 'success',
                                theme: 'relax',
                                layout: 'topRight',
                                closeWith: ['click', 'button'],
                                timeout: process.env.REACT_APP_NOTY_TIMEOUT,
                                text: `${t('companyProfileForm.successfullyUpdateCompany')}`,
                                callbacks: {
                                    afterClose: function() {
                                        setAccountUpdate(true)
                                    }
                                }
                            }).show();
                        } 
                        dispatch({type: 'stopLoading'});
                    }
                    }
                >
                    {({
                        values, errors, touched, handleChange, handleBlur, handleSubmit,
                        isSubmitting, setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} className={classes.mainContent}>
                            <div className={classes.avatarUploaderWrap}>
                                <div className="avatar-editor">
                                    <input type='file' 
                                        onChange={(event) => {
                                            setIsFileSizeError(false)
                                            if (event.target.files && event.target.files[0]) {
                                                const src = URL.createObjectURL(event.target.files[0]); // set src to blob url
                                                // const fileName =  event.target.files[0].name; 
                                                const fileSize = event.target.files[0].size / 1024 / 1024;
                                                if( fileSize > 0.5) {
                                                    setIsFileSizeError(true)
                                                    return
                                                } else {
                                                    setIsFileSizeError(false)
                                                }
                                                setFieldValue("file", event.currentTarget.files);
                                                setInitAvatar(src)
                                            }
                                        }}
                                        disabled={isSubmitting}
                                        style={inputCursorStyle} 
                                    />
                                    <div className="avatar-image">
                                        <img src={initAvatar} alt="avatar" />
                                    </div>
                                </div>
                                <div>
                                    <Typography variant="caption" color="secondary">
                                    {t('companyProfileForm.imageSize')}
                                    </Typography>
                                    <Typography variant="caption" color="secondary">
                                        {t('companyProfileForm.imageFormat')}
                                    </Typography>
                                    { isFileSizeError ? 
                                        <Typography variant="caption" color="error">Please upload file within 500 KB.</Typography>
                                        :
                                        ''
                                    }
                                </div>
                            </div>    
                            <TextField
                                className={classes.nameTexField}
                                variant="standard"
                                placeholder="name *"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                helperText={errors.name && touched.name && errors.name}
                                error={!!(errors.name && touched.name && errors.name)}
                            />
                            <TextField
                                placeholder="Phone *"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                helperText={errors.phone && touched.phone && errors.phone}
                                error={!!(errors.phone && touched.phone && errors.phone)}
                            />
                            <TextField
                                placeholder="Email *"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                helperText={errors.email && touched.email && errors.email}
                                error={!!(errors.email && touched.email && errors.email)}
                            />
                            <TextField
                                select
                                SelectProps={{
                                    native: true,
                                }}
                                name="role"
                                value={values.role}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                className={clsx({
                                    [classes.roleSelection] : !values.role,
                                    [classes.roleSelected] : values.role
                                })}
                                onBlur={handleBlur}
                                helperText={errors.role && touched.role && errors.role}
                                error={!!(errors.role && touched.role && errors.role)}
                            >
                                <option value={null}>Select a role *</option>
                                {roleArr.map((role, i) => {
                                    return (<option key={i} value={role}>{role}</option>)
                                })}
                            </TextField>
                            <div className={classes.passwordWrap}>
                                <TextField
                                    placeholder="Password *"
                                    name="password"
                                    type={passwordValues.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    onBlur={handleBlur}
                                    helperText={errors.password && touched.password && errors.password}
                                    error={!!(errors.password && touched.password && errors.password)}
                                />
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    disabled={isSubmitting}
                                >
                                    {passwordValues.showPassword ? <Visibility /> : <VisibilityOff />}                              
                                </IconButton>
                                <Typography variant="caption" color="textSecondary">{t('accountProfile.thePasswordMustBeAtLeast8Characters')}</Typography>
                            </div>
                            <HasPermission permission="active_account">
                                <FormControl className={`${classes.activeStatus} ${classes.activeStatusEditable}`}>
                                    <FormLabel>Active</FormLabel>
                                    <RadioGroup row aria-label="active" name="active"
                                        value={values.active}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        >
                                        <FormControlLabel control={<Radio 
                                                color="primary" 
                                                onClick={(event)=>{setActiveStatus(!activeStatus)}}
                                                checked={activeStatus}
                                                disabled={isSubmitting}
                                            />}
                                            value={activeStatus}
                                        />
                                    </RadioGroup>
                                    <FormHelperText
                                        hidden={!(errors.active && touched.active && errors.active)}
                                        error={!!(errors.active && touched.active && errors.active)}
                                    >
                                        {errors.active && touched.active && errors.active}
                                    </FormHelperText>
                                </FormControl>
                            </HasPermission>
                            <ButtonGroup variant="text" className={classes.buttonGroup}>
                                <StyledSubmitBtn btnColor="secondary" variant="text" startIcon={<SendOutlinedIcon color="primary" />}  disabled={isSubmitting}
                                    btnText={t('companyProfileForm.confirmBtn')} 
                                />
                                <Button type="button" color="secondary" onClick={props.handleCancelCreateAccount}  disabled={isSubmitting}>
                                    {t('companyProfileForm.cancelBtn')}
                                </Button>
                            </ButtonGroup>
                        </form>
                    )}
                </Formik>
            </Paper>
        </Grid>
        </HasPermission>
    )
}

export default AccountCardForm;