import { getRolesForServicesAPI } from '../apis/getRolesForServicesAPI';
import * as localforage from 'localforage';

export const roleSelectorDeterminator = async() => {
    const userPermissions = await localforage.getItem('userPermissions')
    const allRoles = await getRolesForServicesAPI()
    const roles = allRoles.map((role)=>{
        return role.name
    })
    if(!userPermissions.includes('administrator_role')){
        const removedRoleIndex = roles.indexOf('administrator_role')
        roles.splice(removedRoleIndex, 1)
    }
    return roles
}