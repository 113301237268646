import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Grid, Button, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
// import * as localforage from 'localforage';
// import axios from 'axios';
// import { url } from '../../apis/apiSetup';
import { errorHandling } from '../../helpers/errorHandling';
import { useTranslation } from "../../langs/useTranslation";

const EditGroupNameInput = (props) => {
    const id = props.id;
    const { t } = useTranslation('devicesGroups')
    const inputvalue = props.inputValue;
    const [ isFieldDisabled, setIsFieldDisabled ] = useState(true);
    const [ value, setValue ] = useState(inputvalue);
    const [ storedValue, setStoredValue ] = useState(inputvalue);
    const [ valueErrorStatus, setValueErrorStatus ] = useState(false)
    
    const handleTextEditabled = () => {
        setIsFieldDisabled(false);
    }

    const handleTextSaved = async() => {
        setIsFieldDisabled(true);
        if( value ){
            if ( value !== storedValue ){
                try {
                    // const token = await localforage.getItem('token');
                    // let response = await axios({
                    //     url: `${url}/api/v1/company/group/update`,
                    //     method: 'PUT',
                    //     headers: {
                    //         Accept: 'application/json',
                    //         Authorization: `Bearer ${token}`
                    //     },
                    //     data: {
                    //         name: value,
                    //         id: id
                    //     }
                    // });
                    setStoredValue(value);
                } catch (error) {
                    setValue(storedValue);
                    errorHandling(error, false);
                }
            }
            setValueErrorStatus(false)
            return
        } else {
            setValueErrorStatus(true)
            setIsFieldDisabled(false);
        }
    }

    const handleTextEditabledCancel = () => {
        setValue(storedValue);
        setIsFieldDisabled(true);
    }

    const handleValueChanged = (event) => {
        setValue(event.target.value);
    }

    useEffect(()=>{
        setValue(inputvalue)
        setStoredValue(inputvalue)
    },[inputvalue])

    return (
        <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
                <TextField 
                    id={id}
                    value={value} 
                    disabled={isFieldDisabled} 
                    onChange={handleValueChanged}
                />
                { valueErrorStatus ? 
                    <Typography color="error" variant="caption">{t('setting.nameRequired')}</Typography>
                    :
                    ''
                }
            </Grid>
            {
                isFieldDisabled ? 
                (
                <Grid item xs={6}>
                    <Button color="secondary" onClick={handleTextEditabled}  startIcon={<EditOutlinedIcon />}>{t('setting.renameGroup')}</Button>
                </Grid>
                )
                :
                (
                <Grid item xs={6}>
                    <IconButton aria-label="edit" size="small" onClick={handleTextSaved} >
                        <CheckRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="cancel" size="small" onClick={handleTextEditabledCancel} >
                        <CloseRoundedIcon fontSize="small" />
                    </IconButton>
                </Grid>    
                )
            }
        </Grid>
    )
}

export default EditGroupNameInput;