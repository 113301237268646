import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { getEndOfDayInUTC, getStartOfDayInUTC } from '../helpers/getUTCDateTime';
import { successHandling } from '../helpers/successHandling';

export const renewSubscribeServiceAPI = async(t, setDialogOpen, serviceId, users, startedAt, endedAt) => {
    
    const token = await localforage.getItem('token');
    const userJsons = users.map( o => ({ id: o.id, company_id: o.origin.company_id, name: o.name, email: o.origin.email}));
    const successfulText ='subscribeNewService.successfullyRenewSubscription';

    try {
        let response = await axios({
            url: `${url}/api/v1/subscribe/renewal`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: {
                subscribe_value_added_service_id: serviceId,
                users: JSON.stringify(userJsons),
                started_at: getStartOfDayInUTC(startedAt),
                ended_at: getEndOfDayInUTC(endedAt)
            }
        })

        successHandling(t, successfulText, 'success');
        setDialogOpen(true);
        return response.data.subscription;

    } catch (error) {
        errorHandling(error, false);
        return false;
    }
    
}