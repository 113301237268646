import React, { useState, useContext } from 'react';
import { Dialog, Grid, Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { SubmitBtnContext, NotificationContext } from '../../App';
import useStyles from '../../styles/delegationStyle';
import { useTranslation } from "../../langs/useTranslation";
import '../../css/dataTable.css';
import * as localforage from 'localforage';
import StyledSubmitBtn from '../commons/StyledSubmitBtn';
import { GetAppOutlined } from '@material-ui/icons';
import { uploadNewDevicesAPI } from '../../apis/uploadNewDevicesAPI';
import { successHandling } from '../../helpers/successHandling';
import TemplateFile from '../../assets/excel/template.xlsx';

const ImportDevicesDialog = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;
    const { t } = useTranslation('devicesGroups');
    const { dispatch } = useContext(SubmitBtnContext);
    const { getNotiDispatch } = useContext(NotificationContext);
    const [ excelFile, setExcelFile ] = useState();
    const [ fileUploadError, setFileUploadError ] = useState(false);

    const handleFileUpload = (event) => {
        const fileName =  event.target.files[0].name; 
        if (event.target.files && event.target.files[0]) {
            const fileExtension = fileName.split('.').pop();
            if(fileExtension === 'xlsx' || fileExtension === 'xls' || fileExtension === 'xlsb' || fileExtension === 'csv' || fileExtension === 'xlsm' ){
                setFileUploadError(false);
                setExcelFile(event.target.files[0])
            } else {
                setFileUploadError(true);
            }
        }
    }

    const successfullyDelegationHandling = (responseStatus) => {
        if (responseStatus === true) {
            successHandling(t, 'setting.successfullyImportDevices', 'success')
            getNotiDispatch({type:'get'})
            getNotiDispatch({type:'cancel'})
        } 
    }
    
    const handleImportDevices = async() => {
        dispatch({type: 'showLoading'});
        const token = await localforage.getItem('token');
        const companyId = await localforage.getItem('userCompanyID')
        let responseStatus = await uploadNewDevicesAPI(token, companyId, excelFile);
        if(responseStatus){
            successfullyDelegationHandling(responseStatus)
            onClose(true);
            setExcelFile()
        }
        dispatch({type: 'stopLoading'});
    }

    return (
      <Dialog className={classes.importDevicesDialog} onClose={onClose} aria-labelledby="edit-groups" open={open}>
        <DialogTitle align="center">{t('setting.uploadDeviceSN')}</DialogTitle>
        <DialogContent>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                <div className={`${classes.uploadFileWrap} ${classes.dialogUploadFileWrap}`}>
                        <div className={`${classes.deviceExcelFileUploadMainInput} ${classes.dialogDeviceExcelFileUploadMainInput}`}>
                            <Button variant="outlined" component="label">
                                <input type="file" onChange={handleFileUpload} className={classes.hiddenInput} />    
                            </Button>
                            <Typography variant="button" className={classes.uploadBtn} color="primary" >{t('setting.uploadBtn')}</Typography>
                            {!fileUploadError ? '' : <Typography variant="caption" color="error" display="block" className={classes.showFileUploadStatus}>{t('setting.uploadExcelFileErrorMsg')}</Typography>}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button href={TemplateFile} variant="text" color="secondary" className={classes.downloadTemplate} startIcon={<GetAppOutlined color="primary" />}>{t('setting.downloadTemplateBtn')}</Button>
                </Grid>  
            </Grid>
        </DialogContent>
        <DialogActions className={classes.editGroupConfirmBtnWrap}>
            <div className={classes.formControlBtn}>
                <StyledSubmitBtn btnColor="primary" 
                    buttonClassname={classes.submitFormBtn}
                    btnText={t('table.confirmBtn')} 
                    onClick={handleImportDevices}
                />
                <Button color="secondary" 
                    variant="contained"
                    onClick={onClose}
                >
                    {t('table.cancelBtn')}
                </Button>
            </div> 
        </DialogActions>
      </Dialog>
    );
}

export default ImportDevicesDialog;