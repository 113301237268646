import React from 'react';
import { IconButton } from '@material-ui/core';
import { ReplayOutlined, FilterListOutlined } from '@material-ui/icons';

export const ReloadTableIconButton = props => {
    const { onClick } = props;
    return (
        <IconButton aria-label="reload table" size="small" onClick={onClick}>
            <ReplayOutlined />
        </IconButton>
    )
}

export const FilterTableIconButton = props => {
    const { onClick } = props;
    return (
        <IconButton className="filter-button" size="small" onClick={onClick}>
            <FilterListOutlined />
        </IconButton>
    )
}