import React from 'react';
import MultipleSelector from './MultipleSelector';
import CompanySelectorForAddingSubscription from './CompanySelectorForAddingSubscription';
import SingleSelector from './SingleSelector';
import { Grid, Paper, Box, Typography } from '@material-ui/core';
import { StepTitle, MissingSelectedErrorText } from '../commons/StyledTexts';
import { useTranslation } from "../../langs/useTranslation";

const SelectCompanyAndUsers = props => {
    const { t } = useTranslation('subscribeServices');
    const { users, setUsers, serviceId,
              selectedUsers, setSelectedUsers, 
              selectedUserIds, setSelectedUserIds,
              companies, selectedCompany, setSelectedCompany,
              onChangeSingleSelector, 
              isFormSubmitting, isRenewal, 
              serviceValue } = props;

    return (
        <>
            <Grid item xs={12}>
                <StepTitle title='selectCompanyAndUsers' /> 
            </Grid>
            <Grid item xs={12}>
                <Paper className="inGridSelectorWrap selectCompanyAndUserWrap">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={12} md={6} className="flex align-items-center">
                            <Box mr={2} mb={1}>
                                <Typography>Company</Typography>
                            </Box>
                            <Box mb={1} width="100%" display="block" className="company-single-selector">
                                {serviceValue === 'nubis_drive' && !isRenewal ? 
                                    <CompanySelectorForAddingSubscription
                                        noDataText={!serviceValue && `${t('subscribeNewService.pleaseSelectServiceFirst')}`}
                                        serviceValue={serviceValue}
                                        serviceId={serviceId}
                                        setSelectedCompany={setSelectedCompany}
                                        onChange={onChangeSingleSelector}
                                    />
                                    :
                                    <SingleSelector
                                        noDataText={!serviceValue && `${t('subscribeNewService.pleaseSelectServiceFirst')}`}
                                        isRenewal={isRenewal}
                                        groups={companies} 
                                        selectedGroup={selectedCompany} 
                                        onChange={onChangeSingleSelector}
                                    />
                                }
                                { isFormSubmitting && !selectedCompany && <Box mt={1}><MissingSelectedErrorText text="companyRequired" /></Box>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="flex align-items-center">
                            <Box mr={2} mb={1}>
                                <Typography>Users</Typography>
                            </Box>
                            <Box mb={1} width={`calc( 100% - 50px )`} display="block" className="users-multiple-selector">
                                <MultipleSelector
                                    noDataText={t('subscribeNewService.noUsersUnderSelectedCompany')}
                                    defaultText={!selectedCompany ? `${t('subscribeNewService.pleaseSelectCompanyFirst')}` : ''}
                                    hasNoData={selectedCompany && users.length < 1}
                                    height={32}
                                    isRenewal={isRenewal}
                                    groups={users} 
                                    setGroups={setUsers} 
                                    selectedGroups={selectedUsers} 
                                    setSelectedGroups={setSelectedUsers} 
                                    setSelectedGroupIds={setSelectedUserIds} 
                                />
                                { isFormSubmitting && !selectedUserIds && selectedUsers.length < 1 && <Box mt={1}><MissingSelectedErrorText text="userRequired" /></Box>}
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}

export default SelectCompanyAndUsers;