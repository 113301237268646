import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const authLogout = async () => {

    function sendData(path, parameters, method='post') {
        console.log("Log out")
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        document.body.appendChild(form);

        for (const key in parameters) {
            const formField = document.createElement('input');
            formField.type = 'hidden';
            formField.name = key;
            formField.value = parameters[key];

            form.appendChild(formField);
        }
        form.submit();
    }
    const token = await localforage.getItem('token');
    await localforage.clear();
    await sendData(`${url}/cas/logout`, { token });
    //await sendData('https://localhost:8089/logout', {query: 'log out'});

    return true;

        //
        // const loginData = await localforage.getItem('loginData');
        // const token = loginData.token
        // try {
        //     await axios({
        //         url: `${url}/api/v1/member/logout`,
        //         method: 'POST',
        //         headers: {
        //             Accept: 'application/json',
        //             Authorization: "Bearer " + token
        //         }
        //     });
        //     localforage.clear();
        // } catch (error) {
        //     errorHandling(error, true);
        // }
       
}