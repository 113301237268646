import React from 'react';
import { IconButton } from '@material-ui/core';
import HasPermission from '../commons/HasPermission';

 const SubscriptionBtn = props => {

    const {actionName, icon, onClick, href } = props;

    return (
        <HasPermission permission="device_and_groups_export">
            <IconButton 
                aria-label={actionName} 
                size="small" 
                onClick={onClick}
                href={href ? href : null}
            >
                {icon}
            </IconButton>
        </HasPermission>
    )
}

export default SubscriptionBtn;
