import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from "../helpers/errorHandling";

export const getCountriesAPI = async (token) => {
        try {
            let response = await axios({
                url: `${url}/api/v1/countries`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                }
            })
            const allCountriesData = response.data.countries;
            const countriesNamesAndIDs = allCountriesData.map((countryData) => {
                const filteredCountryData = {
                    name: countryData.name, 
                    id: countryData.id
                }  
                return filteredCountryData;
            });
            await localforage.setItem('countries', countriesNamesAndIDs);
        } catch (error) {
            errorHandling(error, false);
        }
}



