import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    btn: {
        // width: 300,
        lineHeight: '3em',
        borderRadius: 4,
        display: 'block',
        textAlign: 'center',
        boxShadow: '0 0 12px #dddd',
        color: '#000',
        height: '100%',
        padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
        // height: 300,
        transition: '0.2s',
        '& img': {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
            height: 70,
        },
        '& h6': {
            lineHeight: 1.2,
            marginBottom: 10,
        },
        '&:hover': {
            color: '#000 !important',
            boxShadow: '0 0 50px #dedede',
        }
    },
    wordBreak:{
        wordBreak:'break-all',
        overflow:'hidden'
    }
}))

export default useStyles;