export const langsOptions =
  [ 
    { value: 'zh_tw', label: '繁' },
    { value: 'zh_cn', label: '简' },
    { value: 'kr', label: '한' },
    { value: 'en', label: 'EN' },
    { value: 'jp', label: 'JP' },
    { value: 'fr', label: 'FR' },
    { value: 'es', label: 'ES' },
    { value: 'ru', label: 'RU' },
  ];

  export const qaUrl = 'https://docs.google.com/document/d/1lKkIlQa4TUmiSKo6Dl5he-o3HD_0s-5Ok8c2ovd674A/edit#heading=h.1yobisukd09z';