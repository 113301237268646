import React from 'react';
import useStyles from '../../styles/submitBtnStyle';
import StyledSubmitBtn from './StyledSubmitBtn';
import { Button } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { SendOutlined } from '@material-ui/icons';

const SubmitAndCancelBtn = props => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const { styledSubmitBtnOnClick, cancelBtnHref, cancelBtnDisabled, cancelBtnOnClick } = props;
    
    return (
        <div className={classes.formControlBtn}>
            <StyledSubmitBtn 
                btnColor="primary" 
                startIcon={<SendOutlined />} 
                btnText={t('btn.confirmBtn')} 
                onClick={styledSubmitBtnOnClick}
            />
            <Button 
                color="secondary" 
                variant="contained" 
                href={cancelBtnHref} 
                disabled={cancelBtnDisabled}
                onClick={cancelBtnOnClick ? cancelBtnOnClick : null}
            >
                {t('btn.cancelBtn')}
            </Button>
        </div>     
    )
}

export default SubmitAndCancelBtn;