import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, Box } from '@material-ui/core';
import { MenuPropsForTableFilter } from '../../helpers/menuProps';
import { NoDataText, DefaultMenuItem } from '../commons/StyledTexts';

const SingleSelector = props => {

    const { groups, selectedGroup, defaultText, onChange, isRenewal, noDataText } = props;
    const [ showDefaultText, setShowDefaultText ] = useState(true);

    useEffect(() => {
        setShowDefaultText(!selectedGroup);
    }, [selectedGroup])

    return (
        <div className="outlinedSelector singleSelector">
            <DefaultMenuItem text={defaultText} ifShow={showDefaultText} />
            <Select
                id="single-selector"
                value={selectedGroup || false} 
                disabled={isRenewal || groups.length < 1}
                fullWidth
                MenuProps={MenuPropsForTableFilter}
                displayEmpty
                onChange={(e) => onChange(e)}
            >
                { !isRenewal && !showDefaultText && 
                    <MenuItem value={false}>
                        <Box color="#ccc">
                            <Typography variant="caption">Clear</Typography>
                        </Box>
                    </MenuItem>
                }
                { !isRenewal && groups && groups.length > 1 ?
                    groups.map((o, i) => (
                        <MenuItem key={i} value={o} name={o.name}>
                            {o.name}
                        </MenuItem>
                    ))
                    :
                    <MenuItem value={false}>
                        <NoDataText text={noDataText} />
                    </MenuItem>
                }

                {isRenewal && 
                    <MenuItem value={selectedGroup} name={selectedGroup.name}>
                        {selectedGroup.name}
                    </MenuItem>
                }
            </Select>
        </div>
    )
}

export default SingleSelector;