import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getCompanyGroupsAPI = async(selectedCompanyId) => {

    const token = await localforage.getItem('token');
    const companyId = !selectedCompanyId ? await localforage.getItem('userCompanyID') : selectedCompanyId;

    try {
        let response = await axios({
            url: `${url}/api/v1/company/groups`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            params: {
                company_id: companyId,
            }
        })
        return response.data.data;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}