import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getUnsubscribeCompanyAPI = async(subscribeServiceId, page, record, orderByColumn, orderByDesc) => {

    const token = await localforage.getItem('token');

    try {
        let response = await axios({
            url: `${url}/api/v1/subscribe/unsubscribe-companies`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            params: {
                subscribe_service_id: subscribeServiceId,
                order_by_column: orderByColumn,
                order_by_desc: orderByDesc,
                page: page,
                record: record
            }
        })

        return response.data;
       
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}