import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  Typography,
  Divider,
  DialogActions
} from "@material-ui/core";
import AvatarEditor from "../commons/AvatarEditor";
import defaultImage from "../../assets/images/default_image.svg";
import PublishStatusSwitch from "./PublishStatusSwitch";
import TypeRadioButtons from "./TypeRadioButtons";
import useStyles from "../../styles/subscribeServicesStyle";
import SupportOsAndFile from "./SupportOsAndFileUpload";
import { SupportOsAddButtonBar } from "./SupportOsAndFileUpload";
import { FullWidthOutlinedTextField } from "../commons/StyledTextFields";
import SubmitAndCancelBtn from "../commons/SubmitAndCancelBtn";
import { SubmitBtnContext } from "../../App";
import { values, includes, some, isEmpty } from "lodash";
import { isValidURL, isValidEmail } from "../../helpers/stringValidation";
import { SimpleErrorText } from "../commons/StyledTexts";

const CreateServiceDialog = props => {
  const classes = useStyles();
  const { onClose, open } = props;
  const { t } = useTranslation("subscribeServices");
  const { dispatch } = useContext(SubmitBtnContext);
  const [imgSrc, setImgSrc] = useState(defaultImage);
  const [name, setName] = useState("");
  const [publishStatus, setPublishStatus] = useState(true);
  const [type, setType] = useState("company");
  const [person, setPerson] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [landingUrl, setLandingUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [validLandingUrl, setValidLandingUrl] = useState(true);
  const [osAndFileArr, setOsAndFileArr] = useState([{ os: "", file: "" }]);
  const [landingUrlErrorTexts, setLandingUrlErrorTexts] = useState([]);
  // const [osAndFileError, setOsAndFileError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [uploadProgress, setUploadProgress] = useState(0);
  const [validating, setValidating] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleChangeAvatar = event => {
    if (event.target.files && event.target.files[0]) {
      const src = URL.createObjectURL(event.target.files[0]);
      setImgSrc(src);
    }
  };

  const handleChangeName = e => setName(e.target.value);
  const handleChangePublishStatus = e => setPublishStatus(e.target.checked);
  const handleChangeType = e => setType(e.target.value);
  const handleChangePerson = e => setPerson(e.target.value);
  const handleChangeCompanyName = e => setCompanyName(e.target.value);

  const handleChangeEmail = e => {
    const value = e.target.value;
    setEmail(value);
    setValidEmail(isValidEmail(value));
  };

  const handleChangeLandingUrl = e => {
    const value = e.target.value;
    setLandingUrl(value);
    setValidLandingUrl(isValidURL(value));
    landingUrlErrorTexts.push(`${t("serviceManagement.enterValidUrl")}`);
    setLandingUrlErrorTexts(landingUrlErrorTexts);
  };

  const handleChangeOsOrFile = (e, i, type) => {
    let newOS;
    if (type === "os") {
      newOS = {
        os: e.target.value,
        file: osAndFileArr[i].file
      };
    }
    if (type === "file") {
      const file = e.target.files[0];
      newOS = {
        os: osAndFileArr[i].os,
        file: file
      };
    }
    osAndFileArr.splice(i, 1, newOS);
    setOsAndFileArr([...osAndFileArr]);
  };
  const handleAddSupportOs = () =>
    setOsAndFileArr([...osAndFileArr, { os: "", file: null }]);

  const handleValidating = () => {
    setValidating(true);
    let osAndFileAllValid;

    if (osAndFileArr.length > 0) {
      osAndFileAllValid =
        osAndFileArr.filter(o => includes(values(o), "", null)).length < 1;
    }

    if (!landingUrl) {
      landingUrlErrorTexts.push(`${t("serviceManagement.thisIsRequired")}`);
      setLandingUrlErrorTexts(landingUrlErrorTexts);
    }

    if (
      name &&
      publishStatus &&
      type &&
      osAndFileArr.length > 0 &&
      osAndFileAllValid &&
      landingUrl &&
      !landingUrlErrorTexts
    ) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (
      name &&
      publishStatus &&
      type &&
      osAndFileArr.length > 0 &&
      landingUrl
    ) {
      dispatch({ type: "showLoading" });
      setUploading(true);
      console.log("submit");
    } else {
      console.log("data required");
    }
  };

  useEffect(() => {
    if (open) {
      dispatch({ type: "stopLoading" });
      setUploading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
      className={classes.createServiceDialog}
    >
      <DialogTitle>{t("dialog.addService")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row">
          <AvatarEditor
            imgSrc={imgSrc}
            onChange={handleChangeAvatar}
            disabled={uploading}
          />
          <Box display="flex" flexDirection="column" width="100%">
            <TextField
              label={t("serviceManagement.serviceName")}
              variant="outlined"
              value={name}
              fullWidth={false}
              onChange={handleChangeName}
              className={`mb-2 ${classes.serviceNameField}`}
              required
              disabled={uploading}
              error={validating && !name}
              helperText={
                validating && !name
                  ? `${t("serviceManagement.thisIsRequired")}`
                  : null
              }
            />
            <PublishStatusSwitch
              typographyVariant="body1"
              checked={publishStatus}
              onChange={handleChangePublishStatus}
              className="mb-1 align-items-center"
              ifShowRequired={true}
              disabled={uploading}
            />
            <TypeRadioButtons
              value={type}
              onChange={handleChangeType}
              className="mb-2"
              disabled={uploading}
            />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <FullWidthOutlinedTextField
              label={t("serviceManagement.contactPerson")}
              value={person}
              onChange={handleChangePerson}
              required={false}
              disabled={uploading}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <FullWidthOutlinedTextField
              label={t("serviceManagement.contactEmail")}
              value={email}
              onChange={handleChangeEmail}
              required={false}
              disabled={uploading}
              error={!validEmail}
              helperText={!validEmail && `${t("serviceManagement.enterValidEmail")}`}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <FullWidthOutlinedTextField
              label={t("serviceManagement.companyName")}
              value={companyName}
              onChange={handleChangeCompanyName}
              required={false}
              disabled={uploading}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="mb-1">
            <FullWidthOutlinedTextField
              label={t("serviceManagement.landingUrl")}
              value={landingUrl}
              onChange={handleChangeLandingUrl}
              required={true}
              disabled={uploading}
              error={
                landingUrlErrorTexts.length > 0 || (validating && !landingUrl)
              }
            />
            {validating &&
              landingUrlErrorTexts.length > 0 &&
              landingUrlErrorTexts.map(o => <SimpleErrorText text={o} />)}
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-direction-row space-between align-items-center mb-1"
          >
            <Box width="120px">
              <Typography
                variant="body1"
                color="textSecondary"
                className="mr-1"
              >
                {t("serviceManagement.supportOS")} *
              </Typography>
            </Box>
            <Box width="100%">
              <Divider className="full-width" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {osAndFileArr &&
              osAndFileArr.length > 0 &&
              osAndFileArr.map((o, i) => (
                <SupportOsAndFile
                  key={i}
                  o={o}
                  i={i}
                  onChange={handleChangeOsOrFile}
                  ifLastOne={osAndFileArr.length === i + 1}
                  uploadProgress={uploadProgress}
                  disabled={uploading}
                  error={validating && some(o, isEmpty)}
                />
              ))}
          </Grid>
          <Grid
            item
            xs={12}
            className="flex flex-direction-row space-between align-items-center mb-1"
          >
            <SupportOsAddButtonBar onClick={handleAddSupportOs} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              multiline
              rows="3"
              fullWidth
              label={t("serviceManagement.notes")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="justify-content-center">
        <SubmitAndCancelBtn
          styledSubmitBtnOnClick={handleValidating}
          //   cancelBtnDisabled={}
          cancelBtnOnClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateServiceDialog;
