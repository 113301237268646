import localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const exportSubscriptionAPI = async(subscribeValueAddedServiceId) => {

    const token = await localforage.getItem('token');
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try{
        
        let response = await axios({
            url: `${url}/api/v1/subscribe/export`,
            method: "POST",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                subscribe_value_added_service_id: subscribeValueAddedServiceId,
                timezone: timezone
            }
        })
        return response.data.download_link;

    } catch (error) {
        errorHandling(error, true);
    }

}