import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grow: {
      flexGrow: 1,
      width: '100%',
      '& header': {
          zIndex: '999',
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      '& span': {
          paddingLeft: 10,
          letterSpacing: '0.2em',
          textTransform: 'uppercase',
          position: 'relative',
          top: 5,
      }
    },
    logo: {
      width: 70,
    },
    rightSection:{
      display: 'flex',
    },
    search: {
      display: 'none',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      right: 10,
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: '1em',
      transition: theme.transitions.create('width'),
      border: '1px solid #ddd',
      borderRadius: 4,
      top: 6,
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    notificationList: { 
      '& .MuiMenu-paper': {
        width: '380px !important',
      },
      '& .MuiListItem-button': {
        transition: '0.3s',
        display: 'flex !important',
        flexDirection: 'row',
        background: '#2d3ef310 !important',
        '& .notificationItemDot':{
          width: '30px',
          paddingRight: '30px',
          paddingTop: '6px',
          '& svg': {
            fontSize: 13,
          },
        },
        '&:hover': {
          background: '#2d3ef614 !important'
        },
      },
      '& .MuiListItem-button.read': {
        background: '#fff !important',
        '&:hover': {
          background: '#f3f3f3 !important'
        },
      },
    },
    notiListText: {
      '& .error': {
        color: '#f77c7c'
      },
      '& .primary': {
        color: '#7b85e8'
      }
    },
    notiMainText: {
      marginBottom: '8px !important',
    },
    notiMemos:{
      lineHeight: '1.5em !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 2, 
      boxOrient: 'vertical'
    },
    readDate: {
      paddingLeft: '8px !important'
    },
    notiDialogMainMsg: {
      marginBottom: '16px !important'
    },
    allNotiMemos:{
      lineHeight: '1.8em !important',
      marginTop: '16px !important',
    },
    notiDialogActionWrap: {
      justifyContent: 'center !important'
    }, 
    menuList: {
      top: '40px !important',
      overflow: 'initial',
    },
    langSelect: {
      position: 'relative',
      width: '48px !important',
      overflow: 'hidden',
      '& .MuiSelect-select': {
        position: 'relative',
        width: '50px !important',
        padding: 0,
        overflow: 'hidden',
        minHeight: '0 !important',
        height: '0 !important',
      },
      '& .MuiSelect-icon': {
        top: '-27px !important',
        transform: 'scale(0.8)',
      },
      '& .MuiInput-underline:before': {
        display: 'none',
      },
      '& .MuiInput-underline:after': {
        display: 'none',
      },
    },
    langBtn: {
      position: 'absolute',
      width: 48,
      left: -2,
      '& .MuiIconButton-label': {
        marginLeft: -3
      }
    },
    langBtnText: {
      width: 25,
      position: 'relative',
      left: -4,
      '& svg': {
        position: 'absolute',
        top: 2,
      },
    },
    usernameText: {
      paddingLeft: '10px !important'
    },
    qaLink: {
      lineHeight: '1em !important',
      color: '#8a8a8a !important'
    },
    releaseVersionText: {
      color: '#ccc',
      fontSize: '10px',
      letterSpacing: 'initial !important'
    }
}))

export default useStyles;