import React, { useState, useContext } from "react";
import { MenuItem, IconButton, Select } from "@material-ui/core";
import useStyles from "../../styles/appBarRightStyle";
import TranslateIcon from "@material-ui/icons/Translate";
import { LanguageContext } from "../../langs/LanguageContext";
import { languageOptions } from "../../langs/index";

const LangSelect = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleLangMenuOpen = () => {
    setOpen(true);
  };

  const handleLangMenuClose = () => {
    setOpen(false);
  };

  const handleLangChange = (event) => {
    userLanguageChange(event.target.value);
  };

  const renderLangMenu = (
    <Select
      open={open}
      defaultValue={userLanguage}
      className={classes.menuList}
      onClose={handleLangMenuClose}
      value={userLanguage}
      onChange={handleLangChange}
      MenuProps={{ id: "lang-select", disableScrollLock: true }}
    >
      {Object.entries(languageOptions).map(([id, name]) => (
        <MenuItem key={id} value={id} name={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <div className={classes.langSelect}>
      <IconButton
        className={classes.langBtn}
        aria-label="lang"
        onClick={handleLangMenuOpen}
      >
        <TranslateIcon />
      </IconButton>
      {renderLangMenu}
    </div>
  );
};

export default LangSelect;
