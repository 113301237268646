import React from "react";
import { Typography, Box } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/delegationStyle";

export const StepTitle = props => {
  const { title } = props;
  const { t } = useTranslation("common");

  return <Typography variant="h6">{t(`setting.${title}`)}</Typography>;
};

export const SettingTitle = props => {
  const { title, action, actionsHideGroupOrTagSelection } = props;
  const { t } = useTranslation("devicesGroups");

  return (
    <Typography
      variant="body1"
      color={
        actionsHideGroupOrTagSelection.includes(action)
          ? "textSecondary"
          : "primary"
      }
    >
      {t(`setting.${title}`)}
    </Typography>
  );
};

export const SettingText = props => {
  const { text } = props;
  const { t } = useTranslation("devicesGroups");

  return (
    <Typography variant="body1" color="primary">
      {t(`setting.${text}`)}
    </Typography>
  );
};

export const MissingSelectedErrorText = props => {
  const { text } = props;
  const { t } = useTranslation("common");

  return (
    <Typography variant="caption" color="error">
      {t(`setting.${text}`)}
    </Typography>
  );
};

export const FileUploadErrorText = props => {
  const classes = useStyles();
  const { text } = props;
  const { t } = useTranslation("devicesGroups");

  return (
    <Typography
      variant="caption"
      color="error"
      display="block"
      className={classes.showFileUploadStatus}
    >
      {t(`setting.${text}`)}
    </Typography>
  );
};

export const NoDataText = props => {
  const { text } = props;

  return (
    <Typography
      variant="caption"
      color="textSecondary"
      className="default-value"
    >
      {text}
    </Typography>
  );
};

export const DefaultMenuItem = props => {
  const { text, ifShow } = props;

  return (
    <Box display={ifShow ? "block" : "none"}>
      <Typography
        variant="caption"
        color="textSecondary"
        className="default-value"
      >
        {text}
      </Typography>
    </Box>
  );
};

export const CaptionText = props => {
  const { text, className } = props;
  return (
    <Typography variant="caption" color="secondary" className={className}>
      {text}
    </Typography>
  );
};

export const Body2Text = props => {
  const { children, className } = props;
  return (
    <Typography variant="body2" color="secondary" className={className}>
      {children}
    </Typography>
  );
};

export const SimpleErrorText = props => {
    const { text } = props;
    return (
      <Typography variant="caption" color="error">
        {text}
      </Typography>
    );
  };
  