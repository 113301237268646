import React from 'react';
import ReactDOM from 'react-dom';
import * as localforage from 'localforage';
import * as $ from 'jquery';
import 'datatables.net';
import 'datetime-moment';
import '../css/dataTable.css';
import { url } from '../apis/apiSetup';
import { language } from './datatableSetting';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import { searchStyleRender } from './datatableSearchIconRender';
import { deleteCompanyGroupAPI } from '../apis/deleteCompanyGroupAPI';
import EditGroupNameInput from '../components/devicesGroups/EditGroupNameInput';

export const table = async(t) => {
    const token = await localforage.getItem('token');
    const company_id = await localforage.getItem('userCompanyID');

    $('#edit-groups-table').DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        filter: false,
        deferRender: true,
        info: false,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        scrollY: 'calc( 90vh - 410px )',
        paging: false,
        ordering: true,
        order: [[ 0, 'asc' ]],
        ajax: {
            url: `${url}/api/v1/company/groups`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                company_id: company_id,
            },
            beforeSend: request => {
                request.setRequestHeader('Authorization', 'Bearer ' + token );
                request.setRequestHeader('Accept', 'application/json');
            },
            error: () => {
                window.location.replace(process.env.REACT_APP_UNAUTH_REDIRECT_URL);
            },
        },
        columns: [
            {   
                data: 'name', 
                name: 'name',
                title: `${t('table.groupName')}`,
                searchable: true,
                orderDataType: 'dom-text', 
                type: 'string',
                createdCell: (td, data, rowData) => {
                    ReactDOM.render (<EditGroupNameInput id={rowData.id} inputValue={data} />, td)
                }
            },
            {   
                data: 'id',
                title: `${t('setting.actions')}`,
                className: 'action',
                searchable: false,
                createdCell: (td, data) => {
                    ReactDOM.render (
                    <IconButton aria-label="delete" onClick={()=>deleteCompanyGroup(t, data)}> 
                        <DeleteOutlined color="secondary" />
                    </IconButton>
                    , td)
                },
            },
        ]
    })

    searchStyleRender();
}


// var table = $('#edit-groups-table').DataTable();

export const deleteCompanyGroup = async(t, id) => {
    const responseStatus = await deleteCompanyGroupAPI(id);
    if(responseStatus) table(t);
}