import React from 'react';
import useStyles from '../../styles/privateLayoutStyle';
import { useTranslation } from "../../langs/useTranslation";

const Footer = () => {
    const { t } = useTranslation('common'); 
    const classes = useStyles();

    return <div className={classes.footer}>{t('footer.copyright')}</div>
}

export default Footer;