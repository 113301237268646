import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import HasPermission from '../commons/HasPermission';
import { table } from '../../helpers/delegationDatatable';
import * as localforage from 'localforage';

const ActionRadioGroup = props => {
    const { t } = useTranslation('devicesGroups');
    const { action, setAction, setNubisVersion, selectedCompanyIds, selectedGroupIds, nubisVersion, setIsMissingSelectedTarget, setResetSearchField }  = props;

    const handleActionChange = async(e) => {
        
        const action = e.target.value;
        
        await localforage.removeItem('dtDeviceIds');
        
        setAction(action);
        setIsMissingSelectedTarget(false); // reset form validation
        setResetSearchField(true); 
        
        switch (action) {
            case 'nubis_free':
                table(t, action, undefined, undefined, 'nubis_pro');
                break;
            case 'nubis_pro':
                table(t, action, undefined, undefined, 'nubis_free');
                break;
            case 'remove':
            case 'register':
                setNubisVersion('');
                table(t, action, selectedCompanyIds, selectedGroupIds, nubisVersion);
                break;
            default:
                table(t);
        }

    };

    return(
        <FormControl>
            <RadioGroup row aria-label="action" name="action" value={action} onChange={handleActionChange}>
                <HasPermission permission="device_and_groups_assign">
                    <FormControlLabel control={<Radio color="primary" />} value="assign" label={t('setting.assign')}  />
                </HasPermission>
                <HasPermission permission="device_and_groups_remove">
                    <FormControlLabel control={<Radio color="primary" />} value="remove" label={t('setting.remove')} />
                </HasPermission>
                <HasPermission permission="device_and_groups_register">
                    <FormControlLabel control={<Radio color="primary" />} value="register" label={t('setting.register')} />
                </HasPermission>
                <HasPermission permission="device_and_groups_nubis_version">
                    <FormControlLabel control={<Radio color="primary" />} value="nubis_free" label={t('setting.nubisFree')} />
                </HasPermission>
                <HasPermission permission="device_and_groups_nubis_version">
                    <FormControlLabel control={<Radio color="primary" />} value="nubis_pro" label={t('setting.nubisPro')} />
                </HasPermission>
            </RadioGroup>
        </FormControl>
    )
}

export default ActionRadioGroup;