import React, { useEffect, useState } from 'react';
import localforage from 'localforage';

const HasPermission = (props) => {
    const [hasPermission, setHasPermission] = useState(false)

    const checkHasPermission = async() => {
        // get the user role permission
        const userPermissions = await localforage.getItem('userPermissions')
        if(userPermissions){
            const isPermission = userPermissions.includes(props.permission)
            setHasPermission(!!isPermission)
        }
    }
    
    useEffect(()=>{
        checkHasPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        (hasPermission ? 
            <>{props.children}</>
            :
            ''
        )
    )
}

export default HasPermission;