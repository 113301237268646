import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/subscribeServicesStyle";
import SearchFieldAndButton from "../commons/SearchFieldAndButton";
import SingleSelector from "./SingleSelector";
import { FilterTableIconButton } from "../commons/IconButtons";
import { setUpArrayWithCheckStatus } from "../../helpers/setUpArrayWithCheckStatus";
import { typeArray, osArray } from "../../config/subscriptionsOptions";
import ServiceListItem from "./ServiceListItem";
import { getServicesAPI } from "../../apis/getServicesAPI";
import { NoDataText } from "../commons/StyledTexts";
import LoadingIndicator from "../commons/LoadingIndicator";

const ServiceList = () => {
  const classes = useStyles();
  const { t } = useTranslation("subscribeServices");
  const [services, setServices] = useState([]);
  const [resetSearchField, setResetSearchField] = useState(false);
  const [os, setOs] = useState([]);
  const [selectedOs, setSelectedOs] = useState('');
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [loading, setLoading] = useState(true);

  const getFilterInitialData = async () => {
    setUpArrayWithCheckStatus(typeArray(t), setTypes);
    setUpArrayWithCheckStatus(osArray(t), setOs);
  };

  const handleOsChange = e => {
    setSelectedOs(e.target.value);
  };
  const handleTypeChange = e => {
    setSelectedType(e.target.value);
  };

  const handleSearch = async searchValue => {
    setLoading(true);
    const services = await getServicesAPI(
      selectedOs,
      selectedType,
      searchValue
    );

    if (services) {
      setServices(services);
      setLoading(false);
    }
  };

  const getServices = async (clearFilter = false) => {
    setLoading(true);
    const os = clearFilter ? null : selectedOs;
    const type = clearFilter ? null : selectedType;

    const services = await getServicesAPI(os, type);

    if (services) {
      setServices(services);
      setLoading(false);
    }

    if (clearFilter) {
      setSelectedOs(null);
      setSelectedType(null);
      setResetSearchField(true);
    }
  };

  useEffect(() => {
    getFilterInitialData();
    getServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.iconButtonGroupWthTitle}
      >
        <Grid item lg={2} sm={12}>
          <Typography variant="h6">
            {t("serviceManagement.serviceList")}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sm
          lg
          className="iconButtonGroup"
          alignItems="center"
          justify="flex-end"
        >
          <SingleSelector
            groups={os}
            selectedGroup={selectedOs}
            defaultText={t("serviceManagement.os")}
            onChange={handleOsChange}
          />
          <SingleSelector
            groups={types}
            selectedGroup={selectedType}
            defaultText={t("serviceManagement.type")}
            onChange={handleTypeChange}
          />
          <FilterTableIconButton onClick={() => getServices(false)} />
          <SearchFieldAndButton
            resetSearchField={resetSearchField}
            setResetSearchField={setResetSearchField}
            searchDatatableForTable={handleSearch}
          />
        </Grid>
      </Grid>
      {loading ? (
        <LoadingIndicator />
      ) : services.length > 0 ? (
        services.map((o, i) => <ServiceListItem serviceData={o} key={i} />)
      ) : (
        <Box
          display="flex"
          width="100%"
          height="200px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <NoDataText text={t("serviceManagement.noData")} />
          <Typography
            variant="caption"
            color="primary"
            onClick={() => getServices(true)}
            className="pointer block"
          >
            {t("serviceManagement.clearFilter")}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ServiceList;
