import React, { useEffect } from 'react';
import { Paper, Box, Typography, Divider } from '@material-ui/core';
import DatePicker from './DatePicker';

const SetDates = props => {

    const {  startedAt, setStartedAt, endedAt, setEndedAt, 
                startDateLocalforageName, 
                endDateLocalforageName,
                setDateRange, isRenewal } = props; 

    useEffect(()=>{
        setDateRange(!!(startedAt && endedAt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startedAt, endedAt])

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" className="flex-direction-column-from-sm">
            <Box width="46%" className="full-width-from-sm">
                <Paper className={`full-width flex align-items-center ${isRenewal && !startedAt ? "primary-shadow" : ''}`}>
                    <Box mr={2} width="105px">
                        <Typography>Start at</Typography>
                    </Box>
                    <Box width="100%">
                        <DatePicker
                            noLockPast={true}
                            dateState={startedAt}
                            setDateState={setStartedAt}
                            comparedDate={endedAt}
                            storedDatePickerValueToLocalforageName={startDateLocalforageName}
                        />
                    </Box>
                </Paper>
            </Box>
            <Divider className="dates-connector hide-xs-sm" />
            <Box width="46%" className="full-width-from-sm">
                <Paper className={`full-width flex align-items-center ${isRenewal && !endedAt ? "primary-shadow" : ''}`}>
                    <Box mr={2} width="105px">
                        <Typography>End at</Typography>
                    </Box>
                    <Box width="100%">
                        <DatePicker
                            dateState={endedAt}
                            setDateState={setEndedAt}
                            comparedDate={startedAt}
                            storedDatePickerValueToLocalforageName={endDateLocalforageName}
                        />
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default SetDates;