import React from 'react';
import '../../index.css';
import { useTranslation } from "../../langs/useTranslation";

const StatusChip = props => {
    const { data } = props;
    const { t } = useTranslation('common');

    return ( 
        <span className={`${data} status`}>
            {t(`status.${data}`)}
        </span>
    )
}

export default StatusChip;