import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        height: '100vh',
        flexDirection: 'column',
    },
    loadingWrap: {
        display: 'flex',
        height: 'calc(100% - 30px) !important',
        minHeight: '300px',
        alignItems: 'center !important',
        justifyContent: 'center !important',
    },
    titleSection: {
        margin: '16px 0 !important',
    },
    companyProfileForm: {
        paddingBottom: '8px !important',
        '& form': {
            paddingBottom: '0 !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#666 !important',
        }, 
        '& .MuiInputBase-input': {
            fontSize: '1rem !important',
        }
    },
    errorText: {
        marginTop: -10,
        padding: '5px 0',
        color: 'red',
        fontSize: '12px',
        textAlign: 'left',
        width: '100%',
    },
    mainContent: {
        height: '100% !important',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '60px !important',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        position: 'relative !important',
        alignContent: 'baseline !important',
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    input: {
        width: '30%',
        marginBottom: 24,
        [theme.breakpoints.down('md')]: {
            width: '100% !important',
        },
    },
    inputLong: {
        width: '65%',
    },
    linkText: {
        textAlign: 'center',
        fontSize: 14,
        margin: '30px auto 0',
        display: 'block',
        padding: 5,
        color: '#aaa !important',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
    },
    formControlBtn: {
        position: 'absolute',
        top: '-90px !important',
        right: '-30px !important',
        '&>div': {
            display: 'inline !important',
            '& .MuiCircularProgress-colorPrimary': {
                marginLeft: '-7px !important',
                marginTop: '-10px !important',
                zIndex: 10,
            },
            '& .MuiButton-textPrimary': {
                background: '#2c3ff3 !important',
                color: '#fff !important',
                '&:hover': {
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important'
                }
            },
            '& .MuiButton-root.Mui-disabled': {
                background: '#ddd !important',
                color: '#c3c3c3 !important'
            }
        },
        '& button': {
            marginLeft: '10px !important',
            width: 'initial !important',
            lineHeight: 'initial !important',
        },
    },
    accountCardWrap: {
        minHeight: '430px !important',
        '& .MuiPaper-root': {
            height: '100% !important',
            paddingBottom: '0 !important',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            backgroundColor: 'transparent !important',
        },
        '& input:-internal-autofill-selected': {
            backgroundColor: 'transparent !important',
        },
        '& .MuiFormControl-root': {
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%', 
            '& .MuiFormGroup-row': {
                width: '40px !important',
            },
            '& .MuiInput-root': {
                width: '100% !important',
            },
        },
        '& .MuiButton-textSecondary': {
            background: 'transparent !important'
        },
        '& .edit-remove-btn-wrap': {
            '& .MuiIconButton-colorSecondary': {
                padding: '6px !important'
            }
        }
    },
    avatarWrap: {
        zIndex: '9 !important',
        position: 'relative',
        marginBottom: '0 !important',
        width: 'auto !important',
        height: '0 !important',
        right: '0 !important',
        '&>div': {
            transform: 'scale(0.7)',
            position: 'absolute !important',
            top: '0 !important',
            right: '0 !important',
            '& input': {
                marginRight: '0 !important'
            }
        },
        '&>div.avatar-editor': {
            width: 'auto !important'
        }
    },
    avatarUploaderWrap: {
        marginBottom: '20px !important',
        '& .MuiTypography-caption': {
            display: 'block !important',
        },
        '& .MuiTypography-colorSecondary': {
            color: '#9e9e9e !important',
        },
        '& .avatar-editor':{
            transition: '0.2s all',
            '& input': {
               outline: 'none !important',
               overflow: 'hidden !important',
           },
           '&:hover': {
               opacity: '0.8'
           }
        },
    },
    passwordWrap: {
        width: '100% !important',
        position: 'relative !important',
        marginBottom: '20px',
        '& .MuiFormControl-root': {
            marginBottom: '6px',
        },
        '& .MuiIconButton-root': {
            position: 'absolute !important',
            top: '-10px !important',
            right: '-9px !important',
            zIndex: '9 !important'
        },
        '& .MuiTypography-colorTextSecondary': {
            letterSpacing: '0.03em',
            color: '#9e9e9e !important'
        }
    },
    roleSelection: {
        '& .MuiSelect-select.MuiSelect-select': {
            color: '#a2a2a2 !important',
        },
        '& .MuiFormLabel-root': {
            color: '#a2a2a2 !important',
        },
    },
    roleSelected: {
        '& .MuiSelect-select.MuiSelect-select .MuiInputBase-input.Mui-disabled': {
            color: '#333 !important'
        },
    },
    activeStatus: {
        flexDirection: 'row !important',
        alignItems: 'center !important',
        '& .MuiFormLabel-root':{
            width: 'auto !important',
            marginRight: '10px !important',
            color: '#666 !important',
        },
        '& input::before': {
            width: '40px !important',
            height: '40px !important'
        },
    },
    activeStatusEditable: {
        '& .MuiFormLabel-root': {
            color: '#a2a2a2 !important',
        }
    },
    buttonGroup: {
        borderTop: '1px solid #ddd',
        width: 'calc(100% + 64px) !important',
        justifyContent: 'space-between',
        marginLeft: '-32px !important',
        height: '60px !important',
        position: 'absolute !important',
        bottom: '0 !important',
        marginRight: '-32px !important',
        padding: '0px 32px 0 32px !important',
        alignItems: 'center',
        '& .MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
            border: 'none',
        },
        '& .MuiButton-textSecondary': {
            width: 'auto !important',
            lineHeight: '3.3em !important',
            transition: '0.2s',
            padding: '0 !important',
            '&:hover': {
                background: 'none !important',
                color: '#333'
            }
        },
        '& .MuiButton-root.Mui-disabled': {
            '& .MuiSvgIcon-root': {
                color: '#cacbd0 !important'
            }
        },
    },
    createAccountCard: {
        '& .MuiPaper-root': {
            padding: '0 !important',
        },
        '& .MuiButtonBase-root': {
            width: '100% !important',
            height: '100% !important',
            display: 'flex',
            flexDirection: 'row !important',
            margin: '0 auto !important'
        }
    },
    accountCardShow: {
        '& .MuiTypography-body1': {
            borderBottom: '1px dotted #949494 !important',
            lineHeight: '12px !important',
            display: 'block !important',
            width: '100% !important',
            padding: '10px 0 !important',
            height: '32px !important',
            marginBottom: '17px !important',
        },
        '& .MuiTypography-caption': {
            position: 'absolute !important',
            bottom: '80px !important'
        }
    },
    activeStatusReset: {
        marginBottom: '0 !important',
        width: '56% !important',
        '& .MuiRadio-colorPrimary.Mui-disabled': {
            color: '#2c3ff3 !important'
        }
    },
}))

export default useStyles;