import React, { useContext} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext } from '../../App';
import StyledSubmitBtn from '../commons/StyledSubmitBtn';
import { DeleteOutlined } from '@material-ui/icons';
import { deletePermissionsAPI } from '../../apis/deletePermissionsAPI';
import { successHandling } from '../../helpers/successHandling';
import useStyles from '../../styles/accessManagementStyle';

const RemovePermissionDialog = (props) => {
    const classes = useStyles()
    const { dispatch } = useContext(SubmitBtnContext);
    const { t } = useTranslation('accessManagement');
    const { onClose, open, permissionId, permissionName } = props;

    const handleClose = () => {
        onClose()
    }

    const confirmRemovePermission = async() => {
        dispatch({type: 'showLoading'});
        const responseStatus = await deletePermissionsAPI(permissionId);
        if(responseStatus){
            const successfulText = 'setting.successfullyRemovePermission'
            successHandling(t, successfulText, 'success')
        }
        onClose()
        dispatch({type: 'stopLoading'});
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('setting.remove')} {permissionName}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t('setting.wouldYouLikeToRemovePermission')}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className={classes.removeBtn}>
                    <StyledSubmitBtn btnColor="primary" variant="contained" btnText={t('setting.removeBtn')} startIcon={<DeleteOutlined />} onClick={confirmRemovePermission} />
                </div>
            <Button onClick={handleClose} color="primary">
                Back
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemovePermissionDialog;