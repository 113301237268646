import localforage from 'localforage';
import * as $ from 'jquery';

export const handleDatatableSelectWithRadio = (setSelectWay) => {
    setSelectWay('select');
    handleStoreDataToLocal('dtDeviceIds', 'value');
}

export const handleStoreDataToLocal = async(localforageKeyName, idOrValue) => {
    
    let arr = await localforage.getItem(localforageKeyName) ? await localforage.getItem(localforageKeyName) : [];
    
    $('.mdl-data-table tr td input').each( function() {
        let target = idOrValue === 'id' ? $(this).attr('id'): $(this).val();
        $(this).prop('checked') ? addDeviceToArr(arr, target) : removeDeviceFromArr(arr, target);
    })
    
    await localforage.setItem(localforageKeyName, arr);
}

const addDeviceToArr = (arr, value) => {
    if(!arr.includes(value)) arr.push(value);
}

const removeDeviceFromArr = (arr, value) => {
    if(arr.includes(value)) arr.splice(arr.indexOf(value), 1);
}