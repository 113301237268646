import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getUnsubscribeCompanyAPI } from '../../apis/getUnsubscribeCompanyAPI';
import _ from 'lodash';

const CompanySelectorForAddingSubscription =props => {
    const { serviceValue, serviceId, setSelectedCompany, noDataText } = props;
    const [ page, setPage ] = useState(1);
    const [ options, setOptions ] = useState([]);
    const record = 20;

    const addOptions = () => {
        setTimeout(() => {
          setPage(page+1);
        }, 500);
      }


    const handleChange = async(e, {value}) => {

        // Prevents React from resetting its properties:
        // ref: https://reactjs.org/docs/legacy-event-pooling.html
        e.persist();

        if(e){
          const ifResetValue = e.target.className.includes('clear');
          setSelectedCompany(ifResetValue ? false : {value: value, name: e.target.innerText});
        }

    }

    const handleScroll = () => {
      
      let element = document.querySelector('.visible.menu.transition');
      
      if(element){
        
        const scrollTop = element.scrollTop;
        const elementHeight = element.clientHeight;
        const heightOfList = element.scrollHeight;
        const ifReachListBottom = heightOfList - Math.abs(scrollTop) === elementHeight;
        
        if (ifReachListBottom) {
          addOptions();
        }
      }
    }

    const getOptions = async(p = page) => {
      const response = await getUnsubscribeCompanyAPI(serviceId, p, record, 'name', 'asc');
      const companies = response.companies;
      if (companies && companies.length > 0) {
        const allOptions = _.reject(options.concat(companies), ['id', 'loading']);
        setOptions([...allOptions, response.should_scroll ? { id: 'loading', name: 'Loading...', disabled: true } : {}]);
      }
    }

    useEffect(() => {
      if(serviceValue === 'nubis_drive') getOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, serviceValue])
   
    return(
        <Dropdown
            placeholder={noDataText || ''}
            clearable
            fluid
            search
            selection
            lazyLoad
            disabled={!serviceValue}
            onChange={handleChange}
            onScroll={handleScroll}
            options={options.map((o, i) => ({
                key: i,
                value: o.id,
                text: o.name,
                disabled: o.disabled
            }))}
            />
    )
}

export default CompanySelectorForAddingSubscription;