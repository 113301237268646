import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        flexDirection: 'column',
        '& .MuiTypography-root': {
            marginTop: '10px !important'
        }
    }
}));

export default useStyles;