import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
        margin: '0 auto',
        height: '100vh',
        flexDirection: 'column',
    },
    errorText: {
        marginTop: -10,
        padding: '5px 0',
        color: 'red',
        fontSize: '12px',
        textAlign: 'left',
        width: '100%',
    },
    paper: {
        padding: theme.spacing(6),
        textAlign: 'center',
    },
    logo: {
        display: 'block',
        margin: '0 auto 20px'
    },
    loginText: {
        marginBottom: theme.spacing(3)
    },
    passwordWrap: {
        position: 'relative',
        '& .MuiIconButton-root': {
            position: 'absolute',
            right: 5,
            top: 5,
            zIndex: 9,
        },
    },
    input: {
        width: '100%',
        '& label': {
            zIndex: 3,
        },
        '& fieldset': {
            zIndex: 3,
        }
    },
    linkText: {
        textAlign: 'center',
        fontSize: 14,
        margin: '30px auto 0',
        display: 'block',
        padding: 5,
        color: '#aaa !important',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    downloadWrap: {
        margin: '16px !important',
        marginTop: '-16px !important',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '110%',
        '& .MuiButton-text': {
            padding: '6px 8px !important',
        },
        '& .MuiButton-label': {
            padding: '0 !important'
        }
    },
    logoSmall: {
        width: 60,
        display: 'block',
        margin: '0 auto 20px'
    },
    forgotPwdTitle: {
        marginBottom: theme.spacing(2)
    },
    forgotPwdText: {
        marginBottom: theme.spacing(3),
        fontSize: 13,
    },
    loginAgainText: {
        marginBottom: theme.spacing(3),
    },
    loginAgainTitle: {
        marginBottom: theme.spacing(3),
        fontSize: 13,
    },
    paperInModal: {
        width: 400,
        padding: theme.spacing(6),
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        marginLeft: -200,
        top: '50%',
        marginTop: -223,
        '& h5': {
            marginBottom: theme.spacing(4)
        },
    },
    backBtn: {
        marginTop: theme.spacing(3),
        color: '#aaa',
        '&:hover': {
            background: 'none !important'
        }    
    }
}))

export default useStyles;