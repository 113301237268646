import React, { useState, useEffect } from 'react';
import { Select, Input, MenuItem, Checkbox, Chip, FormControlLabel } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';
import { MenuPropsForTableFilter } from '../../helpers/menuProps';
import { handleItemsSelected } from '../../helpers/handleMultipleSelection';
import { Scrollbars } from 'react-custom-scrollbars';
import { DefaultMenuItem } from '../commons/StyledTexts';

const MultipleSelector = props => {

    const classes = useStyles();
    const { groups, setGroups, 
              selectedGroups, setSelectedGroups, setSelectedGroupIds, 
              defaultText, noDataText, hasNoData, 
              isRenewal, height,
            } = props;
    const [ showDefaultText, setShowDefaultText ] = useState(true);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            position: 'relative',
            top: '17px',
            height: '3px',
            borderRadius: '1px'
        };
        return (
            <div
                className="track"
                style={{...trackStyle }}
                {...props}/>
        );
    }

    useEffect(() => {
        setShowDefaultText( !hasNoData && selectedGroups.length < 1 );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, hasNoData])

    return (
        <div className={`
            outlinedSelector 
            ${isRenewal && showDefaultText ? "primary-shadow" : ''}
        `}>
            <DefaultMenuItem text={defaultText} ifShow={showDefaultText} />
            <DefaultMenuItem text={noDataText} ifShow={hasNoData} />
            <Select
                labelId="mutiple-chip-label"
                id="mutiple-chip"
                multiple
                value={selectedGroups} 
                disabled={!!hasNoData}
                input={<Input fullWidth={true} variant="outlined" />}
                renderValue={array => {
                    return (
                        <Scrollbars
                            thumbMinSize={10}
                            style={{ height: height }}
                            renderTrackHorizontal={renderTrack}
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                        >
                            {array.map( o => <Chip key={o.id} label={o.name} className={classes.chip} /> )}
                        </Scrollbars>
                    )
                }}
                MenuProps={MenuPropsForTableFilter}
            >
                {!hasNoData &&
                    groups.map((o, i) => {
                        return (
                            <MenuItem key={i}>
                                <FormControlLabel 
                                    className={classes.fullWidthLabel}
                                    control={
                                        <Checkbox 
                                            checked={o.checked} 
                                            id={o.id}
                                            onChange={(e)=>handleItemsSelected(e, groups, selectedGroups, setGroups, setSelectedGroups, setSelectedGroupIds)}
                                            name={o.name} 
                                        />
                                    }
                                    label={o.name}
                                />
                            </MenuItem>
                            )
                        })
                }
            </Select>
        </div>
    )
}

export default MultipleSelector;