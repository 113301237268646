import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const newPwdSendAction = async (email) => {
    const enteredEmail = email.email;

    try {
        await axios({
            url: `${url}/api/v1/member/forgot-password`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            data: {
                email: enteredEmail,
            }
        })
        return true
    } catch (error) {
        errorHandling(error, false);
    }

}