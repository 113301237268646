import {  format, startOfDay, endOfDay } from 'date-fns';

export const getUTCDate = (dateString = Date.now()) => {
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
    return format(new Date(utcDate), 'yyyy-MM-dd HH:mm:ss');
};

export const getEndOfDayInUTC = (dateString = Date.now()) => {
    const date = endOfDay(new Date(dateString));
    const dateInUTC = getUTCDate(date);
     return dateInUTC;
}

export const getStartOfDayInUTC = (dateString = Date.now()) => {
  const date = startOfDay(new Date(dateString));
  const dateInUTC = getUTCDate(date);
  return dateInUTC;
}