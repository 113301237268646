import React, { useContext } from 'react';
import { SubmitBtnContext } from '../../App';
import { AuthContext } from '../../auths/Auth';
import StyledSubmitBtn from '../commons/StyledSubmitBtn';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import { loginValuesSchema, loginInitialValues } from '../../config/formValueSchema';
import { PasswordTextField, EmailTextField } from '../commons/StyledTextFields';

const LoginForm = () => {
    const { t } = useTranslation('login');
    const { dispatch } = useContext(SubmitBtnContext);
    const { login } = useContext(AuthContext);
    
    return(
        <Formik
            initialValues={loginInitialValues}
            validationSchema={loginValuesSchema(t)}
            validateOnChange={false}
            onSubmit={async(values) => {
                dispatch({type: 'showLoading'});
                await login(values);
                dispatch({type: 'stopLoading'});
            }}
        >
        {({
            values, errors, handleChange, handleSubmit,
        }) => (
            <Form onSubmit={handleSubmit}>  
                <EmailTextField 
                    width="300px"
                    className={errors.email ? 'mb_16 error-text-field' : 'mb_16' }
                    label={t('login.emailInputLabel')} 
                    name='email'
                    onChange={handleChange} 
                    value={values.email} 
                    helperText={errors.email}
                />
                <PasswordTextField 
                    width="300px"
                    className={ errors.password ? 'mb_16 error-text-field' : 'mb_16' }
                    label={t('login.pwdInputLabel')} 
                    name='password'
                    onChange={handleChange} 
                    value={values.password} 
                    helperText={errors.password}
                />
                <StyledSubmitBtn 
                    btnColor="primary" 
                    variant="contained" 
                    btnText={t('login.loginBtn')} 
                />
            </Form>
            )}
        </Formik>
    )
}

export default LoginForm;