import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";  
import "../../node_modules/noty/lib/themes/relax.css"; 

export const successHandling = (t, successfulText, type, afterShow ) => {
    new Noty({
        type: type,
        theme: 'relax',
        layout: 'topRight',
        closeWith: ['click', 'button'],
        timeout: process.env.REACT_APP_NOTY_TIMEOUT,
        text: `${t(successfulText)}`,
        callbacks: {
            afterShow: afterShow,
        }
    }).show();
}