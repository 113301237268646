import React, { useEffect, useState } from 'react';
import defaultImg from '../../assets/images/default_image.svg'
import { Typography, Button } from '@material-ui/core';
import useStyles from '../../styles/profileCardStyle';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';

const ProfileCard = () => {

    const classes = useStyles();
    const { t } = useTranslation('accountCenter'); 
    // eslint-disable-next-line no-unused-vars
    const [ userData, setUserData ] = useState('')
    const [ userCompanyName, setUserCompanyName ] = useState('')
    const [ userCompanyContactPerson, setUserCompanyContactPerson ] = useState('')
    const [ userCompanyEmail, setUserCompanyEmail ] = useState('')
    const [ userCompanyID, setUserCompanyID ] = useState('')
    const [ userCompanyImg, setUserCompanyImg ] = useState(defaultImg)
    
    const getCompanyProfile = async() => {
        const userData = await localforage.getItem('user');
        const userCompanyName = await localforage.getItem('userCompanyName');
        const userCompanyContactPerson = await localforage.getItem('userCompanyContactPerson');
        const userCompanyEmail = await localforage.getItem('userCompanyEmail');
        const userCompanyID = await localforage.getItem('userCompanyID');
        const userCompanyImgLink = await localforage.getItem('userCompanyImgLink');
        setUserData(userData)
        setUserCompanyName(userCompanyName)
        setUserCompanyID(userCompanyID)
        setUserCompanyContactPerson(userCompanyContactPerson)
        setUserCompanyEmail(userCompanyEmail)
        if(userCompanyImgLink){
            setUserCompanyImg(userCompanyImgLink)
            return
        } 
    }
        
    useEffect(()=>{
        getCompanyProfile()
    },[])
    
    return(
        <div className={classes.root}>
            <div className={classes.profileCardImgWrap}>
                <img src={userCompanyImg} alt={userCompanyName} />
            </div>
            <Typography variant="h6">{userCompanyName}</Typography>
            <Typography variant="body2" color="secondary">{t('profileCard.contactInfoTitle')}</Typography>
            <Typography variant="body2" className={classes.contactName}>{userCompanyContactPerson}</Typography>
            <Typography variant="body2" className={classes.contactMail}>{userCompanyEmail}</Typography>
            <NavLink to={`/company-profile/${userCompanyID}`}><Button variant="outlined">{t('profileCard.viewCompanyProfile')}</Button></NavLink>
        </div>
    )
}

export default ProfileCard;