import React from 'react';
import { NavLink } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Typography } from '@material-ui/core';
import useStyles from '../../styles/backtoPrevPageStyle.js';

const BacktoPrevPage = (props) => {
    const classes = useStyles();

    return(
        <NavLink to={props.linkTo} className={classes.backtoPrevPage}>
            <ArrowBackIosIcon /><Typography variant="button">{props.btnText}</Typography>
        </NavLink>
    )
}

export default BacktoPrevPage;