import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 3,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: '#2c3ff3 !important',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#fff',
          borderColor: '#2c3ff3 !important',
        },
      },
      '&.MuiSwitch-colorSecondary.Mui-disabled': {
        color: '#bdbdbd !important',
        '& + $track': {
          borderColor: '#bdbdbd !important',
        },
      }
    },
    thumb: {
      width: 10,
      height: 10,
      boxShadow: 'none',
    },
    track: {
      border: `2px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

export default AntSwitch;