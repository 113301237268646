import React, { useState } from "react";
import { Box, IconButton, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useStyles from "../../styles/loginStyle";

export const PasswordTextField = props => {
  const classes = useStyles();
  const { width, label, name, onChange, values, helperText, className } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box className="password-input-wrap" width={width} mb={2}>
      <TextField
        className={`${className} ${classes.input} mb_16`}
        fullWidth
        variant="outlined"
        label={label}
        name={name}
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        value={values}
        helperText={helperText}
      />
      <IconButton onClick={handleClickShowPassword}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </Box>
  );
};

export const EmailTextField = props => {
  const classes = useStyles();
  const { width, label, name, onChange, values, helperText, className } = props;

  return (
    <Box className="password-input-wrap" width={width} mb={2}>
      <TextField
        className={`${className} ${classes.input} mb_16`}
        fullWidth
        variant="outlined"
        label={label}
        name={name}
        onChange={onChange}
        value={values}
        helperText={helperText}
      />
    </Box>
  );
};

export const FullWidthOutlinedTextField = props => {
  const { label, value, onChange } = props;
  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      fullWidth={true}
      onChange={onChange}
      {...props}
    />
  );
};
