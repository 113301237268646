import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import * as localforage from 'localforage';

export const getNotificationListAPI = async (take) => {
    const token = await localforage.getItem('token');
    try {
        let response = await axios({
            url: `${url}/api/v1/notifications`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token
            },
            params: {
                take: take
            }
        });
        return response.data.notifications;
    } catch (error) {
        errorHandling(error, true);
    }
}