import React from 'react';
import ReactDOM from 'react-dom';
import { url } from '../apis/apiSetup';
import { Grid, Typography, Box } from '@material-ui/core';
import { searchStyleRender } from './datatableSearchIconRender';
import { language } from './datatableSetting';
import { format } from 'date-fns';
import * as localforage from 'localforage';
import * as $ from 'jquery';
import '../css/dataTable.css';
import 'datatables.net';
import ServiceIconAndName from '../components/subscribeServices/ServiceIconAndName';
import StatusChip from '../components/commons/StatusChip';
import SubscriptionActionButtons from '../components/subscribeServices/SubscriptionActionButtons';

export const table = async (t, showDevices, showSubscriptionDetail, showUsers, companyIds, valueAddedServiceIds, filterByPastDay) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('userCompanyID');
    $.fn.dataTable.ext.errMode = 'throw'; 

    $('#subscriptions-list-table').DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        filter: true,
        deferRender: true,
        info: true,
        paging: true,
        pageLength:15,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        order: [[ 0, 'desc' ]],
        ajax: {
            url: `${url}/api/v1/subscribe/datatable`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                company_id: companyId,
                company_ids: companyIds,
                value_added_service_ids: valueAddedServiceIds,
                filter_by_past_day: filterByPastDay ? filterByPastDay : undefined
            },
            beforeSend: (request) => {
                request.setRequestHeader('Authorization', 'Bearer ' + token );
                request.setRequestHeader('Accept', 'application/json');
            },
            complete: async() => {
                $('.mdl-data-table input[type=checkbox]').each(function(){
                    $(this).prop('checked', false);
                })
            }
        },
        columns: [
            {
                data: 'order_id', 
                name: 'order_id',
                className: 'subscription-column',
                searchable: true, 
                title: `${t('table.subscription')}`,
                createdCell: (td, data, rowData)=> {
                    const serviceName = rowData.value_added_service_name.toLowerCase();
                    const subscriptionId = rowData.id;
                    const planContent = rowData.plan_content?.capacity;
                    ReactDOM.render (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <ServiceIconAndName serviceName={serviceName} planContent={planContent} />
                            </Grid>
                            <Grid item xs={12} className="subscription-no">
                                <Box component="a" onClick={()=>showSubscriptionDetail(rowData)}>{`No. ${data}`}</Box>
                            </Grid>
                            <Grid item xs={12} className="subscription-status">
                                <StatusChip data={rowData.status} />
                                <SubscriptionActionButtons 
                                    isRenewal={rowData.is_renewal} 
                                    data={rowData.status} 
                                    subscriptionInfo={rowData} 
                                    subscriptionId={subscriptionId} 
                                    showDevices={showDevices} 
                                    showSubscriptionDetail={showSubscriptionDetail} 
                                />
                            </Grid>
                        </Grid>
                    </>
                    , td);
                }
            },
            {   data: 'devices', 
                name: 'devices.serial_number', 
                searchable: true,
                orderable: false,
                className: 'devices-column',      
                title: `${t('table.deviceS/N')}`,
                createdCell: (td, data, rowData)=> {
                    if(data.length === 0) ReactDOM.render ('-' , td);
                    if(data.length <= 3 && data.length > 0) {
                        const devices = data.map(o => o.serial_number).join(', ');
                        ReactDOM.render (
                            <Typography variant="caption">{devices}</Typography>
                        , td);
                    }
                    if(data.length > 3) {
                        const devices = data.filter((o,i) => i < 3).map(o => o.serial_number).join(', ');
                        const subscriptionId = rowData.id;
                        ReactDOM.render (
                            <>
                                {devices}
                                <Typography variant="caption" className="more" onClick={() => showDevices(subscriptionId)}>
                                    more
                                </Typography>
                            </>
                        , td);
                    }
                }
            },
            {   data: 'started_at', 
                name: 'started_at',
                className: 'date-column',
                searchable: true,
                title: `${t('table.startDate')}`, 
                render: data => {
                    return data ? format(new Date(data),'yyyy-MM-dd') : '-'; 
                },
            },
            {   data: 'ended_at',
                name: 'ended_at',
                className: 'date-column',
                searchable: true,
                title: `${t('table.endDate')}`, 
                render: data => {
                    return data ? format(new Date(data),'yyyy-MM-dd') : '-'; 
                 },
            },
            {   data: 'users',
                name: 'users',
                className: 'users-column',
                searchable: true,
                title: `${t('table.users')}`,
                createdCell: (td, data)=> {
                    if(data.length === 0) ReactDOM.render ('-' , td);
                    if(data.length <= 2 && data.length > 0) {
                        const users = data.map(o => o.name).join(', ');
                        ReactDOM.render (
                            <Typography variant="caption">{users}</Typography>
                        , td);
                    }
                    if(data.length > 2) {
                        const users = data.filter((o,i) => i < 2).map(o => o.name).join(', ');
                        const allUsers = data.map(o => o.name);
                        ReactDOM.render (
                            <>
                                {users}
                                <Typography variant="caption" className="more" onClick={() => showUsers(allUsers)}>
                                    more
                                </Typography>
                            </>
                        , td);
                    }
                }
            },
            {   data: 'company_name',
                name: 'company_name',
                className: 'company-name',
                searchable: true,
                title: `${t('table.company')}`, 
                render: data => {
                    return data ? data : '';
                }
            },
            {   data: 'note',
                name: 'note',
                searchable: true,
                title: `${t('table.note')}`, 
                render: data => {
                    return data ? data : '';
                }
            },
            {   data: 'value_added_service_name',
                name: 'value_added_service_name',
                searchable: true,
                visible: false,
            },
        ]
    })
    // render Material UI Search Input
    searchStyleRender();
}
