/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { SubmitBtnContext } from '../../App';
import { AccountContext } from './AccountCard';
import { CompanyAccountContext } from '../../containers/CompanyProfile';
import { Grid, Paper, ButtonGroup, Typography, Button, TextField, FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import { Formik } from 'formik';
import useStyles from '../../styles/companyProfileFormstyle';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { useParams } from 'react-router-dom';
import { updateAccountAPI } from '../../apis/updateAccountAPI';
import StyledSubmitBtn from '../../components/commons/StyledSubmitBtn';
import Noty from 'noty';
import "../../../node_modules/noty/lib/noty.css";  
import "../../../node_modules/noty/lib/themes/relax.css";  
import defaultImage from '../../assets/images/default_image.svg';
import '../../css/avatarEditor.css';
import { roleSelectorDeterminator } from '../../helpers/roleSelectorDeterminator';
import { updateAccountCardFormValueSchema } from '../../config/formSchema';

const AccountCardForm = (props) => {
    const classes = useStyles();
    const { company_id } = useParams();
    const { dispatch } = useContext(SubmitBtnContext);
    const { setAccountUpdate } = useContext(CompanyAccountContext);
    const { handleCancelEditAccount } = useContext(AccountContext);
    const { t } = useTranslation('accountCenter');
    const [ activeStatus, setActiveStatus ] = useState(props.activeStatus || '');
    const [ name, setName ] = useState(props.name || '');
    const [ phone, setPhone ] = useState(props.phone || '');
    const [ email, setEmail ] = useState(props.email || '');
    const [ role, setRole ] = useState(props.role || '');
    const [ password, setPassword ] = useState(props.password || '');
    const [ initAvatar, setInitAvatar ] = useState(props.avatarLink || defaultImage);
    const [ isFormEditable, setIsFormEditable ] = useState(true);
    const [ isFileSizeError, setIsFileSizeError ] = useState(false)
    const [ isFileExtensionError, setIsFileExtensionError ] = useState(false)
    const [ accountID, setAccountID ] = useState(props.id || '')
    const [ roleArr, setRoleArr ] = useState([])
    const [ userHasPermissionToAdmin, setUserHasPermissionToAdmin] = useState(false)
    const roleNeedPermission = 'Administrator'

    const getRoleOptions = async() => {
        const userPermissions = await localforage.getItem('userPermissions')
        const roles = await roleSelectorDeterminator()
        setRoleArr(roles)
        setUserHasPermissionToAdmin(userPermissions.includes(roleNeedPermission))
    }

    const inputCursorStyle = !isFormEditable ?  {} : {cursor: 'pointer'}

    const initialValues = {
        name: name,
        phone: phone,
        email: email,
        role: role,
        password: password,
        active: activeStatus
    }

    useEffect(()=>{
        getRoleOptions()
    },[])

    return (
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className={classes.accountCardWrap}>
            <Paper elevation={3} className={classes.paper}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={updateAccountCardFormValueSchema}
                    onSubmit={async(values, {setSubmitting}) => {
                        setSubmitting(true)
                        const token = await localforage.getItem('token');
                        if(isFileExtensionError === false && isFileSizeError === false){
                            dispatch({type: 'showLoading'});
                            let responseStatus = await updateAccountAPI(token, accountID, company_id, values, activeStatus, initAvatar, defaultImage)
                            if (responseStatus === true) {
                                handleCancelEditAccount()
                                setAccountUpdate(true)
                                new Noty({
                                    type: 'success',
                                    theme: 'relax',
                                    layout: 'topRight',
                                    closeWith: ['click', 'button'],
                                    timeout: process.env.REACT_APP_NOTY_TIMEOUT,
                                    text: `${t('companyProfileForm.successfullyUpdateCompany')}`
                                }).show();
                            } 
                            dispatch({type: 'stopLoading'});
                        }
                    }}
                >
                    {({
                        values, errors, touched, handleChange, handleBlur, handleSubmit,
                        isSubmitting, setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} className={classes.mainContent}>
                            <div className={classes.avatarUploaderWrap}>
                                <div className="avatar-editor">
                                    <input type='file' 
                                        onChange={(event) => {
                                            setIsFileSizeError(false)
                                            if (event.target.files && event.target.files[0]) {
                                                const src = URL.createObjectURL(event.target.files[0]); // set src to blob url
                                                const fileName =  event.target.files[0].name; 
                                                const fileSize = event.target.files[0].size / 1024 / 1024;
                                                if( fileSize > 0.5) {
                                                    setIsFileSizeError(true)
                                                    return
                                                } else {
                                                    setIsFileSizeError(false)
                                                }
                                                setFieldValue("file", event.currentTarget.files);
                                                setInitAvatar(src)
                                            }
                                        }}
                                        disabled={!isFormEditable} 
                                        style={inputCursorStyle} 
                                    />
                                    <div className="avatar-image">
                                        <img src={initAvatar} alt="avatar" />
                                    </div>
                                </div>
                                <div>
                                    <Typography variant="caption" color="secondary">
                                    {t('companyProfileForm.imageSize')}
                                    </Typography>
                                    <Typography variant="caption" color="secondary">
                                        {t('companyProfileForm.imageFormat')}
                                    </Typography>
                                    { isFileSizeError ? 
                                        <Typography variant="caption" color="error">Please upload file within 500 KB.</Typography>
                                        :
                                        ''
                                    }
                                </div>
                            </div>    
                            <TextField
                                className={classes.nameTexField}
                                variant="standard"
                                placeholder="name *"
                                name="name"
                                disabled={isSubmitting}
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.name && touched.name && errors.name}
                                error={!!(errors.name && touched.name && errors.name)}
                            />
                            <TextField
                                placeholder="Phone *"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                helperText={errors.phone && touched.phone && errors.phone}
                                error={!!(errors.phone && touched.phone && errors.phone)}
                            />
                            <TextField
                                placeholder="Email *"
                                name="email"
                                disabled={isSubmitting}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.email && touched.email && errors.email}
                                error={!!(errors.email && touched.email && errors.email)}
                            />
                            <TextField
                                select
                                SelectProps={{
                                    native: true,
                                }}
                                name="role"
                                value={values.role}
                                onChange={handleChange}
                                className={clsx({
                                    [classes.roleSelection] : !values.role,
                                    [classes.roleSelected] : values.role
                                })}
                                onBlur={handleBlur}
                                disabled={isSubmitting || (!userHasPermissionToAdmin && values.role === roleNeedPermission)}
                                helperText={errors.role && touched.role && errors.role}
                                error={!!(errors.role && touched.role && errors.role)}
                            >
                                <option value={null}>Select a role *</option>
                                {values.role === roleNeedPermission ? 
                                    <option value={values.role}>{values.role}</option>
                                :
                                    ''
                                }
                                {roleArr.map((role, i) => {
                                    return (<option key={i} value={role}>{role}</option>)
                                })}
                            </TextField>
                            {/* rick - comment out, in case Elio want it back..... */}
                            {/* <div className={classes.passwordWrap}>
                                <TextField
                                    placeholder="Change Password"
                                    name="password"
                                    disabled={isSubmitting}
                                    type={passwordValues.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={errors.password && touched.password && errors.password}
                                    error={!!(errors.password && touched.password && errors.password)}
                                />
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {passwordValues.showPassword ? <Visibility /> : <VisibilityOff />}                              
                                </IconButton>
                            </div> */}
                            <FormControl className={`${classes.activeStatus} ${classes.activeStatusEditable}`}>
                                <FormLabel>Active</FormLabel>
                                <RadioGroup row aria-label="active" name="active"
                                    value={values.active}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    >
                                    <FormControlLabel control={<Radio 
                                            color="primary" 
                                            onClick={()=>{setActiveStatus(!activeStatus)}}
                                            checked={!!activeStatus}
                                            disabled={isSubmitting}
                                        />}
                                        value={activeStatus}
                                    />
                                </RadioGroup>
                                <FormHelperText
                                    hidden={!(errors.active && touched.active && errors.active)}
                                    error={!!(errors.active && touched.active && errors.active)}
                                >
                                    {errors.active && touched.active && errors.active}
                                </FormHelperText>
                            </FormControl>
                            <ButtonGroup variant="text" className={classes.buttonGroup}>
                                <StyledSubmitBtn btnColor="secondary" variant="text" startIcon={<SendOutlinedIcon color="primary" />}  disabled={isSubmitting}
                                    btnText={t('companyProfileForm.confirmBtn')} 
                                />
                                <Button type="button" color="secondary" onClick={props.handleCancelCreateAccount}  disabled={isSubmitting}>
                                    {t('companyProfileForm.cancelBtn')}
                                </Button>
                            </ButtonGroup>
                        </form>
                    )}
                </Formik>
            </Paper>
        </Grid>
    )
}

export default AccountCardForm;