import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
        '& h6': {
            marginBottom: theme.spacing(1),
            fontSize: 18
        },
        '& p': {
            fontSize: 13,
            lineHeight: 1.8
        },
        '& a': {
            marginTop: theme.spacing(2)
        }
    },
    profileCardImgWrap: {
        height: 70,
        width: 70,
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginBottom: theme.spacing(2),
        '& img': {
            width: '100%'
        },
    }
}))

export default useStyles;