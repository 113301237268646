import * as localforage from 'localforage';

export const getObjArrFromAPIOrLocal = async(localforageKeyName, getObjAPI, idOrValue, shouldGenerateOnlyNamesAndIdsOrValues = true) => {
    
    let objArr;

    if (localforageKeyName === 'valueAddedServices') {
        objArr = await getObjAPI()
    } else {
        objArr = await localforage.getItem(localforageKeyName);
        if (!objArr) {
            await getObjAPI();
            objArr = await localforage.getItem(localforageKeyName);
        } 
    }
    if (!shouldGenerateOnlyNamesAndIdsOrValues) {
        return objArr;
    } else {
        let objArrWithNamesAndIds = await formObjArrWithNamesAndIdsOrValues(objArr, idOrValue);
        return objArrWithNamesAndIds;
    }
}

export const formObjArrWithNamesAndIdsOrValues = (objArr, idOrValue) => {
    
    const objArrWithNamesAndIds = objArr.map( o => {
        if (idOrValue === 'id') return ({ name: o.name, id: o.id }); // usually for multiple checkbox selector
        if (idOrValue === 'value') return ({ name: o.name, value: o.id }); // usually for single selectorre
        else return ({})
    })
    return objArrWithNamesAndIds;
}
