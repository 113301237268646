import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const customersAPI = async () => {
  const token = await localforage.getItem("token");
  try {
    let response = await axios({
      url: `${url}/api/v1/company/fec-customers`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token
      }
    });
    let data = response.data.fec_customers;
    await localforage.setItem("customers", data);
    return data;
  } catch (error) {
    errorHandling(error, false);
    return false;
  }
};
