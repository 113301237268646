import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDivider-root': {
            width: '100% !important',
            marginBottom: '0'
        },
        '& .MuiFormControlLabel-root': {
            overflow: 'hidden !important',
        },
        '& .MuiFormControl-root': {
            width: '100% !important',
        },
        '& .MuiPaper-rounded': {
            padding: '18px 32px 8px !important'
        },
        '& .fullwidth-table-wrapper': {
            padding: '16px 0 !important'
        },
        '& .MuiButton-textSecondary': {
            color: 'rgba(0, 0, 0, 0.65) !important',
        },
        '& .MuiSvgIcon-colorPrimary': {
            color: '#2c3ff3 !important'
        },
        '& .btn-no-permission': {
            display: 'none !important',
        }, 
    },
    flex: {
      display: 'flex !important'
    },
    delegationForm: {
      '& .MuiTypography-colorTextSecondary': {
        color: 'rgba(0, 0, 0, 0.2) !important'
      },
      '& .MuiInputBase-root.Mui-disabled': {
        opacity: '0.4'
      }
    },
    multipleSelect: {
      margin: '16px 0 !important',
      width: '100%',
      borderRadius: '4px !important',
      border: '1px solid #ddd !important',
      '& .MuiInput-underline:before': {
          border: 'none !important'
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingLeft: '8px !important',
      },
      '& .MuiChip-root':{
        backgroundColor: '#e0e0e094 !important'
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    uploadFileWrap: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: '40px !important',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'baseline',
      }
    },
    deviceExcelFileUploadMainInput: {
      cursor: 'pointer',
      width: '350px',
      height: '40px',
      position: 'relative',
      '& .MuiButton-outlined': {
        width: '100% !important',
        padding: '0 !important',
        height: '100% !important',
        position: 'absolute !important',
        lineHeight: '33px !important',
        color: '#888',
        fontSize: '12px !important'
      },
      '& .MuiTypography-colorSecondary': {
        position: 'absolute !important',
        top: '10px !important',
        left: '20px !important'
      },
      '& input': {
        width: '100% !important',
        height: '40px !important',
        margin: '0 !important',
        '&::before': {
          width: '100% !important',
          height: '40px !important',
          cursor: 'pointer',
          position: 'absolute'
        }
      },
      '& .MuiTypography-colorError': {
        position: 'absolute !important',
        top: '50px !important'
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '32px'
      },
    },
    uploadBtn: {
      position: 'absolute !important',
      width: '100px',
      height: '100%',
      background: '#ddd',
      textAlign: 'center',
      lineHeight: '40px !important',
      right: '0'
    },
    downloadTemplate: {
      width: 'auto !important',
      marginTop: '0 !important',
      marginLeft: '24px !important',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0 !important',
      },
    },
    divider: {
      marginBottom: '20px !important',
    },
    formControlBtn: {
      display: 'flex !important',
      flexDirection: 'row',
      '& .MuiButton-textPrimary': {
        height: '40px !important',
        width: 'auto !important',
        marginRight: '10px !important',
        background: '#2c3ff3 !important',
        color: '#fff !important',
        '&:hover': {
          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important'
        }
      },
      '& .MuiButton-containedSecondary': {
        marginTop: '0 !important',
        '&:hover': {
          color: '#fff !important'
        }
      }
    },  
    iconButtonGroup: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      width: 'calc( 100% - 20px )',
      '& button':{
        marginRight: '5px !important',
        zIndex: 20
      }
    },
    editGroupDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: '60% !important',
        maxWidth: '700px !important'
      },
      '& .MuiDialogContent-root': {
        overflow: 'hidden'
      },  
      '& .MuiPaper-rounded': {
        padding: '10px 20px 30px !important',
        marginBottom: '0 !important',
      },
      '& .MuiFormControl-root': {
        width: '100% !important',
      },
      '& .fullwidth-table-wrapper': {
        padding: '0 !important',
        '& #edit-groups-table_wrapper tr th': {
          textAlign: 'center !important'
        }
      },
      '& div.dataTables_wrapper': {
        paddingBottom: '30px !important'
      },
      '& .MuiButton-containedPrimary': {
        background: '#2c3ff3 !important',
        width: '100%',
        maxWidth: '300px',
        lineHeight: '2em !important',
        margin: '0 auto !important'
      },
      '& .MuiButton-textSecondary': {
        color: '#656565 !important',
        '&:hover': {
           background: '#9e9e9e14'
        }
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: '#555'
      },
      '& .MuiButton-contained.Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.12) !important'
      }
    },
    editGroupConfirmBtnWrap: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiButton-textPrimary': {
        height: '40px !important',
        // width: 'auto !important',
        marginRight: '10px !important',
        background: '#2c3ff3 !important',
        color: '#fff !important',
        '&:hover': {
          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12) !important'
        }
      }
    },
    dialogUploadFileWrap: {
      marginBottom: '0 !important',
      alignItems: 'center !important'
    },
    dialogDeviceExcelFileUploadMainInput: {
      margin: '0 auto !important'
    },
    importDevicesDialog: {
      '& .MuiButton-textSecondary': {
        margin: '0 auto !important',
        display: 'flex !important',
        color: '#797979 !important',
        '& .MuiSvgIcon-colorPrimary': {
          color: '#2c3ff3 !important'
        }
      },
      '& .MuiButton-containedPrimary': {
        width: '200px !important',
        margin: '0 auto !important',
        background: '#2c3ff3 !important'
      }
    }
}));

export default useStyles;