import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const renamePermissionAPI = async (
  id,
  value,
  storedValue,
  setStoredValue,
  setValue
) => {
  const token = await localforage.getItem("token");

  try {
    await axios({
      url: `${url}/api/v1/permissions/update`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      },
      data: {
        permission_id: id,
        permission_name: value
      }
    });
    setStoredValue(value);
  } catch (error) {
    setValue(storedValue);
    errorHandling(error, false);
  }
};
