import React from 'react';
import { Breadcrumbs, Link, Drawer, Typography } from '@material-ui/core';
import { HomeOutlined} from '@material-ui/icons';
import useStyles from '../styles/subscribeServicesStyle';
import { useTranslation } from "../langs/useTranslation";
import SubscribeDrawerBtns  from '../components/subscribeServices/DrawerBtns';
import SubscriptionList from '../components/subscribeServices/SubscriptionList';

const Subscriptions = () => {
    const classes = useStyles();
    const { t } = useTranslation('subscribeServices');

    return(
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                   <SubscribeDrawerBtns />
                </Drawer>
            </nav>
            <main className={classes.content}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href='/'>
                        <HomeOutlined />
                    </Link>
                    <Typography color="textPrimary">{t('subscriptions.title')}</Typography>
                </Breadcrumbs>
                <div className={classes.titleSection}>
                    <Typography variant="h4">{t('subscriptions.title')}</Typography>
                </div>
                <SubscriptionList />
            </main>
        </div>
    )
}

export default Subscriptions;