import React, { useState, useEffect } from 'react';
import { useTranslation } from "../../langs/useTranslation";
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import SearchFieldAndButton from '../commons/SearchFieldAndButton';
import { ReloadTableIconButton } from '../commons/IconButtons';
import * as $ from 'jquery';
import { table } from '../../helpers/subscriptionDeviceDatatable';

const DeviceTableDialog = props => {
    const { onClose, open, id } = props;
    const { t } = useTranslation('subscribeServices');
    const [ resetSearchField, setResetSearchField ] = useState(true);
    
    const loadTable = () => {
      table(t, id);
      setResetSearchField(true);
    }

    const searchDatatable = searchValue => $('#device-table').DataTable().search(searchValue).draw();

    useEffect(()=>{
      if(open) loadTable();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])
  
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle id="text-with-reload-dialog-title">
          {t('dialog.devices')}
          <ReloadTableIconButton 
            onClick={loadTable} 
          />  
        </DialogTitle>
        <DialogContent className="dialog-fullwidth-table-wrap one-column-table-wrap">
            <SearchFieldAndButton 
              resetSearchField={resetSearchField} 
              setResetSearchField={setResetSearchField} 
              searchDatatableForTable={searchDatatable} 
            />
            <table id="device-table" className="mdl-data-table dataTable">
                <thead>
                    <tr>
                        <th className="devices-column">{t('table.deviceS/N')}</th>
                    </tr>
                </thead>
            </table>
        </DialogContent>
      </Dialog>
    );
  }
  
export default DeviceTableDialog;