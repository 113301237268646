import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import * as localforage from 'localforage';

export const updateNotificationAPI = async (notificationIdsArr) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v1/notification/update`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token
            },
            data: {
                notification_ids: notificationIdsArr
            }
        });
        return true;
    } catch (error) {
        errorHandling(error, true);
    }
}