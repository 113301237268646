import React, { useState } from 'react';
import { Box, Button, Typography }  from '@material-ui/core';
import { removeAccountAPI } from '../../apis/removeAccountAPI';
import { useTranslation } from "../../langs/useTranslation";
import { successHandling } from '../../helpers/successHandling';
import { DeleteOutlined } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

const ConfirmRemoveAccountButton = props => {
    const { t } = useTranslation('accountCenter');
    const { id, name, email, companyID, getCompanyAccount, handleCloseRemoveButton } = props;
    const [ loading, setLoading ] = useState(false);

    const handleRemoveAccount = async(e) => {
        if(!e.detail || e.detail === 1) {
            setLoading(true);
            let result = await removeAccountAPI(id, email);
            if(result){
                const successfulText = 'accountProfile.successfullyRemoveAccount';
                successHandling(t, successfulText, 'success');
                getCompanyAccount(companyID);
            }
        }
    }
    
    return(
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={`calc(100% - 40px)`}>
                 {loading ? 
                    <CircularProgress />
                    :
                    <>
                        <Box mb={2} textAlign="center">
                            <Typography variant="subtitle1" align="center" component="span">
                                {t('accountProfile.areYouSureToRemoveTheAccount')} 
                            </Typography>
                            <Typography variant="subtitle1" component="span" color="primary">{name}</Typography>?
                        </Box>
                        <Box>
                        <Button onClick={handleRemoveAccount} color="secondary" startIcon={<DeleteOutlined />}>
                            {t('accountProfile.remove')} 
                        </Button>
                        <Button onClick={handleCloseRemoveButton} color="secondary">
                            {t('accountProfile.back')} 
                        </Button>
                    </Box>
                    </>
                }
            </Box>
        
    )
}

export default ConfirmRemoveAccountButton;