import React from 'react';
import {  IconButton } from '@material-ui/core';
import { DeleteOutlined, EditOutlined} from '@material-ui/icons';
import HasPermission from '../commons/HasPermission';

const AccountEditAndRemoveBtns = props => {
    const { handleEditAccount, ShowConfirmRemoveAccountButton, isAdmin } = props;

    return(
        <div className="edit-remove-btn-wrap">
            <HasPermission permission="edit_account">
                <IconButton color="secondary" onClick={handleEditAccount}>
                    <EditOutlined color="secondary" />
                </IconButton>
            </HasPermission>
            <HasPermission permission="remove_company_account">
                <IconButton color="secondary" onClick={ShowConfirmRemoveAccountButton} disabled={isAdmin}>
                    <DeleteOutlined color={isAdmin ?  'disabled' : 'secondary'} />
                </IconButton>
            </HasPermission>
        </div>
    )
}

export default AccountEditAndRemoveBtns;