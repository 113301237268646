import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Typography, Paper, IconButton } from '@material-ui/core';
import useStyles from '../../styles/accessManagementStyle';
import { url } from '../../apis/apiSetup'
import '../../css/dataTable.css';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import * as $ from 'jquery';
import 'datatables.net';
import 'datetime-moment';
import { DeleteOutlined, EditOutlined, ReplayOutlined, AddBox } from '@material-ui/icons';
import CreateRoleDialog from './CreateRoleDialog';
import RemoveRoleDialog from './RemoveRoleDialog';
import { language } from "../../helpers/datatableSetting";

const RoleManagementTable = () => {
    const classes = useStyles();
    const { t } = useTranslation('accessManagement');
    const [open, setOpen] = useState(false);
    const [roleId, setRoleId] = useState();
    const [roleName, setRolenName] = useState();

    const handleRemoveRoleDialogClickOpen = (roleId, roleName) => {
        setOpen(true);
        setRoleId(roleId)
        setRolenName(roleName)
    };

    const handleRemoveRoleDialogClose = () => {
        setOpen(false);
        table()
    };


    const table = async() => {
        const token = await localforage.getItem('token')
        $('#role-management-table').DataTable({
            processing: true,
            language: language(t),
            searching: true,
            filter: false,
            deferRender: true,
            info: false,
            autoWidth: false,
            stateSave: false,
            destroy: true,
            pageLength:15,
            ordering: true,
            order: [[ 0, 'asc' ]],
            ajax: {
                url: `${url}/api/v1/roles`,
                type: 'GET',
                dataSrc: 'roles.data',
                data: {
                    page: 1,
                },
                beforeSend: (request) => {
                    request.setRequestHeader('Authorization', 'Bearer ' + token );
                    request.setRequestHeader('Accept', 'application/json');
                },
                error: ()=>{
                    // window.location.replace(process.env.REACT_APP_UNAUTH_REDIRECT_URL);
                },
            },
            columns: [
                {   data: 'name', 
                    name: 'data.name',
                    title: `${t('table.roleTitle')}`,
                    searchable: true,
                    createdCell:  (td, data) => {
                        ReactDOM.render(<Typography variant="body1">{data}</Typography>,td)
                    } 
                },
                {   data: 'id', 
                    name: 'data.id',
                    title: `${t('table.actionTitle')}`,
                    searchable: true,
                    createdCell:  (td, data, rowData) => {
                        ReactDOM.render(
                        <div>
                            <Button href={`/permission-management/${rowData.name}/${data}`} startIcon={<EditOutlined />}>{t('table.editPermission')}</Button>
                            <Button onClick={()=>handleRemoveRoleDialogClickOpen(rowData.id, rowData.name)} startIcon={<DeleteOutlined />}>{t('table.removeRole')}</Button>
                        </div>
                        , td)
                    }, 
                },
                {   data: 'created_at',
                    visible: false,
                    searchable: false,
                },
            ],
        })
    }

    //Create Role Props and state
    const [ createRoleDialogOpen, setCreateRoleDialogOpen] = useState(false);
    const handleCreateRoleDialogClickOpen = () => {
        setCreateRoleDialogOpen(true);
    };
    const handleCreateRoleDialogClose = () => {
        setCreateRoleDialogOpen(false);
        table()
    };

    const handleReloadDataTable = () => {
        table()
    }

    useEffect(()=>{
        table()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Paper className="fullwidth-table-wrapper reset-margin-top-table">
            <div className="table-button-wrap">
                <IconButton aria-label="reload table" className={classes.margin} size="small" onClick={handleReloadDataTable}>
                    <ReplayOutlined />
                </IconButton>
                <IconButton aria-label="reload table" className={classes.margin} size="small" onClick={handleCreateRoleDialogClickOpen}>
                    <AddBox />
                </IconButton>
            </div>
            <CreateRoleDialog open={createRoleDialogOpen} onClose={handleCreateRoleDialogClose} />
            <table id="role-management-table" className="mdl-data-table"></table> 
            <RemoveRoleDialog open={open} onClose={handleRemoveRoleDialogClose} roleId={roleId} roleName={roleName} />
        </Paper>
    )
}

export default RoleManagementTable;