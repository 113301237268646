export const generateRecursiveList = (json = []) => {
  let treeData = [];
  if (json) {
    json.map( (res, index) => {
      if (Array.isArray(res.children) && res.children.length > 0) {
       treeData.push({
          title: res.name,
          key: res.id,
          parent_id: res.parent_company_id,
           children: generateRecursiveList(res.children)
         })
      } else {
       treeData.push({
          title: res.name,
          key: res.id,
          parent_id: res.parent_company_id,
           isLeaf: true, // means the last layer
         })
      }
       return treeData;
     });
  }
  
  
  return treeData;
}
