import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& a': {
            textDecoration: 'none',
            marginTop: 30,
        },
        '& .MuiDivider-root': {
            marginBottom: theme.spacing(4),
        }
    },
    drawerPaper: {
        width: drawerWidth,
        position: 'fixed',
        top: theme.spacing(8),
        zIndex: '1',
    },
    content: {
        flexGrow: 1,
        boxSizing: 'border-box',
        padding: theme.spacing(8),
        marginTop: theme.spacing(5),
        width: `calc( 100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    titleSection: {
        marginBottom: '24px !important',
        marginTop: '32px !important',
        '& h4': {
            marginBottom: '16px !important',
        },
    },
    searchTitle: {
        marginBottom: theme.spacing(2)
    },
    createCompanyBtn: {
        // marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    companyTreeTitle: {
        marginBottom: theme.spacing(2),
    },
    companyTreeContent: {
        //padding: '24px !important',
    },
    treeOuter: {
        maxHeight: "400px",
        minHeight: "400px"
    },
    popupIndicatorOpen: {
        display: 'none'
    },
    popupIndicator: {
        display: 'none'
    },
    searchInput: {
        position: 'relative',
        maxWidth: 600,
        minWidth: 200,
        width: '40vw !important',
        marginBottom: 36,
        '& .MuiFormControl-root': {
            width: '100% !important',
        }
    },
    searchIcon: {
        position: 'absolute',
        top: 15,
        right: 20,
    },
    clearIndicator: {
        right: 46
    }
}))

export default useStyles;