import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { bytesConverter } from '../helpers/transformedFormatBytes';
import { getEndOfDayInUTC, getStartOfDayInUTC } from '../helpers/getUTCDateTime';
import { successHandling } from '../helpers/successHandling';

export const createSubscribeServiceAPI = async(t, setDialogOpen, companyId, companyName, valueAddedServiceId, valueAddedServiceName, users, planContentValue, startedAt, endedAt, deviceIds, spreadsheet) => {
    
    const token = await localforage.getItem('token');
    const userJsons = users.map( o => ({ id: o.id, company_id: o.origin.company_id, name: o.name, email: o.origin.email}));
    const planContentValueInKB = planContentValue && bytesConverter(planContentValue);
    const successfulText ='subscribeNewService.successfullyCreateSubscription';
    let formData = new FormData();

    formData.append('company_id', companyId);
    formData.append('company_name', companyName);
    formData.append('value_added_service_id', valueAddedServiceId);
    formData.append('value_added_service_name', valueAddedServiceName); 
    formData.append('users', JSON.stringify(userJsons)); 
    formData.append('started_at', getStartOfDayInUTC(startedAt));
    formData.append('ended_at', getEndOfDayInUTC(endedAt));
    
    if ( valueAddedServiceName ==='nubis_drive' ) formData.append('plan_content', JSON.stringify({'capacity': `${planContentValueInKB}`})); // in KB unit 
    if ( spreadsheet ) formData.append('spreadsheet', spreadsheet);
    if ( deviceIds ) {
        for ( var i = 0; i < deviceIds.length; i++) {
            formData.append('device_ids[]', deviceIds[i]);
        }
    }

    try {
        let response = await axios({
            url: `${url}/api/v1/subscribe/service-create`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: formData
        })

        successHandling(t, successfulText, 'success');
        setDialogOpen(true);
        return response.data.subscription;

    } catch (error) {
        errorHandling(error, false, true, t);
        return false;
    }
    
}