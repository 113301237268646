import React from 'react';
import { Box, RadioGroup, FormControlLabel, Radio, Grid, Paper, Divider } from '@material-ui/core';
import useStyles from '../../styles/subscribeServicesStyle';
import SelectNubisDriveCapacity from './SelectNubisDriveCapacity';
import { useTranslation } from "../../langs/useTranslation";
import { StepTitle } from '../commons/StyledTexts';

const SelectService = props => {
    const classes = useStyles();
    const { t } = useTranslation('subscribeServices');
    const { services, serviceValue, setServiceValue, setServiceId, 
              setCompanies, setSelectedCompany, setUsers, setSelectedUsers, setSelectedUserIds,
              nubisDriveCapacityValue, setNubisDriveCapacityValue, 
              planContentValue, setPlanContentValue,
              isValueValid, setIsValueValid, isRenewal 
            } = props;

    const handleServiceChange = e => {
        
        setServiceValue(e.target.value);
        setServiceId(e.target.id);

        // Rule: service changed, company selector and user selector changed
        // So reset data once selected service is changed
        setCompanies([]);
        setSelectedCompany(''); 
        setUsers([]);
        setSelectedUsers([]);
        setSelectedUserIds(false);
    }

    return (
        <Grid item xs={12}>
            <StepTitle title='selectAService' /> 
            <RadioGroup 
                aria-label="service" 
                name="service" 
                className={classes.serviceSeletion} 
                value={serviceValue} 
                onChange={handleServiceChange}>
                <Grid container spacing={1}>
                    {!isRenewal && services.map( o => {
                        if ( o.is_enable ){
                            return(
                                <Grid item xs={12} sm={6} md={3} lg={2} key={o.id} >
                                    <FormControlLabel 
                                        value={o.name} 
                                        control={<Radio color="primary" id={o.id} disabled={isRenewal} />} 
                                        label={
                                            <>
                                                {t(`service.${o.name}`)}
                                                <Paper><img src={o.logo} alt={o.name} /></Paper>
                                            </>
                                        }
                                    />
                                </Grid>
                            )
                        }else{
                            return ''
                        }
                    })}

                    {isRenewal && services.map( o => {
                        if ( o.is_enable && o.name === serviceValue ){
                            return(
                                <Grid item xs={12} sm={6} md={3} lg={2} key={o.id} >
                                    <FormControlLabel 
                                        value={o.name} 
                                        control={<Radio color="primary" id={o.id} disabled={isRenewal} />} 
                                        label={
                                            <>
                                                {t(`service.${o.name}`)}
                                                <Paper><img src={o.logo} alt={o.name} /></Paper>
                                            </>
                                        }
                                    />
                                </Grid>
                            )
                        }else{
                            return ''
                        }
                    })}
                </Grid>
            </RadioGroup>
            { serviceValue === 'nubis_drive' && 
                <>
                    <Divider />
                    <Box mb={2} mt={1}>
                        <SelectNubisDriveCapacity
                            isRenewal={isRenewal}
                            isValueValid={isValueValid}
                            setIsValueValid={setIsValueValid}
                            nubisDriveCapacityValue={nubisDriveCapacityValue}
                            setNubisDriveCapacityValue={setNubisDriveCapacityValue}
                            planContentValue={planContentValue}
                            setPlanContentValue={setPlanContentValue}
                        />
                    </Box>
                </>
            }
        </Grid>
    )
}

export default SelectService;