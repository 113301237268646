import React, { useEffect, useState, useContext } from 'react';
import { Dialog, TextField, Grid, Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../../styles/delegationStyle';
import { useTranslation } from "../../langs/useTranslation";
import { useLocation } from 'react-router-dom';
import StyledSubmitBtn from '../../components/commons/StyledSubmitBtn';
import { createCompanyGroupAPI } from '../../apis/createCompanyGroupAPI';
import { SubmitBtnContext } from '../../App';
import { table } from '../../helpers/editGroupDatatable';

const EditGroupDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, handleEditGroupsDialogClickOpen } = props;
    const { t } = useTranslation('devicesGroups');
    const { dispatch } = useContext(SubmitBtnContext);

    const location = useLocation();
    let query = new URLSearchParams(location.search);
    
    const [ newGroupName, setNewGroupName ] = useState('');
    const [ newGroupNameErrorStatus, setNewGroupNameErrorStatus ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ ifEditGroupForNubis, setIfEditGroupForNubis ] = useState(query.get('edit_group'));
  
    const handleClose = () => {
        if(ifEditGroupForNubis){
            window.location.replace(`${process.env.REACT_APP_NUBIS_URL}/device-list?update_group=true`);
        } else {
            onClose();
        }
    };

    const handleNewGroupName = e => setNewGroupName(e.target.value);

    const handleCreateNewGroup = async() => {
        
        dispatch({type: 'showLoading'});
        
        if(newGroupName){
            const responseStatus = await createCompanyGroupAPI(newGroupName);
            if(responseStatus){
                table(t);
                setNewGroupName('');
            }
            setNewGroupNameErrorStatus(false);
        } 
        
        if(!newGroupName) setNewGroupNameErrorStatus(true);
        
        dispatch({type: 'stopLoading'});
    }

    useEffect(()=>{
        table(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(()=>{
        if(ifEditGroupForNubis) handleEditGroupsDialogClickOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ifEditGroupForNubis])
  
    return (
        <Dialog 
            className={classes.editGroupDialog} 
            onClose={ifEditGroupForNubis ? null : onClose} 
            aria-labelledby="edit-groups" 
            open={open}     
        >
            <DialogTitle align="center">{t('setting.editGroup')}</DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={7}>
                        <TextField variant="outlined" label={t('setting.groupName')} value={newGroupName} onChange={handleNewGroupName}></TextField>
                    </Grid>
                    <Grid item xs={5}>
                        <StyledSubmitBtn btnColor="primary" variant="contained" btnText={t('setting.createGroup')} onClick={handleCreateNewGroup} startIcon={<AddIcon />}/>
                    </Grid>
                    {newGroupNameErrorStatus && <Grid item xs={12}><Typography color="error" variant="caption">{t('setting.nameRequired')}</Typography></Grid>}
                    <Grid item xs={12} className="fullwidth-table-wrapper">
                        <table id="edit-groups-table" className="mdl-data-table"></table>       
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.editGroupConfirmBtnWrap}>
                <Button color="primary" variant="contained" onClick={handleClose}>
                    {ifEditGroupForNubis ? `${t('table.backToNubisBtn')}` :`${t('table.confirmBtn')}`  }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditGroupDialog;