import { arraySorter } from './arraySorter';

export const setUpArrayWithCheckStatus = (array, setState, type) => {
    const arrayWithCheckStatus = array.map(o => ({
        name: o.name, 
        id: o.id, 
        checked: false,
        origin: o
    }));

    if(type) setState(arraySorter(arrayWithCheckStatus));
    if(!type) setState(arrayWithCheckStatus);
}