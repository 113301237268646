import React from 'react';
import { Box, Typography, Dialog, Divider, Button, Grid } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/subscribeServicesStyle';
import { format } from 'date-fns';

const SubscriptionDetailDialog = props => {
    const classes = useStyles();
    const { open, onClose, orderId, serviceValue, planContentValue,
              companyName, usernames, startedAt, endedAt, deviceSNs, directToList } = props;
    const { t } = useTranslation('subscribeServices');

    const handleClose = () => {
        onClose();
        if(directToList) window.location.assign('/subscriptions');
    }

    return (
        <Dialog onClose={handleClose} open={open} className={classes.subscriptionDetailDialog}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body1">New Subscription</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">Order No.
                    <span className="bold">{orderId}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {t(`service.${serviceValue}`)} {serviceValue === 'nubis_drive' && planContentValue ? `  - ${planContentValue}` : ''}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        {t('subscribeNewService.from')} 
                        <span className="bold">
                            {startedAt && format(new Date(startedAt), 'yyyy-MM-dd')}
                        </span> 
                        {t('subscribeNewService.to')} 
                        <span className="bold">
                            {endedAt && format(new Date(endedAt), 'yyyy-MM-dd')}
                        </span>
                    </Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography variant="caption">
                        {t('subscribeNewService.company')}: 
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Typography variant="caption">
                        {companyName}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Typography variant="caption">
                        {t('subscribeNewService.users')}: 
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <Typography variant="caption">
                        {usernames}
                    </Typography>
                </Grid>
            </Grid>
            {serviceValue !== 'nubis_drive' && 
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Typography variant="caption">
                            {t('subscribeNewService.devices')}: 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Typography variant="caption">
                            {deviceSNs && deviceSNs.join(', ')}
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Box display="flex" justifyContent="center" mt={3}>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    {t('subscribeNewService.ok')}
                </Button>
            </Box>
        </Dialog>
    )
}

export default SubscriptionDetailDialog;