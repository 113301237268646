import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from "../helpers/errorHandling";

export const getRegionsAPI = async (token) => {
        try {
            let response = await axios({
                url: `${url}/api/v1/regions`,
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token
                }
            })
            const allRegionsData = response.data.regions;
            const regionsNamesAndIDs = allRegionsData.map((regionData) => {
                const filteredRegionData = {
                    name: regionData.name, 
                    id: regionData.id
                }  
                return filteredRegionData;
            });
            await localforage.setItem('regions', regionsNamesAndIDs);
        } catch (error) {
            errorHandling(error, false);
        }
}



