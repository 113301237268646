import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  IconButton,
  Divider
} from "@material-ui/core";
import { EditOutlined, DevicesOutlined, Business } from "@material-ui/icons";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/subscribeServicesStyle";
import PublishStatusSwitch from "./PublishStatusSwitch";
import { Body2Text } from "../commons/StyledTexts";
import { format } from "date-fns";

const ServiceListItem = props => {
  const classes = useStyles();
  const { t } = useTranslation("subscribeServices");
  const { serviceData } = props;
  const [checkedStatus, setCheckedStatus] = useState(serviceData.is_enable);
  const jsonParsedJoinedArray = jsonArray => JSON.parse(jsonArray).join(", ");

  const handleSwitchChanged = e => {
    setCheckedStatus(e.target.checked);
  };

  useEffect(() => {
    setCheckedStatus(serviceData.is_enable);
  }, [serviceData]);

  return (
    <Paper className={`p-3 ${classes.serviceListItem}`}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xl={1} lg={1} md={2} sm={3} xs={12}>
          <img
            src={serviceData.logo}
            alt={serviceData.name}
            className="service-logo"
          />
        </Grid>
        <Grid
          item
          xl={11}
          lg={11}
          md={10}
          sm={9}
          xs={12}
          className="flex flex-direction-column"
        >
          <Grid
            container
            justify="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="h6">{serviceData.name}</Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton size="small">
                <EditOutlined fontSize="small" />
              </IconButton>
              <Divider orientation="vertical" variant="middle" flexItem />
              <PublishStatusSwitch
              ifShowRequired={false}
                typographyVariant="caption"
                checked={checkedStatus}
                onChange={handleSwitchChanged}
                id={serviceData.id}
              />
            </Box>
          </Grid>
          <Divider className="mb-2 mt-1" />
          <Grid container spacing={1}>
            <Grid item xl={5} lg={5} md={12}>
              <Box display="flex" flexDirection="row">
                <Body2Text
                  className="mr-1"
                  children={`${t("serviceManagement.package")}: 
                    ${
                      serviceData.package
                        ? jsonParsedJoinedArray(serviceData.package)
                        : "-"
                    }
                  `}
                />
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} md={12}>
              <Box display="flex" flexDirection="row">
                <Body2Text className="flex align-items-center">
                  <span className="mr-1">{t("serviceManagement.type")}: </span>
                  {serviceData.is_for_device ? (
                    <>
                      <DevicesOutlined fontSize="small" className="mr-1" />
                      {t("serviceManagement.device")}
                    </>
                  ) : (
                    <>
                      <Business fontSize="small" className="mr-1" />
                      {t("serviceManagement.company")}
                    </>
                  )}
                </Body2Text>
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} md={12}>
              <Box display="flex" flexDirection="row">
                <Body2Text
                  className="mr-1"
                  children={`${t("serviceManagement.os")}: 
                  ${
                    serviceData.os ? jsonParsedJoinedArray(serviceData.os) : "-"
                  }
                  `}
                />
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={12}>
              <Box display="flex" flexDirection="row">
                <Body2Text
                  className="mr-1"
                  children={`${t("serviceManagement.updatedDate")}: ${
                    serviceData.updated_at
                      ? format(
                          new Date(`${serviceData.updated_at}`),
                          "yyyy-MM-dd HH:mm"
                        )
                      : "-"
                  }`}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ServiceListItem;
