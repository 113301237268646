import React from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import useStyles from '../../styles/companyProfileFormstyle';
import AddIcon from '@material-ui/icons/Add';
import HasPermission from '../commons/HasPermission';

const AccountCardCreateBtnCard = (props) => {
    const classes = useStyles();

    return (
    <HasPermission permission="create_account">
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className={`${classes.accountCardShow} ${classes.createAccountCard} ${classes.accountCardWrap}`}>
                <Paper elevation={3} className={classes.paper}>
                    <Button type="button" onClick={props.handleCreateAccount} disabled={props.disabled} color="primary" startIcon={<AddIcon color={ props.disabled ? 'secondary' : 'primary'} />}>
                            Create Account
                    </Button>
                </Paper>
    </Grid>
    </HasPermission>
    )
}

export default AccountCardCreateBtnCard;