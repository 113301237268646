import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/subscribeServicesStyle';
import SearchFieldAndButton from '../commons/SearchFieldAndButton';
import DeviceTableDialog from './DeviceTableDialog';
import MultipleSelector from './MultipleSelector';
import SingleSelector from './SingleSelector';
import { ReloadTableIconButton, FilterTableIconButton } from '../commons/IconButtons';
import { table }  from '../../helpers/subscriptionListDatatable';
import { valueAddedServicesAPI } from '../../apis/valueAddedServicesAPI';
import { getCompanyTreeAPI } from '../../apis/getCompanyTreeAPI';
import * as $ from 'jquery';
import { sub, differenceInCalendarDays } from 'date-fns';
import { dateItemArray } from '../../config/subscriptionsOptions';
import { setUpArrayWithCheckStatus } from '../../helpers/setUpArrayWithCheckStatus';
import { getObjArrFromAPIOrLocal } from '../../helpers/objArrHandlers';
import { transformedFormatBytes } from '../../helpers/transformedFormatBytes';
import SubscriptionDetailDialog from './SubscriptionDetail';
import UserDialog from './UserDialog';

const SubscriptionList = () => {
    const classes = useStyles();
    const { t } = useTranslation('subscribeServices');
    const [ resetSearchField, setResetSearchField ] = useState(false);
    const [ openDeviceDialog, setOpenDeviceDialog ] = useState(false);
    const [ openUserDialog, setOpenUserDialog ] = useState(false);
    const [ subscriptionId, setSubscriptionId ] = useState([]);
    const [ companies, setCompanies ] = useState([]);
    const [ selectedCompanies, setSelectedCompanies ] = useState([]);
    const [ selectedCompanyIds, setSelectedCompanyIds ] = useState([]);
    const [ services, setServices ] = useState([]);
    const [ selectedServices, setSelectedServices ] = useState([]);
    const [ selectedServiceIds, setSelectedServiceIds ] = useState([]);
    const [ dates, setDates ] = useState(dateItemArray);
    const [ selectedDate, setSelectedDate ] = useState(false);
    const [ dayDiff, setDayDiff ] = useState(false);
    const [ detailDialogOpen, setDetailDialogOpen ] = useState(false);
    const [ startedAt, setStartedAt ] = useState();
    const [ endedAt, setEndedAt ] = useState();
    const [ orderId, setOrderId ] = useState();
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ selectedCompany, setSelectedCompany ] = useState();
    const [ serviceValue, setServiceValue ] = useState([]);
    const [ planContentValue, setPlanContentValue ] = useState();
    const [ deviceSNs, setDeviceSNs ] = useState();

    const showDevices = id => {
        setOpenDeviceDialog(true);
        setSubscriptionId(id);
    }

    const showUsers = users => {
        setOpenUserDialog(true);
        setSelectedUsers(users);
    }

    const showSubscriptionDetail = data => {
        setDetailDialogOpen(true);
        setStartedAt(data.started_at);
        setEndedAt(data.ended_at);
        setOrderId(data.order_id);
        setSelectedCompany(data.company_name);
        setSelectedUsers(data.users.map(o=>o.name).join(', '));
        setDeviceSNs(data.devices.map(o=>o.serial_number));
        setServiceValue(data.value_added_service_name);
        setPlanContentValue(transformedFormatBytes(data?.plan_content?.capacity));
    }

    const handleDeviceTableDialogClose = () => {
        setOpenDeviceDialog(false);
    }

    const handleDetailDialogClose = () => {
        setDetailDialogOpen(false);
    }

    const handleUserDialogClose = () => {
        setOpenUserDialog(false);
        setSelectedUsers([]);
    }

    const reloadTable = (hasFilter = false) => {
        
        setResetSearchField(true);

        if (!hasFilter) {
            table(t, showDevices, showSubscriptionDetail, showUsers);
            getFilterInitialData();
            setSelectedCompanies([]);
            setSelectedCompanyIds([]);
            setSelectedServices([]);
            setSelectedServiceIds([]);
            setSelectedDate(false);
            setDates(dateItemArray);
            setDayDiff(false);
        } else {
            table(t, showDevices, showSubscriptionDetail, showUsers, selectedCompanyIds, selectedServiceIds, dayDiff);
        }
        
    }
    
    const checkDayDiff = value => {
        
        if (value) {
            const pastDate = sub(new Date(), {months: value});
            const diff = differenceInCalendarDays(new Date(), new Date(pastDate));
            setDayDiff(diff);
        } else {
            setDayDiff(false);
        }

    }

    const handleDateChange = e => {
        const value = e.target.value;
        const dateValue =  value.value;
        setSelectedDate(value);
        checkDayDiff(dateValue);
    }

    const getFilterInitialData = async() => {

        let servicesObj = await getObjArrFromAPIOrLocal('valueAddedServices', valueAddedServicesAPI, 'id', true);
        const serviceNamesAndIds = servicesObj.map( o => {
            const name = `${t(`service.${o.name}`)}`;
            return({ name: name, id: o.id });
        });
        setUpArrayWithCheckStatus(serviceNamesAndIds, setServices);
        
        let companyNamesAndIds = await getObjArrFromAPIOrLocal('flattenCompanyTree', getCompanyTreeAPI, 'id', true);
        setUpArrayWithCheckStatus(companyNamesAndIds, setCompanies);
    }

    const searchDatatable = searchValue => $('#subscriptions-list-table').DataTable().search(searchValue).draw();
 
    useEffect(() => {
        reloadTable();
        getFilterInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Paper className="fullwidth-table-wrapper">
                        <Box className={classes.iconButtonGroup} display="flex" flexDirection="row" alignItems="center">
                            <ReloadTableIconButton onClick={()=>reloadTable(false)} />
                            <MultipleSelector
                                height={19}
                                defaultText={t('subscriptions.companies')}
                                groups={companies}
                                setGroups={setCompanies}
                                selectedGroups={selectedCompanies}
                                setSelectedGroups={setSelectedCompanies}
                                setSelectedGroupIds={setSelectedCompanyIds}
                            />
                            <MultipleSelector
                                height={19}
                                defaultText={t('subscriptions.services')}
                                groups={services}
                                setGroups={setServices}
                                selectedGroups={selectedServices}
                                setSelectedGroups={setSelectedServices}
                                setSelectedGroupIds={setSelectedServiceIds}
                            />
                            <Typography variant="body2" color="textSecondary">Start at</Typography>
                            <SingleSelector
                                defaultText={t('subscriptions.date')}
                                groups={dates}
                                selectedGroup={selectedDate}
                                onChange={handleDateChange}
                            />
                            <FilterTableIconButton 
                                onClick={()=>reloadTable(true)} 
                            />
                            <SearchFieldAndButton 
                                resetSearchField={resetSearchField} 
                                setResetSearchField={setResetSearchField} 
                                searchDatatableForTable={searchDatatable} 
                            />
                        </Box>
                        <table id="subscriptions-list-table" className="mdl-data-table dataTable">
                            <thead>
                                <tr>
                                    <th className="subscription-column">{t('table.subscription')}</th>
                                    <th className="devices-column">{t('table.deviceS/N')}</th>
                                    <th className="date-column">{t('table.startDate')}</th>
                                    <th className="date-column">{t('table.endDate')}</th>
                                    <th className="users-column">{t('table.users')}</th>
                                    <th>{t('table.company')}</th>
                                    <th>{t('table.note')}</th>
                                </tr>
                            </thead>
                        </table>
                    </Paper>
                </Grid>
            </Grid>
            <DeviceTableDialog 
                onClose={handleDeviceTableDialogClose} 
                open={openDeviceDialog} 
                id={subscriptionId} 
            />
            <UserDialog
                onClose={handleUserDialogClose} 
                open={openUserDialog} 
                data={selectedUsers}
            />
            <SubscriptionDetailDialog 
                open={detailDialogOpen} 
                onClose={handleDetailDialogClose}
                directToList={false}
                serviceValue={serviceValue}
                deviceSNs={deviceSNs}
                planContentValue={planContentValue}
                companyName={selectedCompany}
                usernames={selectedUsers}
                startedAt={startedAt}
                endedAt={endedAt}
                orderId={orderId}
            />
        </>
    )
}

export default SubscriptionList;