import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Badge } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { NotificationContext } from '../../App';
import { updateNotificationAPI } from '../../apis/updateNotificationAPI';
import NotificationList from './AppBarNotificationList';
import { getNotificationList } from '../../reducers/getNotificationReducer';

const NotificationIconBtn = () => {
    const { get } = useContext(NotificationContext);
    const [ notiDetailDialogOpen, setNotiDetailDialogOpen ] = useState(false);
    const [ hasNewNotification, setHasNewNotification ] = useState(false);
    const [ notiItems, setNotiItems ] = useState([]); 
    const [ currentNotiItems, setCurrentNotiItems ] = useState(''); 
    const [ anchorNotiEl, setAnchorNotiEl ] = useState(null); // Noti menu pop out position
    const isMenuNotiOpen = Boolean(anchorNotiEl);

    const setNotificationList = async() => {
        const hasNotifications = await getNotificationList()
        if(hasNotifications){
          const notiItems = hasNotifications.notificationListObjArr.map(obj => ({...obj, read: false}))
          setNotiItems(notiItems)
          hasNotifications.checkHasNewNotification.length > 0 ? setHasNewNotification(true) : setHasNewNotification(false) 
        }
    }

    const handleNotificationMenuOpen = (event) => {
        setAnchorNotiEl(event.currentTarget);
    };
    
    const handleNotificationMenuClose = () => {
        setAnchorNotiEl(null);
    };

    const handleNotificationDialogOpen = async(notiItem) => {
        setNotiDetailDialogOpen(true)
        handleNotificationMenuClose()
        setCurrentNotiItems(notiItem)
        //update one single notification api
        const notificationIdsArr = [notiItem.id]
        await updateNotificationAPI(notificationIdsArr)
    }
    
    const handleNotificationDialogClose = () => {
        setNotiDetailDialogOpen(false)
    }
    
    useEffect(() => {
        if(!notiDetailDialogOpen || get) setNotificationList();
    },[notiDetailDialogOpen, get])

    return (
        <>
            <IconButton 
                aria-label="Notifications" 
                color="inherit"
                onClick={handleNotificationMenuOpen}
                >
                {hasNewNotification ? 
                    <Badge variant="dot" color="error"><NotificationsIcon /></Badge>
                    :
                    <NotificationsIcon />
                }
            </IconButton>
            <NotificationList 
                open={isMenuNotiOpen} 
                onClose={handleNotificationMenuClose} 
                anchorEl={anchorNotiEl} 
                notiItems={notiItems} 
                currentNotiItem={currentNotiItems}
                notiDetailDialogOpen={notiDetailDialogOpen}
                handleNotificationDialogOpen={handleNotificationDialogOpen}
                handleNotificationDialogClose={handleNotificationDialogClose}
                handleNotificationMenuClose={handleNotificationMenuClose} 
            />
        </>
    )
}

export default NotificationIconBtn;