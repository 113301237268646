import React from 'react';
import { Menu, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';
import moment from 'moment';
import NotificationDetailDialog from './NotificationDetailDialog';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { useTranslation } from "../../langs/useTranslation";

const NotificationList = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('common'); 
    const { anchorEl, open, onClose, notiItems, notiDetailDialogOpen, handleNotificationDialogOpen, handleNotificationDialogClose, currentNotiItem } = props;

    const updateNotification = (notiItem) => {
        handleNotificationDialogOpen(notiItem)
    }

    return (
    <> 
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            className={`${classes.menuList} ${classes.notificationList}`}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={onClose}
            >
                <List>
                { notiItems.length > 0 ? 
                    notiItems.map((notiItem) => {
                        return(
                            <ListItem 
                            alignItems="flex-start" 
                            button 
                            onClick={()=>updateNotification(notiItem)} 
                            key={notiItem.id} 
                            id={notiItem.id}
                            className={(notiItem.read_at ? 'read' : '')}
                            >
                            <div className="notificationItemDot">
                            {notiItem.read_at ? 
                                <CheckRoundedIcon color="secondary" />
                                :
                                <FiberManualRecordRoundedIcon color="primary" />
                            }
                            </div>
                            <ListItemText className={classes.notiListText}>
                                <Typography 
                                    variant="body1" 
                                    className={classes.notiMainText}
                                >
                                    <span dangerouslySetInnerHTML = {{ __html: notiItem.json.message }} />
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    color="textSecondary" 
                                    className={classes.notiMemos}
                                >
                                    {notiItem.json.devices ? notiItem.json.devices.join(', ') : ''}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {moment(notiItem.created_at).format('lll')}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        )
                    })
                    :
                    <Typography display="block" align="center" variant="caption" color="textSecondary">
                        {t('notification.noNewNotification')}
                    </Typography>
                }
                </List>
        </Menu>
        <NotificationDetailDialog open={notiDetailDialogOpen} onClose={handleNotificationDialogClose} currentNotiItem={currentNotiItem} />
    </>
    )
}

export default NotificationList;