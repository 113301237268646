import React, { useState, useEffect } from 'react';
import useStyles from '../../styles/companyProfileFormstyle';
import { Grid, Paper } from '@material-ui/core';
import UpdateAccountCardForm from './UpdateAccountCardForm';
import AccountCardOnlyShow from './AccountCardOnlyShow';
import ConfirmRemoveAccountButton from './ConfirmRemoveAccountButton';

export const AccountContext = React.createContext({})
const AccountCard = (props) => {
    const classes = useStyles();
    const { accountID, imgLink, name, phone, email, role, lastLogin, activeStatus, password, companyID, getCompanyAccount } = props;
    const [ isEditAccount, setIsEditAccount ] = useState(false);
    const [ confirmRemoveDialogOpen, setConfirmRemoveDialogOpen ] = useState(false);
    const [ isAdmin, setIsAdmin ] = useState();

    const handleEditAccount = () => {
        setIsEditAccount(true);
    }

    const handleCancelEditAccount = () => {
        setIsEditAccount(false);
    }

    const ShowConfirmRemoveAccountButton = async() => {
        setConfirmRemoveDialogOpen(true);
    }

    const handleCloseRemoveButton = () => {
        setConfirmRemoveDialogOpen(false);
    }

    useEffect(()=>{
        setIsAdmin(role==='Administrator');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <AccountContext.Provider value={{handleCancelEditAccount}}>
            {!isEditAccount ? 
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className={`${classes.accountCardShow} ${classes.accountCardWrap}`}>
                    <Paper elevation={3} className={classes.paper}>
                            {!confirmRemoveDialogOpen ? 
                            <AccountCardOnlyShow
                                id={accountID}
                                name={name}
                                phone={phone}
                                email={email}
                                lastLogin={lastLogin}
                                activeStatus={activeStatus}
                                avatarLink={imgLink}
                                role={role}
                                handleEditAccount={handleEditAccount}
                                ShowConfirmRemoveAccountButton={ShowConfirmRemoveAccountButton}
                                isAdmin={isAdmin} />
                            :
                            <ConfirmRemoveAccountButton 
                                id={accountID}
                                name={name}
                                companyID={companyID}
                                email={email}
                                getCompanyAccount={getCompanyAccount}
                                handleCloseRemoveButton={handleCloseRemoveButton} />
                        }
                    </Paper>
                </Grid>
                : 
                <UpdateAccountCardForm  
                    id={accountID}
                    name={name}
                    phone={phone}
                    email={email}
                    activeStatus={activeStatus}
                    avatarLink={imgLink}
                    role={role}
                    password={password}
                    handleCancelCreateAccount={handleCancelEditAccount} /> 
            }
        </AccountContext.Provider>
    )
}

export default AccountCard;