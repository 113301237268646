import React, { useContext, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import useStyles from '../styles/accessManagementStyle';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { useTranslation } from "../langs/useTranslation";
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../auths/Auth';

const LoginErrorPage = () => {
    const { t } = useTranslation('common')
    const classes = useStyles();
    const { isLoginFailed, setIsLoginFailed } = useContext(AuthContext)

    useEffect(() => {
        setIsLoginFailed(false);
    }, [])

    return(
        <div className={classes.root}>
            <main className={classes.content}>
                <div className={classes.titleSection}>
                    <Typography variant="h4">{t('permission.oops')}</Typography>
                    <Typography variant="h6">{t('permission.loginErrorText')}</Typography>
                </div>
                <NavLink to="/">
                    <Button startIcon={<HomeRoundedIcon />}>
                    {t('btn.backToHome')}
                    </Button>
                </NavLink>
            </main>
        </div>
    )
}

export default LoginErrorPage;