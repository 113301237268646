import * as Yup from 'yup';

export const newAccountValueSchema = Yup.object().shape({
    name: Yup.string()
    .required('Required!'),
    phone: Yup.string()
    .required('Required!'),
    email: Yup.string()
    .required('Required!').email('Invalid email address'),
    role: Yup.string()
    .required('Required!'),
    password: Yup.string()
    .required('Required!'),
    active: Yup.boolean()
})

export const newAccountInitialValues = {
    name: '',
    phone: '',
    email: '',
    role: '',
    password: '',
    active: ''
}

export const companyProfileFormValueSchema = Yup.object().shape({
    companyName: Yup.string()
    .required('Required!'),
    parentCompany: Yup.string()
    .nullable().required('Required!'),
    contactPerson: Yup.string()
    .required('Required!'),
    contactPhone: Yup.string()
    .required('Required!'),
    contactMail: Yup.string()
    .required('Required!').email('Invalid email address'),
    companyCountry: Yup.string()
    .nullable().required('Required!'),
    companyRegion: Yup.string()
    .nullable().required('Required!'),
    companyAddress: Yup.string()
    .required('Required!'),
    customID: Yup.string().nullable()
})

export const updateAccountCardFormValueSchema = Yup.object().shape({
    name: Yup.string()
    .required('Required!'),
    phone: Yup.string()
    .required('Required!'),
    email: Yup.string()
    .required('Required!').email('Invalid email address'),
    role: Yup.string()
    .required('Required!'),
    password: Yup.string()
    .nullable(),
    active: Yup.boolean()
})