const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export let bytesConverter = sizeWithUnit => {
    
    const b = 1000;
    const size = sizeWithUnit.match(/\d+/)[0];
    const unit = sizeWithUnit.replace(/\d+/g, '');
    const unitInUpperCase = unit.toUpperCase();
    
    if(units.includes(unitInUpperCase)){
        const i = units.indexOf(unitInUpperCase);
        return Math.pow(b, i) * size;
    }

}

export const checkIsValueValid = value => {
    
    if (value) {
        
        const size = value.match(/\d+/) ? value.match(/\d+/)[0] : false;
        const unit = value.replace(/\d+/g, '');
        const unitInUpperCase = unit.toUpperCase();

        if (size && unit && units.includes(unitInUpperCase)) {
            return true;
        }
    }

    return false;
}

let formatBytes = (bytes, decimals = 3) => {
    if (bytes === 0) return '0';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

/* usage */
export const transformedFormatBytes = (size) => formatBytes(size);
