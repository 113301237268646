import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const updateRoleWithPermissionsAPI = async(role_id, role_name) => {
    const token = await localforage.getItem('token');
    const permissions = await localforage.getItem(role_id);
    if(permissions){
        const checkedPermissionArr = permissions.filter((o) => o.permissionAssignedToRole === 'checked')
        const checkedPermissionNameArr = checkedPermissionArr.map((o) => {
            return o.name
        })
        try {
            await axios({
                url: `${url}/api/v1/roles/update`,
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                data: {
                    role_id: role_id,
                    role_name: role_name,
                    permissions: checkedPermissionNameArr
                }
            })
            return true;
        } catch (error) {
            errorHandling(error, false);
            return false;
        }
    }
    
}