import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { generateRecursiveList } from "../helpers/generateRecursiveList";
import { flatMultiDimensional } from "../helpers/flatMultiDimensional";

export const getSubCompanyAPI = async () => {
  const token = await localforage.getItem("token");
  const companyId = await localforage.getItem("userCompanyID");

  try {
    let response = await axios({
      url: `${url}/api/v1/sub-companies`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token
      },
      params: {
        company_id: companyId
      }
    });
    if (response.data && response.data.companies) {
      let originalData = response.data.companies;
      const subCompaniesArr = generateRecursiveList(response.data.companies);
      await localforage.setItem(
        "subCompanies",
        flatMultiDimensional(subCompaniesArr)
      );
      await localforage.setItem("subCompaniesOriginalData", originalData);
      return originalData;
    }
  } catch (error) {
    errorHandling(error, false);
    return false;
  }
};
