import React from "react";
import { Divider } from "@material-ui/core";
import BacktoHomeBtn from "../commons/BacktoHomeBtn";
import {
  SubscribeServiceBtns
} from "./SubscriptionsBtns";
import useStyles from "../../styles/accountCenterStyle.js";

const SubscribeDrawerBtns = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.toolbar} />
      <BacktoHomeBtn />
      <Divider />
      <SubscribeServiceBtns />
    </div>
  );
};

export default SubscribeDrawerBtns;
