import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import * as localforage from "localforage";

export const getServicesAPI = async (os, type, searchValue) => {

  const token = await localforage.getItem("token");

  try {
    let response = await axios({
      url: `${url}/api/v1/service-management/datatable`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token
      },
      data: {
        os: os ? os.name : null,
        type: type ? type.name : null,
        keyword: searchValue
      }
    });

    await localforage.setItem("services", response.data.services);
    return response.data.services;
  } catch (error) {
    errorHandling(error, true);
  }
};
