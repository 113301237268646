import React from 'react';
import { Grid, Select, Input, Chip, MenuItem, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import useStyles from '../../styles/delegationStyle';
import GroupEditBtn from './GroupEditBtn';
import { SettingTitle } from '../commons/StyledTexts';
import { MenuProps } from '../../helpers/menuProps';

const SelectionMenuItem = props => {
    const classes = useStyles();
    const { action, title, actionsHideGroupOrTagSelection, editBtnOnClick, selectValueArray, checkBoxOnChange, menuItemArray } = props;

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item lg={2} md={2} sm={3} xs={3} className={classes.flex}>
                <SettingTitle 
                    action={action} 
                    title={title} 
                    actionsHideGroupOrTagSelection={actionsHideGroupOrTagSelection} 
                />
                {title === 'groups' && <GroupEditBtn onClick={editBtnOnClick} /> }
            </Grid>
            <Grid item lg={10} md={10} sm={9} xs={9}>
                <FormControl className={classes.multipleSelect}>
                    <Select
                        labelId="mutiple-chip-label"
                        id="mutiple-chip"
                        multiple
                        disabled={actionsHideGroupOrTagSelection.includes(action)}
                        value={selectValueArray} 
                        input={<Input fullWidth={true} variant="outlined" />}
                        renderValue={array => {
                            return (
                                <div className={classes.chips}>
                                    {array.map( o => <Chip key={o.id} label={o.name} className={classes.chip} /> )}
                                </div>
                            )
                        }}
                        MenuProps={MenuProps}
                    >
                        {menuItemArray ? menuItemArray.map((o, i) => {
                            return (
                                <MenuItem key={i}>
                                    <FormControlLabel 
                                        className={classes.fullWidthLabel}
                                        control={
                                            <Checkbox 
                                                checked={o.checked} 
                                                id={o.id}
                                                onChange={checkBoxOnChange} 
                                                name={o.name} 
                                            />
                                        }
                                        label={o.name}
                                    />
                                </MenuItem>
                                )
                            })
                            :
                            ''
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default SelectionMenuItem;