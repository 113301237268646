import React from 'react';
import { ReplayOutlined, GetAppOutlined, HighlightOffOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import SubscriptionBtn from './SubscriptionEditBtn';
import { removeSubscriptionAPI  } from '../../apis/removeSubscriptionAPI';
import { cancelSubscriptionAPI  } from '../../apis/cancelSubscriptionAPI';
import { exportSubscriptionAPI  } from '../../apis/exportSubscriptionAPI';
import { successHandling } from '../../helpers/successHandling';
import { useTranslation } from "../../langs/useTranslation";
import { table }  from '../../helpers/subscriptionListDatatable';
import * as localforage from 'localforage';
import HasPermission from '../commons/HasPermission';

const SubscriptionActionButtons = props => {

    const { t } = useTranslation('subscribeServices');
    const { data, subscriptionId, showDevices, subscriptionInfo, isRenewal, showSubscriptionDetail } = props;

    const removeSubscription = async(e) => {

        if(!e.detail || e.detail === 1) { 
            const result = await removeSubscriptionAPI(subscriptionId, t);
            if (result) {
                const successfulText = 'subscriptions.successfullyRemoveSubscription';
                successHandling(t, successfulText, 'success', reloadTable);
            }
        }
    }

    const cancelSubscription = async(e) => {
        if(!e.detail || e.detail === 1) { 
            const result = await cancelSubscriptionAPI(subscriptionId, t);
            if (result) {
                const successfulText = 'subscriptions.successfullyCancelSubscription';
                successHandling(t, successfulText, 'success', reloadTable);
            }
        }
    }

    const renewSubscription = async() => {
        await localforage.setItem(`subscription${subscriptionId}`, subscriptionInfo);
        window.location.assign(`/subscribe-new-service/${subscriptionId}`);
    }

    const exportSubscription = async(e) => {
        if(!e.detail || e.detail === 1) { 
            const result = await exportSubscriptionAPI(subscriptionId, t);
            if (result) {
                const successfulText = 'subscriptions.successfullyExportSubscription';
                successHandling(t, successfulText, 'success', reloadTable);
                window.location.assign(result);
            }
        }
    }

    const reloadTable = () => {
        table(t, showDevices, showSubscriptionDetail);
    }

    return (
        <>
            { data === 'subscribed' && 
                <>
                    <HasPermission permission="subscription_cancel">
                        <SubscriptionBtn actionName="cancel_subscription" icon={<HighlightOffOutlined />} onClick={(e)=>cancelSubscription(e)} />
                    </HasPermission>
                    <SubscriptionBtn actionName="export_subscription" icon={<GetAppOutlined />}  onClick={(e)=>exportSubscription(e)} />
                </>
            }
            { data === 'cancelled' && 
                <>
                    <HasPermission permission="subscription_remove">
                        <SubscriptionBtn actionName="remove_subscription" icon={<DeleteOutlineOutlined  />} onClick={(e)=>removeSubscription(e)} />
                    </HasPermission>
                    <SubscriptionBtn actionName="export_subscription" icon={<GetAppOutlined />}  onClick={(e)=>exportSubscription(e)} />
                </>
            }
            { data === 'expired' && 
                <>
                    {!isRenewal && <HasPermission permission="subscription_renew">
                        <SubscriptionBtn actionName="renew_subscription" icon={<ReplayOutlined />} onClick={(e)=>renewSubscription(e)} />
                    </HasPermission>
                    }
                    <SubscriptionBtn actionName="export_subscription" icon={<GetAppOutlined />}  onClick={(e)=>exportSubscription(e)} />
                </>
            }
        </>
    )
}

export default SubscriptionActionButtons;