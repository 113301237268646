import React from 'react';
import { Grid, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography } from '@material-ui/core';
import useStyles from '../../styles/companyProfileFormstyle';
import defaultImage from '../../assets/images/default_image.svg';
import AccountEditAndRemoveBtns from './AccountEditAndRemoveBtns';

const AccountCardOnlyShow = (props) => {
    const classes = useStyles();
    const { avatarLink, name, phone, email, role, lastLogin, activeStatus, isAdmin, handleEditAccount, ShowConfirmRemoveAccountButton } = props;

    return (
        <div className={classes.mainContent}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography color="secondary" variant="body1">
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.avatarWrap}>
                    <div className="avatar-image">
                        <img src={avatarLink || defaultImage} alt="avatar" />
                    </div>
                </Grid>
            </Grid>
            <Typography color="secondary" variant="body1">
                {phone || '-'}
            </Typography>
            <Typography color="secondary" variant="body1">
                {email}
            </Typography>
            <Typography color="secondary" variant="body1">
                {role}
            </Typography>
            <Typography variant="caption" color="secondary">
                Last Login: {lastLogin}
            </Typography>
            <Grid container alignItems="center" className={classes.buttonGroup}>
                <FormControl className={`${classes.activeStatus} ${classes.activeStatusReset}`}>
                    <FormLabel>Active</FormLabel>
                    <RadioGroup row aria-label="active" name="active" value={activeStatus}>
                        <FormControlLabel control={<Radio color="primary" disabled value={activeStatus} checked={activeStatus === true ? true : false} />}  value={props.activeStatus}  />
                    </RadioGroup>
                </FormControl>
                <AccountEditAndRemoveBtns
                    isAdmin={isAdmin}
                    ShowConfirmRemoveAccountButton={ShowConfirmRemoveAccountButton}
                    handleEditAccount={handleEditAccount}
                />
            </Grid>
        </div>
    )
}

export default AccountCardOnlyShow;