import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const getCompanyAPI = async company_id => {
  const token = await localforage.getItem("token");
  try {
    let response = await axios({
      url: `${url}/api/v1/company/profile`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token
      },
      params: {
        company_id: company_id
      }
    });

    let companyData = response.data.company_profile;
    await localforage.setItem(company_id, response.data.company_profile);
    return companyData;
  } catch (error) {
    errorHandling(error, false);
    return false;
  }
};
