import * as $ from 'jquery';

export const language = t => {
    return({
        zeroRecords: `${t('table.zeroRecords')}`,
        loadingRecords: `<span class="loading">${t('table.loadingRecords')}</span>`,
        infoEmpty: `${t('table.noRecordsAvailable')}`,
        processing: `<span class="loading">${t('table.loadingRecords')}</span>`
    })
}

export const buttons = (userPermissions, node) => {
    if(userPermissions){
        const isPermission = userPermissions.includes('device_and_groups_export');
        isPermission ? $(node).addClass('btn') : $(node).addClass('btn-no-permission');
    }
}