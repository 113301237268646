import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import nubisIcon from '../../assets/images/nubis.svg';
import nubisDriveIcon from '../../assets/images/nubis_drive.png';
import visioIcon from '../../assets/images/visio.svg';
import { transformedFormatBytes } from '../../helpers/transformedFormatBytes';

const ServiceIconAndName = props => {
    const { imgUrl, serviceName, planContent } = props;
    const { t } = useTranslation('subscribeServices');
    const [ img, setImg ] = useState(imgUrl ? imgUrl : null);
    const [ value, setValue ] = useState(planContent ? transformedFormatBytes(planContent) : null);

    const serviceIconDeterminator = serviceName => {
        const service = serviceName.toLowerCase();
        switch (service) {
            case 'nubis_pro': 
                setImg(nubisIcon);
                setValue(false);
                break;
            case 'nubis_drive': 
                setImg(nubisDriveIcon);
                setValue(transformedFormatBytes(planContent));
                break;
            case 'visio': 
                setImg(visioIcon);
                setValue(false);
                break;
            default: 
                setImg(null);
                setValue(false);
                break;
        }
    }

    useEffect(() => {
        if(!imgUrl) serviceIconDeterminator(serviceName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgUrl])
    
     return (
         <div className="subscription-service">
            <img src={img} alt={serviceName} className="service-icon" /> 
            <Typography variant="body2">{t(`service.${serviceName}`)} {value ? `- ${value}` : ''}</Typography>
        </div>
     )
}

export default ServiceIconAndName;