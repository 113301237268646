import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Link,
  Drawer,
  Divider,
  Typography
} from "@material-ui/core";
import BacktoHomeBtn from "../components/commons/BacktoHomeBtn";
import { HomeOutlined } from "@material-ui/icons";
import useStyles from "../styles/accountCenterStyle.js";
import { useTranslation } from "../langs/useTranslation";
import ProfileCard from "../components/accountCenters/ProfileCard";
import BacktoPrevPage from "../components/commons/BacktoPrevPage";
import CompanyProfileForm from "../components/accountCenters/CompanyProfileForm";
// import StoreList from "../components/accountCenters/StoreList";
import NewAccountCreateCardForm from "../components/accountCenters/NewAccountCreateCardForm";
import AccountCard from "../components/accountCenters/AccountCard";
import AccountCardCreateBtnCard from "../components/accountCenters/AccountCardCreateBtnCard";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getCompanyAccountsAPI } from "../apis/getCompanyAccountsAPI";
import { getCompanyAPI } from "../apis/getCompanyAPI";

export const CompanyAccountContext = React.createContext({});
const CompanyProfile = () => {
  const classes = useStyles();
  const { company_id } = useParams();
  const { t } = useTranslation("accountCenter");
  const createCompanyTitle = "Create Company";
  const [ifCreateAccount, setIfCreateAccount] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isEditAccount, setIsEditAccount] = useState(false);
  const [newCompanyProfile, setNewCompanyProfile] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountUpdate, setAccountUpdate] = useState(false);
  const [companyID, setCompanyID] = useState();
  const [companyName, setCompanyName] = useState('');
  const [companyData, setCompanyData] = useState({});

  const handleCreateAccount = () => {
    setIfCreateAccount(true);
  };

  const handleCancelCreateAccount = () => {
    setIfCreateAccount(false);
    setIsEditAccount(false);
  };

  const getCompanyNameInfo = async () => {
    if (company_id === "new") {
      setNewCompanyProfile(true);
      return;
    } else {
      setNewCompanyProfile(false);
      setCompanyID(company_id);
      let companyData = await getCompanyAPI(company_id);
      setCompanyName(companyData.name);
      setCompanyData(companyData);
      //this is for directly switching view company profile by clicking the profile card
      getCompanyAccount(company_id);
    }
  };

  const getCompanyAccount = async company_id => {
    if (company_id !== "new" || newCompanyProfile === false) {
      const accounts = await getCompanyAccountsAPI(company_id);
      if (accounts) {
        setAccounts(accounts);
      }
      setAccountUpdate(false);
    }
  };

  useEffect(() => {
    getCompanyNameInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyID && companyID !== company_id) {
      getCompanyNameInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  useEffect(() => {
    if (accountUpdate) {
      getCompanyAccount(company_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountUpdate]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <BacktoHomeBtn />
      <Divider />
      <ProfileCard />
      <Divider />
    </div>
  );

  return (
    <CompanyAccountContext.Provider
      value={{ setAccountUpdate, handleCancelCreateAccount }}
    >
      <div className={classes.root}>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </nav>
        <main className={classes.content}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              <HomeOutlined />
            </Link>
            <Typography color="textPrimary">
              {t("accountCenter.title")}
            </Typography>
          </Breadcrumbs>
          <BacktoPrevPage
            linkTo="/account-center"
            btnText={t("accountCenter.title")}
          />
          <Typography variant="h5" className={classes.titleSection}>
            {company_id === "new" ? createCompanyTitle : companyName}
          </Typography>
          <Grid container spacing={3} alignContent="stretch">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CompanyProfileForm companyData={companyData} />
            </Grid>
            {/* <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <StoreList />
            </Grid> */}
          </Grid>
          <Typography variant="h5" className={classes.titleSection}>
            {t("accountCenter.accountListTitle")}
          </Typography>
          <Grid container spacing={3} alignContent="stretch">
            {!ifCreateAccount ? (
              <AccountCardCreateBtnCard
                disabled={newCompanyProfile}
                handleCreateAccount={handleCreateAccount}
              />
            ) : (
              <NewAccountCreateCardForm
                handleCancelCreateAccount={handleCancelCreateAccount}
              />
            )}
            {!newCompanyProfile &&
              accounts.map(account => (
                <AccountCard
                  key={account.id}
                  accountID={account.id}
                  companyID={company_id}
                  name={account.name}
                  phone={account.phone}
                  imgLink={account.avatar_link}
                  email={account.email}
                  role={account.roles[0] ? account.roles[0].name : "-"}
                  activeStatus={account.active_status}
                  password={account.password}
                  lastLogin={
                    account.last_login_at
                      ? moment(account.last_login_at).format("lll")
                      : " - "
                  }
                  getCompanyAccount={getCompanyAccount}
                />
              ))}
          </Grid>
        </main>
      </div>
    </CompanyAccountContext.Provider>
  );
};

export default CompanyProfile;
