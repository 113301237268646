import React, { Suspense, useReducer } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./containers/Login";
import casLogin from "./containers/casLogin";
import Home from "./containers/Home";
import DevicesGroups from "./containers/DevicesGroups";
import AccessManagement from "./containers/AccessManagement";
import PermissionManagement from "./containers/PermissionManagement";
import Subscriptions from "./containers/Subscriptions";
import SubscribeNewService from "./containers/SubscribeNewService";
import ServiceManagement from "./containers/ServiceManagement";
import ForgotPassword from "./containers/ForgotPassword.js";
import LoginAgain from "./containers/LoginWithNewPassword";
import AccountCenter from "./containers/AccountCenter";
import CompanyProfile from "./containers/CompanyProfile";
import PrivateRoute from "./hoc/PrivateRoute";
import Auth from "./auths/Auth";
import theme from "./theme/muiTheme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  styledSubmitBtnState,
  styledSubmitBtnReducer,
} from "./reducers/styledSubmitBtnReducer";
import NoPermission from "./containers/NoPermission";
import {
  getNotificationState,
  getNotificationReducer,
} from "./reducers/getNotificationReducer";
import { LanguageProvider } from "./langs/LanguageProvider";
import DateFnsUtils from "@date-io/date-fns";
import LoginErrorPage from "./containers/LoginError";
// use for recognize the node server and kill it, rather than kill all the node server.
// process.title = "fec-platform";

export const SubmitBtnContext = React.createContext({}); // context for btn loading animation
export const NotificationContext = React.createContext({});

function App() {
  const [, dispatch] = useReducer(
    styledSubmitBtnReducer,
    styledSubmitBtnState
  );
  const [, getNotiDispatch] = useReducer(
    getNotificationReducer,
    getNotificationState
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <LanguageProvider>
          <Suspense fallback="loading">
            <Auth>
              {/* Auth get the value sent from the AuthContext.Provider */}
              <SubmitBtnContext.Provider
                value={{
                  loading: styledSubmitBtnState.loading,
                  success: styledSubmitBtnState.success,
                  dispatch,
                }}
              >
                <NotificationContext.Provider
                  value={{ get: getNotificationState.get, getNotiDispatch }}
                >
                  <BrowserRouter>
                    <Switch>
                      <PrivateRoute
                        permissionName="service_management"
                        path="/service-management"
                        component={ServiceManagement}
                      />
                      <PrivateRoute
                        permissionName="No Permission"
                        path="/subscriptions/new"
                        component={Subscriptions}
                      />
                      <PrivateRoute
                        permissionName="No Permission"
                        path="/subscriptions"
                        component={Subscriptions}
                      />
                      <PrivateRoute
                        permissionName="No Permission"
                        path="/subscribe-new-service/:id"
                        component={SubscribeNewService}
                      />
                      <PrivateRoute
                        permissionName="No Permission"
                        path="/subscribe-new-service"
                        component={SubscribeNewService}
                      />
                      <PrivateRoute
                        permissionName="Account Center"
                        path="/account-center"
                        component={AccountCenter}
                      />
                      <PrivateRoute
                        permissionName="Access Management"
                        path="/access-management"
                        component={AccessManagement}
                      />
                      <PrivateRoute
                        permissionName="Access Management"
                        path="/permission-management/:role_name/:role_id"
                        component={PermissionManagement}
                      />
                      <PrivateRoute
                        permissionName="Devices & Groups"
                        path="/devices-groups"
                        component={DevicesGroups}
                      />
                      <PrivateRoute
                        permissionName="Account Center"
                        path="/company-profile/:company_id"
                        component={CompanyProfile}
                      />
                      <PrivateRoute
                        permissionName="No Permission"
                        path="/no-permission"
                        component={NoPermission}
                      />
                      <Route
                        path="/login-error"
                        component={LoginErrorPage}
                      >
                      </Route>
                      <Route
                        path="/forgotPassword"
                        component={ForgotPassword}
                      />
                      <Route path="/loginAgain" component={LoginAgain} />
                      <Route path="/login" component={casLogin} />
                      <PrivateRoute
                        permissionName="Home"
                        path="/"
                        component={Home}
                        exact
                      />
                    </Switch>
                  </BrowserRouter>
                </NotificationContext.Provider>
              </SubmitBtnContext.Provider>
            </Auth>
          </Suspense>
        </LanguageProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
