import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    backtoPrevPage: {
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        color: '#2c3ff38c !important',
        '&:hover': {
            color: '#2c3ff3 !important',
        }
    }
}))

export default useStyles;