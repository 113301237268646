import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuList: {
    top: "40px !important",
    overflow: "initial",
  },
  langSelect: {
    position: "relative",
    width: "48px !important",
    overflow: "hidden",
    "& .MuiSelect-select": {
      position: "relative",
      width: "50px !important",
      padding: 0,
      overflow: "hidden",
      minHeight: "0 !important",
      height: "0 !important",
    },
    "& .MuiSelect-icon": {
      top: "-27px !important",
      transform: "scale(0.8)",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
  },
  langBtn: {
    position: "absolute",
    width: 48,
    "& .MuiIconButton-label": {
      marginLeft: -3,
    },
  },
  langBtnText: {
    width: 25,
    position: "relative",
    left: -4,
    "& svg": {
      position: "absolute",
      top: 2,
    },
  },
}));

export default useStyles;
