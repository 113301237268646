import React from "react";
import "../../css/avatarEditor.css";

const AvatarEditor = props => {
  const { imgSrc, disabled, onChange } = props;

  const inputCursorStyle = disabled ? {} : { cursor: "pointer" };

  return (
    <div className="avatar-editor">
      <input
        type="file"
        onChange={onChange}
        disabled={disabled}
        style={inputCursorStyle}
      />
      <div className="avatar-image">
        <img src={imgSrc} alt="avatar" />
      </div>
    </div>
  );
};

export default AvatarEditor;
