import React, { useState } from "react";
import {
  Breadcrumbs,
  Link,
  Drawer,
  Typography,
  Button,
  Box
} from "@material-ui/core";
import { HomeOutlined, AddOutlined } from "@material-ui/icons";
import useStyles from "../styles/subscribeServicesStyle";
import { useTranslation } from "../langs/useTranslation";
import SubscribeDrawerBtns from "../components/subscribeServices/DrawerBtns";
import ServiceList from "../components/subscribeServices/ServiceList";
import CreateServiceDialog from "../components/subscribeServices/CreateServiceDialog";

const ServiceManagement = () => {
  const classes = useStyles();
  const { t } = useTranslation("subscribeServices");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const handleOpenCreateDialog = () => setOpenCreateDialog(true);
  const handleCloseCreateDialog = () => setOpenCreateDialog(false);

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <SubscribeDrawerBtns />
        </Drawer>
      </nav>
      <main className={classes.content}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            <HomeOutlined />
          </Link>
          <Typography color="textPrimary">
            {t("serviceManagement.title")}
          </Typography>
        </Breadcrumbs>
        <Box
          className={classes.titleSection}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">{t("serviceManagement.title")}</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddOutlined />}
            onClick={handleOpenCreateDialog}
          >
            {t("serviceManagement.service")}
          </Button>
        </Box>
        <ServiceList />
      </main>
      <CreateServiceDialog
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
      />
    </div>
  );
};

export default ServiceManagement;
