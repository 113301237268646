import React from 'react'; 
import { Typography, Grid } from '@material-ui/core';
import useStyles from '../../styles/settingOptionStyle';
import { NavLink } from 'react-router-dom';

const SettingOption = props => {

    const classes = useStyles()

    return(
        <Grid item xs={12} sm={6} md={3}>
            <NavLink to={props.linkPath} className={classes.btn} onClick={props.clickBtn} >
                <img src={props.imgSrc} alt={props.imgAlt} />
                <Typography className={classes.wordBreak} variant="h6">{props.btnTitle}</Typography>    
                <Typography className={classes.wordBreak} variant="body2">{props.btnText}</Typography>  
            </NavLink>
        </Grid>
    )
}


export default SettingOption;