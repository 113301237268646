import React from 'react';
import { RadioGroup, FormControlLabel, Radio, Typography, Box } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { checkIsValueValid, transformedFormatBytes } from '../../helpers/transformedFormatBytes';
import OtherCapacityTextfield from './OtherCapacityTextfield';
import useStyles from '../../styles/subscribeServicesStyle';

const SelectNubisDriveCapacity = props => {
    const classes = useStyles();
    const { t } = useTranslation('subscribeServices');
    const { nubisDriveCapacityValue, setNubisDriveCapacityValue, 
              planContentValue, setPlanContentValue, 
              isRenewal, isValueValid, setIsValueValid } = props;

    const handleNubisDriveCapacityChange = e => {
        const value = e.target.value;
        setNubisDriveCapacityValue(value);
        if ( value === 'other' ) setPlanContentValue(false);
        if ( value &&  value !== 'other' ) setPlanContentValue(value);
    }

    const handlePlanContentChange = e => {
        
        const value = e.target.value;
        const validValue = value ? checkIsValueValid(value) : false;
        
        if ( value ) {
            setPlanContentValue(value);
            setNubisDriveCapacityValue('other');
            setIsValueValid(!!validValue);
        }
    }

    return (
        <Box display="inline-flex" alignItems="baseline">
            {!isRenewal ? 
                <>
                    <Typography  className={classes.capacityFieldTitle}>{t('subscribeNewService.chooseCapacity')}</Typography>
                    <RadioGroup aria-label="nubisDriveCapacity" name="nubisDriveCapacity" value={nubisDriveCapacityValue} onChange={handleNubisDriveCapacityChange}>
                        <Box className={classes.capacityField}>
                            <FormControlLabel value="200GB" control={<Radio color="primary" disabled={isRenewal} />} label="200GB" />
                            <FormControlLabel value="1TB" control={<Radio color="primary" disabled={isRenewal} />} label="1TB" />
                            <FormControlLabel value="other" control={<Radio color="primary" disabled={isRenewal} />} 
                                label={
                                    <OtherCapacityTextfield 
                                        isRenewal={isRenewal}
                                        isValueValid={isValueValid}
                                        planContentValue={planContentValue}
                                        capacityValue={nubisDriveCapacityValue} 
                                        onClick={handlePlanContentChange} 
                                        onChange={handlePlanContentChange} />
                                } 
                            />
                        </Box> 
                    </RadioGroup>
                </>
                :
                <>
                    <Box mr={1}>{t('subscribeNewService.chooseCapacity')}</Box>
                    <Typography color="textPrimary" variant="caption">{transformedFormatBytes(nubisDriveCapacityValue.capacity)}</Typography>
                </>
            }
        </Box>
    )
}

export default SelectNubisDriveCapacity;