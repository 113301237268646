import { successHandling } from './successHandling';
import { table } from './delegationDatatable';

export const successfullyDelegationHandling = (t, getNotiDispatch, action, successfulText, type, handleResetGroupsAndCompanys) => {
    successHandling(t, successfulText, type);
    table(t, action);
    getNotiDispatch({type:'get'});
    getNotiDispatch({type:'cancel'});
    handleResetGroupsAndCompanys();
    table(t);
}