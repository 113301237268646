import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import { Box, Typography } from "@material-ui/core";
import AntSwitch from "../commons/AntSwitch";

const PublishStatusSwitch = props => {
  const {
    checked,
    onChange,
    typographyVariant,
    className,
    ifShowRequired,
    disabled
  } = props;
  const { t } = useTranslation("subscribeServices");

  return (
    <Box display="flex" className={className}>
      <Typography
        variant={typographyVariant}
        color="secondary"
        className="mr-1"
      >
        {t("serviceManagement.publishStatus")} {ifShowRequired ? "*" : ""}
      </Typography>
      <AntSwitch checked={checked} onChange={onChange} disabled={disabled} />
    </Box>
  );
};

export default PublishStatusSwitch;
