import React, { useState, useEffect, useContext } from 'react';
import { Grid, Paper, FormControl, RadioGroup, Divider, Button, Box } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext, NotificationContext } from '../../App';
import useStyles from '../../styles/delegationStyle';
import * as localforage from 'localforage';
import * as $ from 'jquery';
import StyledSubmitBtn from '../../components/commons/StyledSubmitBtn';
import { SendOutlined } from '@material-ui/icons';
import EditGroupsDialog from './EditGroupsDialog';
import { getCompanyGroupsAPI } from '../../apis/getCompanyGroupsAPI';
import { getSubCompanyAPI } from '../../apis/getSubCompanyAPI';
import { assignDevicesAPI } from  '../../apis/assignDevicesAPI';
import { removeDevicesAPI } from  '../../apis/removeDevicesAPI';
import { updateDeviceNubisVersionAPI } from '../../apis/updateDeviceNubisVersionAPI';
import { updateDeviceStatusAPI } from '../../apis/updateDeviceStatusAPI';
import { uploadExistingDevicesAPI } from '../../apis/uploadExistingDevicesAPI';
import { table } from '../../helpers/delegationDatatable';
import DelegationActionFromOrToDeterminator from './DelegationActionFromOrToDeterminator';
import ActionRadioGroup from './ActionRadioGroup';
import SelectionMenuItem from './SelectionMenuItem';
import { setUpArrayWithCheckStatus } from '../../helpers/setUpArrayWithCheckStatus';
import { StepTitle, SettingText, MissingSelectedErrorText } from '../commons/StyledTexts';
import DelegationTable from './DelegationTable';
import ExcelFileUpload from '../commons/ExcelFileUpload';
import { successfullyDelegationHandling } from '../../helpers/successfullyDelegationHandling';
import { handleDatatableSelectWithRadio } from '../../helpers/handleDatatableSelectWithRadio';
window.JSZip = require( "jszip" );

const DelegationForm = () => {
    const classes = useStyles();
    const { t } = useTranslation('devicesGroups');
    const { dispatch } = useContext(SubmitBtnContext);
    const { getNotiDispatch } = useContext(NotificationContext);
    const [ groups, setGroups ] = useState([]);
    const [ selectedGroups, setSelectedGroups ] = useState([]);
    const [ companies, setCompanies ] = useState([]);
    const [ selectedCompanies, setSelectedCompanies ] = useState([]);
    const [ action, setAction ] = useState('assign');
    const [ selectWay, setSelectWay ] = useState('upload');
    const [ excelFile, setExcelFile ] = useState(null);
    const [ selectedGroupIds, setSelectedGroupIds ] = useState([]);
    const [ selectedCompanyIds, setSelectedCompanyIds ] = useState([]);
    const [ deviceIds, setDeviceIds ] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [ deviceStatus, setDeviceStatus ] = useState('');
    const [ nubisVersion, setNubisVersion ] = useState('');
    const [ isMissingSelectedTarget, setIsMissingSelectedTarget ] = useState(false);
    const [ editGroupsDialogOpen, setEditGroupsDialogOpen ] = useState(false);
    const [ resetSearchField, setResetSearchField ] = useState(true);
    const actionsHideGroupOrTagSelection = ['register', 'nubis_free', 'nubis_pro'];
    let delegationTable = $('#device-list-table').DataTable();
   
    const handleSelectWayChange = e => {
        setSelectWay(e.target.value);
        setExcelFile(null); // reset the 'required' validation
        clearFileInput("#fileInput");
        setDeviceIds([]); // reset the 'required' validation
    }

    const getAllSelectionItems = async(type) => {

        if(type.includes('group')){
            const companyGroupsArr = await getCompanyGroupsAPI();
            if(companyGroupsArr) setUpArrayWithCheckStatus(companyGroupsArr, setGroups, 'group');
        }
       
        if(type.includes('company')){
            await getSubCompanyAPI();
            const subCompaniesArr = await localforage.getItem('subCompanies');
            
            let subCompaniesArrWithNameAndId;
            if(subCompaniesArr) subCompaniesArrWithNameAndId = subCompaniesArr.map(o => ({name: o.title, id: o.key}));
            if(subCompaniesArrWithNameAndId) setUpArrayWithCheckStatus(subCompaniesArrWithNameAndId, setCompanies, 'company');
        }
        
    }

    const handleItemsSelected = (e, menuObjArray, chipObjArray, type) => {
        let itemName = e.target.name;
        let itemId = e.target.id;
        let itemCheckedStatus = e.target.checked;
        
        // update the menu list with checkbox status
        let changedItemIndex = menuObjArray.map( o => o.id).indexOf(itemId);
        let changedItem = {name: itemName, id: itemId, checked: itemCheckedStatus};
        
        const copiedItemsArr = menuObjArray;
        menuObjArray.splice(changedItemIndex, 1, changedItem);
        if(type === 'group') setGroups([...copiedItemsArr]);
        if(type === 'company') setCompanies([...copiedItemsArr]);

        // update the chip list
        let chipItem = {};
        chipItem['name'] = itemName;
        chipItem['id'] = itemId;

        let chipItemsIndex = chipObjArray.map(o => o.id).indexOf(itemId);
        chipItemsIndex > -1 ? chipObjArray.splice(chipItemsIndex, 1) : chipObjArray.push(chipItem);
        if(chipObjArray.length > 0) setIsMissingSelectedTarget(false);
        
        // update the device table
        let selectedItemIds = chipObjArray.map(o => o.id);
        if(type === 'group'){
            setSelectedGroups(chipObjArray);
            setSelectedGroupIds(selectedItemIds);
            table(t, action, selectedCompanyIds, selectedItemIds, deviceStatus, nubisVersion);
        }
        if(type === 'company'){
            setSelectedCompanies(chipObjArray);
            setSelectedCompanyIds(selectedItemIds);
            table(t, action, selectedItemIds, selectedGroupIds, deviceStatus, nubisVersion);
        }
    }
    
    const handleReloadDataTable = () => {
        setResetSearchField(true);
        table(t, action, selectedCompanyIds, selectedGroupIds, nubisVersion);
    }
    
    const handleEditGroupsDialogClickOpen = () => setEditGroupsDialogOpen(true);
    
    const handleEditGroupsDialogClose = () => {
        setEditGroupsDialogOpen(false);
        getAllSelectionItems('group');
    };

    const handleResetGroupsAndCompanys = async() => {
        const resetGroupsCheckedArr = groups.map((o)=>({name: o.name, id: o.id, checked: false}));
        const resetCompaniesCheckedArr = companies.map((o)=>({name: o.name, id: o.id, checked: false}));
        await localforage.removeItem('dtDeviceIds');
        setGroups(resetGroupsCheckedArr);
        setCompanies(resetCompaniesCheckedArr);
        setSelectedGroups([]);
        setSelectedCompanies([]);
        setSelectedGroupIds([]);
        setSelectedCompanyIds([]);
        setDeviceIds([]);
    }
            
    const clearFileInput = (id) => {
      $(id).val('')
    };

    const handleDelegationSubmit = async() => {
        // Check if Action is 'Assign' or 'Remove', is any group or company selected?
        let hasGroupOrCompanySelected = !!(selectedCompanyIds.length < 1 && selectedGroupIds.length < 1);
        if(action === 'assign' || action === 'remove') {
            setIsMissingSelectedTarget(hasGroupOrCompanySelected);
            if(hasGroupOrCompanySelected){
                return
            }
        }
        dispatch({type: 'showLoading'});
        // Check if any device is selected
        let deviceIdsArr = await localforage.getItem('dtDeviceIds');
        let hasDeviceIdsArr = !!(deviceIdsArr && deviceIdsArr.length > 0);
        if(selectWay === 'upload') setExcelFile(!!excelFile);
        if(selectWay !== 'upload') setDeviceIds(hasDeviceIdsArr);

        // Submit
        if(excelFile || hasDeviceIdsArr){
            const token = await localforage.getItem('token');

            if(selectWay === 'upload' && excelFile) {
                let responseStatus = await uploadExistingDevicesAPI(token, action, selectedCompanyIds, selectedGroupIds, excelFile, deviceStatus, nubisVersion)
                let successfulText = 'setting.successfullyUploadExcel'
                if (responseStatus){
                    successfullyDelegationHandling(t, getNotiDispatch, action, successfulText, 'success', handleResetGroupsAndCompanys);
                    clearFileInput("#fileInput");
                } 
            } 

            if(selectWay !== 'upload'){

                if((action === 'assign' || action === 'remove')  && !hasGroupOrCompanySelected){
                    if(action === 'assign'){
                        let responseStatus = await assignDevicesAPI(token, deviceIdsArr, selectedGroupIds, selectedCompanyIds)
                        let successfulText = 'setting.successfullyAssignDevices'
                        if (responseStatus) successfullyDelegationHandling(t, getNotiDispatch, action, successfulText, 'success', handleResetGroupsAndCompanys);
                    }
                    if(action === 'remove'){
                        let responseStatus = await removeDevicesAPI(token, deviceIdsArr, selectedGroupIds, selectedCompanyIds)
                        let successfulText = 'setting.successfullyRemoveDevices'
                        if (responseStatus) successfullyDelegationHandling(t, getNotiDispatch, action, successfulText, 'success', handleResetGroupsAndCompanys);
                    }
                }
    
                if(action === 'nubis_free' || action === 'nubis_pro'){
                    // 'action' already stated as nubis_free or nubis_pro, so just simply assigned it
                    let responseStatus = await updateDeviceNubisVersionAPI(token, deviceIdsArr, action)
                    let successfulText = 'setting.successfullyUpdateNubisStatus'
                    if (responseStatus) successfullyDelegationHandling(t, getNotiDispatch, action, successfulText, 'success', handleResetGroupsAndCompanys);
                }
                
                if(action === 'register'){
                    let responseStatus = await updateDeviceStatusAPI(token, deviceIdsArr, 'registered');
                    let successfulText = 'setting.successfullyRegisterDevices'
                    if (responseStatus) successfullyDelegationHandling(t, getNotiDispatch, action, successfulText, 'success', handleResetGroupsAndCompanys);
                }
            }  
        } 
        
        dispatch({type: 'stopLoading'});
    }

    useEffect(()=>{
        table(t);
        getAllSelectionItems(['group', 'company']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=>{
        handleResetGroupsAndCompanys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editGroupsDialogOpen])

    
    // // Datatable device selector
    // // Store the device Ids as an array in Localforage

    delegationTable.on('click', $('#device-list-table input'), async function() {
        handleDatatableSelectWithRadio(setSelectWay);
    })

    return (
        <Grid container spacing={2} className={`${classes.root} ${classes.delegationForm}`}>
            <Grid item xs={12}>
                <StepTitle title='selectDelegationSettings' /> 
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <Grid container alignItems="center">
                        <Grid item xl={1} lg={2} md={2} sm={3} xs={3}>
                            <SettingText text='actions' /> 
                        </Grid>
                        <Grid item xl={11} lg={10} md={10} sm={9} xs={9}>
                            <ActionRadioGroup 
                                action={action} 
                                setResetSearchField={setResetSearchField}
                                selectedCompanyIds={selectedCompanyIds}
                                selectedGroupIds={selectedGroupIds}
                                deviceStatus={deviceStatus}
                                nubisVersion={nubisVersion}
                                setAction={setAction}
                                setNubisVersion={setNubisVersion} 
                                handleReloadDataTable={handleReloadDataTable}
                                setIsMissingSelectedTarget={setIsMissingSelectedTarget} />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container alignItems="center">
                        <Grid item xl={1} lg={2} md={2} sm={3} xs={3}>
                            <DelegationActionFromOrToDeterminator action={action} actionsHideGroupOrTagSelection={actionsHideGroupOrTagSelection} /> 
                        </Grid>
                        <Grid item xl={11} lg={10} md={10} sm={9} xs={9}>
                            <SelectionMenuItem 
                                title="groups"
                                action={action}
                                editBtnOnClick={handleEditGroupsDialogClickOpen}
                                actionsHideGroupOrTagSelection={actionsHideGroupOrTagSelection}
                                selectValueArray={selectedGroups} 
                                menuItemArray={groups} 
                                checkBoxOnChange={(e)=>handleItemsSelected(e, groups, selectedGroups, 'group')} />
                            <Divider />
                            <SelectionMenuItem 
                                title="companies"
                                action={action}
                                actionsHideGroupOrTagSelection={actionsHideGroupOrTagSelection}
                                selectValueArray={selectedCompanies} 
                                menuItemArray={companies} 
                                checkBoxOnChange={(e)=>handleItemsSelected(e, companies, selectedCompanies, 'company')} />
                            
                            {isMissingSelectedTarget && 
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item lg={2} md={2} sm={3} xs={3}></Grid>
                                    <Grid item lg={10} md={10} sm={9} xs={9}>
                                        <Box mb={1} mt={-1}><MissingSelectedErrorText text='selectedGroupOrCompanyRequired' /></Box>
                                    </Grid>
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                </Paper> 
            </Grid>  
            <Grid item xs={12}>
                <StepTitle title='chooseAWayToDelegateDevices' /> 
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="selectWay" name="selectWay" value={selectWay} onChange={handleSelectWayChange}>
                        <ExcelFileUpload 
                            selectWay={selectWay}
                            excelFile={excelFile}
                            setExcelFile={setExcelFile}
                            setSelectWay={setSelectWay}
                            label={t('setting.uploadDevicesSN')}
                        />
                        <Divider className={classes.divider} />
                        <DelegationTable
                            resetSearchField={resetSearchField}
                            setResetSearchField={setResetSearchField}
                            reloadTableBtnOnclick={handleReloadDataTable} 
                        />
                        {!deviceIds && <MissingSelectedErrorText text='selectedTargetRequired' />}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.formControlBtn}>
                    <StyledSubmitBtn btnColor="primary" startIcon={<SendOutlined />} 
                        buttonClassname={classes.submitFormBtn}
                        btnText={t('table.confirmBtn')} 
                        onClick={handleDelegationSubmit}
                    />
                    <Button color="secondary" variant="contained" href="/">
                        {t('table.cancelBtn')}
                    </Button>
                </div>                  
            </Grid>
            <EditGroupsDialog 
                open={editGroupsDialogOpen} 
                handleEditGroupsDialogClickOpen={handleEditGroupsDialogClickOpen}
                onClose={handleEditGroupsDialogClose} />
        </Grid>
    )
}

export default DelegationForm;