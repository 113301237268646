import React from "react";
import { NavLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@material-ui/core";
import { ListOutlined, AddOutlined, Apps } from "@material-ui/icons";
import { useTranslation } from "../../langs/useTranslation";
import HasPermission from "../commons/HasPermission";

const subscribeServiceBtns = t => [
  {
    link: "/subscriptions",
    name: `${t("btn.yourSubscriptions")}`,
    permissionName: null,
    icon: <ListOutlined />
  },
  {
    link: "/subscribe-new-service",
    name: `${t("btn.subscribeNewService")}`,
    permissionName: "subscription_add",
    icon: <AddOutlined />
  },
  {
    link: "/service-management",
    name: `${t("btn.serviceManagement")}`,
    permissionName: "service_management",
    icon: <Apps />
  }
];

const Btn = props => {
  const { btnObj } = props;

  return (
    <>
      <NavLink to={btnObj.link}>
        <List>
          <ListItem button>
            <ListItemIcon>{btnObj.icon}</ListItemIcon>
            <ListItemText>{btnObj.name}</ListItemText>
          </ListItem>
        </List>
      </NavLink>
      <Divider />
    </>
  );
};

export const SubscribeServiceBtns = () => {
  const { t } = useTranslation("subscribeServices");
  const btns = subscribeServiceBtns(t);

  return btns.map((o, i) => {
    if (o.permissionName) {
      return (
        <HasPermission permission={o.permissionName} key={i}>
          <Btn btnObj={o} index={i} />
        </HasPermission>
      );
    } else {
      return <Btn btnObj={o} key={i} />;
    }
  });
};
