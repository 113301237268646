import React from 'react';
import { IconButton } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

const GroupEditBtn = props => {
    const { onClick } = props;
    return(
        <IconButton aria-label="edit-group" size="small" onClick={onClick}>
            <EditOutlined fontSize="inherit" />
        </IconButton>
    )
}

export default GroupEditBtn;