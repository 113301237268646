import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const createCompanyAPI = async(token, values, initCompanyImg, defaultImage) => {

    let formData = new FormData();
    formData.append('name', values.companyName);
    formData.append('parent_company_id', values.parentCompany === "-" ? "" : values.parentCompany);
    formData.append('country_id', values.companyCountry);
    formData.append('region_id', values.companyRegion);
    formData.append('address', values.companyAddress);
    formData.append('contact_person', values.contactPerson);
    
    if (values.file) {
        formData.append('image', values.file[0]);
        if ( initCompanyImg !== defaultImage ) { // if there is already an old image 
            formData.append('old_image', initCompanyImg);
        } else { 
            formData.append('old_image', defaultImage);
        }
    }
    formData.append('phone', values.contactPhone);
    formData.append('email', values.contactMail);
    formData.append('fec_customer_id', values.customID === "-" ? "" : values.customID);
    

    try {
        let response = await axios({
            url: `${url}/api/v1/company/create`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: formData,
        })

        await localforage.setItem( 'newCreatedCompanyID', response.data.company.id);
        await localforage.setItem( response.data.company.id, response.data.company );
        await localforage.setItem( `${response.data.company.id}Name`, response.data.company.name )
        window.location.replace(`/company-profile/${response.data.company.id}`)
        return true;

    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}